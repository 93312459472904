<template>
  <RootBlock :item="item" :padding="false">
    <template v-if="items && items.length > 0">
      <div class="grid w-full h-full">
        <GridItem
          :item="firstSlideItem"
          :index="0"
        />
      </div>
    </template>
    <div v-else class="flex flex-col items-center justify-center space-y-2 w-full h-full px-4 py-8">
      <p class="text-lg">
        Slider Block
      </p>
      <p class="text-sm">
        No slides defined yet.
      </p>
    </div>
  </RootBlock>
</template>
<script>
import RootBlock from '../RootBlock'
import GridItem from '../../GridItem'

export default {
  friendlyName: 'Slider Block',

  components: {
    RootBlock,
    GridItem
  },

  props: {
    item: {
      type: Object,
      required: true
    },

    editMode: {
      type: Boolean,
      required: false,
      default: () => { return false }
    }
  },

  computed: {
    content () {
      return this.item.content || false
    },

    layout () {
      return this.item.layout || false
    },

    items () {
      return this.content.items || []
    },

    firstSlideElement () {
      return this.firstSlideItem ? this.$store.getters['editor/getElementByKey'](this.items[0].element) : false
    },

    firstSlideItem () {
      return this.items[0] || false
    }
  }
}
</script>
