<template>
  <div>
    <label
      v-if="label && $attrs.type !== 'checkbox'"
      class="text-sm font-medium"
    >
      {{ label }}
    </label>
    <div class="flex space-x-1">
      <vue-input
        v-model="localValue"
        v-bind="$attrs"
        v-on="$listeners"
        class="flex-grow"
        @input="localErrors = []"
      />
      <div class="flex items-center justify-center rounded bg-gray-100 px-3">
        <font-awesome-icon :icon="['fal', localValue]" size="lg" />
      </div>
    </div>
  </div>
</template>
<script type="text/javascript">
import HasVModel from '@/mixins/HasVModel'

export default {
  name: 'VueIconPicker',

  mixins: [HasVModel],

  props: {
    label: {
      type: String,
      required: false,
      default: () => { return null }
    }
  }
}
</script>
