<template>
  <RootBlock :item="item" :padding="false">
    <div class="flex flex-col items-center justify-center w-full h-full border border-gray-200 space-y-2">
      <span class="text-2xl">Mapbox Map Embed</span>
    </div>
  </RootBlock>
</template>
<script>
import RootBlock from '../RootBlock'
export default {
  friendlyName: 'Text Block',

  components: {
    RootBlock
  },

  props: {
    item: {
      type: Object,
      required: true
    }
  },

  computed: {
    content () {
      return this.item.content || false
    },

    layout () {
      return this.item.layout || false
    }
  }
}
</script>
