<template>
  <RootBlock :item="item">
    <div class="flex flex-col justify-center h-full space-y-2">
      <component :is="layout ? layout.header || 'h2' : 'h2'" v-if="content && content.header" :class="`text-h1 ${colors && colors.header ? colors.header : colors.text || ''}`">
        {{ content.header }}
      </component>
    </div>
  </RootBlock>
</template>
<script>
import RootBlock from '../RootBlock'

export default {
  friendlyName: 'Link Block',

  components: {
    RootBlock
  },

  props: {
    item: {
      type: Object,
      required: true
    }
  },

  computed: {
    content () {
      return this.item.content || false
    },

    layout () {
      return this.item.layout || false
    },

    colors () {
      return this.item.colors || false
    }
  }
}
</script>
