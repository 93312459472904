var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('vue-data-table',{attrs:{"cols":[
      {
        header: 'Title',
        value: 'title',
      },
      {
        header: 'Slug',
        value: 'slug'
      },
      {
        header: 'Added At',
        value: 'created_at',
        sort: true
      },
      {
        header: 'Last Modified',
        value: 'updated_at',
        sort: true
      },
      {
        header: 'Actions',
        value: 'actions'
      }
    ],"path":"metadata","request-params":{
      type: 'post'
    }},scopedSlots:_vm._u([{key:"header-left",fn:function(){return [_c('vue-button',{attrs:{"icon":['fas', 'plus-circle'],"primary":""},on:{"click":function($event){return _vm.$router.push({ name: _vm.routes.new })}}},[_vm._v(" Add "+_vm._s(_vm.model.singular)+" ")])]},proxy:true},{key:"item.title",fn:function(row){return [_vm._v(" "+_vm._s(row.value.title)+" ")]}},{key:"item.slug",fn:function(row){return [_vm._v(" "+_vm._s(row.value.slug)+" ")]}},{key:"item.created_at",fn:function(row){return [_vm._v(" "+_vm._s(_vm.$momentDateTimeHuman(row.created_at))+" ")]}},{key:"item.updated_at",fn:function(row){return [_vm._v(" "+_vm._s(_vm.$momentDateTimeHuman(row.updated_at))+" ")]}},{key:"item.actions",fn:function(row){return [_c('vue-link',{staticClass:"inline-flex cursor-pointer",attrs:{"to":{
          name: _vm.routes.single,
          params: {
            model: row.id
          }
        },"icon":['fad', 'edit'],"aria-label":("Edit " + (_vm.model.singular) + ": " + (row.name)),"dark":"","text":"","sm":""}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }