export default {
  props: {
    value: {
      required: true
    },

    vuex: {
      type: Boolean,
      required: false,
      default: () => { return false }
    },

    vuexMutation: {
      type: String,
      required: false,
      default: () => { return null }
    }
  },
  computed: {
    localValue: {
      get () {
        return this.value
      },
      set (localValue) {
        if (this.vuex) {
          if (this.vuexMutation) {
            this.$store.commit(this.vuexMutation, {
              key: this.prop,
              value: localValue
            })
          } else {
            this.$emit('vuex', localValue)
          }
        } else {
          this.$emit('input', localValue)
        }
      }
    }
  }
}
