<template>
  <div>
    <vue-alert
      class="mb-3 mt-2"
      primary
    >
      Welcome to the Euro Stainless Ltd. Admin Dashboard
    </vue-alert>
  </div>
</template>
<script>
export default {
  name: 'Home'
}
</script>
