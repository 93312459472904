<template>
  <RootBlock :item="item" :padding="false">
    <div v-if="items && items.length > 0" class="w-full">
      <component :is="layout ? layout.header || 'h2' : 'h2'" v-if="content.header" class="text-h1 mb-2">
        {{ content.header }}
      </component>
      <div class="grid overflow-hidden" :style="blockStyles">
        <div
          v-for="(item, index) in items"
          :key="index"
        >
          <GridItem
            :item="item"
            :index="index"
          />
        </div>
      </div>
    </div>
    <div v-else class="flex flex-col items-center justify-center w-full h-full space-y-2 py-8">
      <p class="text-lg">
        Category List Block
      </p>
      <p>
        No category selected/loaded
      </p>
    </div>
  </RootBlock>
</template>
<script>
import { map } from 'lodash'
import RootBlock from '../RootBlock'
import GridItem from '../../GridItem'

export default {
  friendlyName: 'Columns Block',

  components: {
    RootBlock,
    GridItem
  },

  props: {
    item: {
      type: Object,
      required: true
    },

    editMode: {
      type: Boolean,
      required: false,
      default: () => { return false }
    }
  },

  data () {
    return {
      loadedCategory: null,
      baseShades: [
        'bg-dark-900', 'bg-dark-800', 'bg-dark-700', 'bg-dark-600', 'bg-dark-500', 'bg-dark-400', 'bg-dark-300', 'bg-dark-200', 'bg-dark-100'
      ]
    }
  },

  computed: {
    content () {
      return this.item.content || false
    },

    layout () {
      return this.item.layout || false
    },

    items () {
      return this.loadedCategory ? map(this.loadedCategory.sets, (category, index) => {
        return {
          component: 'BlockSingleCategory',
          element: 'single-category',
          layout: {
            padding_x: 'px-gap-xs',
            padding_y: 'py-gap-xs',
            aspect: 'aspect-w-1 aspect-h-1'
          },
          colors: {
            background: (index % this.shades.length === 0) ? this.getShade(index) : this.getShade(index),
            text: 'text-white'
          },
          content: {
            category: category.id,
            name: category.name,
            slug: category.slug
          },
          images: category.images
        }
      }) || [] : []
    },

    shades () {
      return new Array(this.baseShades.length).fill(this.baseShades).flat()
    },

    blockStyles () {
      return `grid-template-columns: repeat(${this.content.columnCount || 6}, 1fr)`
    }
  },

  watch: {
    'item.content.category': {
      handler (newVal) {
        if (newVal) {
          this.loadCategory()
        } else {
          this.loadedCategory = null
        }
      }
    }
  },

  mounted () {
    if (this.content.category) {
      this.loadCategory()
    }
  },

  methods: {
    getShade (index = 0, offset = 0) {
      return this.shades[this.baseShades.length + index + offset]
    },

    loadCategory () {
      this.$api.get(`sets/${this.content.category}`, {
        params: {
          with: ['sets'],
          withImages: true,
          withPivot: true
        }
      })
        .then((res) => {
          this.loadedCategory = res
        })
    }
  }
}
</script>
