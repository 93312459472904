<template>
  <div>
    <vue-data-table
      :cols="[
        {
          header: 'Customer Name',
          value: 'customer_name',
        },
        {
          header: 'Total',
          value: 'grand_total'
        },
        {
          header: 'Billing Status',
          value: 'billing_status_label',
          sort: true
        },
        {
          header: 'Ordered At',
          value: 'created_at',
          sort: true
        },
        {
          header: 'Actions',
          value: 'actions'
        }
      ]"
       path="orders"
    >
      <template #header-left>
      </template>
      <template v-slot:item.customer_name="row">
        {{ row.customer_name }}
      </template>
      <template v-slot:item.grand_total="row">
        {{ row.grand_total.toLocaleString('en-UK', {'style':'currency','currency':'GBP'}) }}
      </template>
      <template v-slot:item.created_at="row">
        {{ $momentDateTimeHuman(row.created_at) }}
      </template>
      <template v-slot:item.billing_status_label="row">
        {{ row.billing_status_label }}
      </template>
      <template v-slot:item.actions="row">
        <vue-link
          :to="{
            name: routes.single,
            params: {
              model: row.id
            }
          }"
          :icon="['fad', 'edit']"
          :aria-label="`Edit ${model.singular}: ${row.name}`"
          dark
          text
          sm
          class="inline-flex cursor-pointer"
        />
      </template>
    </vue-data-table>
  </div>
</template>

<script>
export default {
  name: 'OrdersIndex',

  data () {
    return {
      model: {
        singular: 'Order',
        plural: 'Orders'
      },
      routes: {
        index: 'orders.index',
        // new: 'users.new',
        single: 'orders.single'
      },
      selected: ''
    }
  }
}
</script>

<style>

</style>
