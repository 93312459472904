<template>
  <RootBlock :item="item">
    <div class="flex justify-center">
      Child Page content will be rendered in here.
    </div>
  </RootBlock>
</template>
<script>
import RootBlock from '../RootBlock'

export default {
  friendlyName: 'Child Page Container',

  components: {
    RootBlock
  },

  props: {
    item: {
      type: Object,
      required: true
    }
  }
}
</script>
