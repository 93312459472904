const state = {
  errors: []
}

const mutations = {
  addError (state, error) {
    state.errors.push(error)
  },

  clearErrors (state) {
    state.errors = []
  }
}

export default {
  namespaced: true,
  state,
  mutations
}
