import { map } from 'lodash'

const colors = [
  { label: 'Primary (Yellow)', value: 'primary' },
  { label: 'Primary Dark (Dark Yellow)', value: 'primary-dark' },
  { label: 'Secondary (Blue)', value: 'secondary' },
  { label: 'Secondary Dark (Dark Blue)', value: 'secondary-dark' },
  { label: 'Dark (10%)', value: 'dark-100' },
  { label: 'Dark (20%)', value: 'dark-200' },
  { label: 'Dark (30%)', value: 'dark-300' },
  { label: 'Dark (40%)', value: 'dark-400' },
  { label: 'Dark (50%)', value: 'dark-500' },
  { label: 'Dark (60%)', value: 'dark-600' },
  { label: 'Dark (70%)', value: 'dark-700' },
  { label: 'Dark (80%)', value: 'dark-800' },
  { label: 'Dark (90%)', value: 'dark-900' },
  { label: 'Black', value: 'black' },
  { label: 'White', value: 'white' },
  { label: 'Light', value: 'light' }
]
const bgColors = [
  { label: 'Transparent', value: 'bg-transparent' },
  { label: 'Gradient - Yellow to Blue', value: 'bg-gradient-radial from-primary to-secondary' },
  { label: 'Gradient - Blue to Yellow', value: 'bg-gradient-radial from-secondary to-primary' },
  { label: 'Gradient - Dark (80%) to Dark (40%)', value: 'bg-gradient-radial from-dark-800 to-dark-400' },
  { label: 'Gradient - Dark (40%) to Dark (80%)', value: 'bg-gradient-radial from-dark-400 to-dark-800' }
]
const opacities = [
  { label: '10%', value: 'bg-opacity-10' },
  { label: '20%', value: 'bg-opacity-20' },
  { label: '30%', value: 'bg-opacity-30' },
  { label: '40%', value: 'bg-opacity-40' },
  { label: '50%', value: 'bg-opacity-50' },
  { label: '60%', value: 'bg-opacity-60' },
  { label: '70%', value: 'bg-opacity-70' },
  { label: '80%', value: 'bg-opacity-80' },
  { label: '90%', value: 'bg-opacity-90' },
  { label: 'Opaque', value: 'bg-opacity-100' }
]

const getMappedColors = (prefix) => {
  return map(colors, (color) => {
    return {
      ...color,
      ...{ value: `${prefix}-${color.value}` }
    }
  })
}

export default {
  data () {
    return {
      colors: {
        background: {
          label: 'Background Color',
          type: 'select',
          options: [
            ...getMappedColors('bg'),
            ...bgColors
          ]
        },
        opacity: {
          label: 'Background Opacity',
          type: 'range',
          options: {
            data: opacities,
            min: 0,
            max: 100,
            hideLabel: true,
            interval: 10,
            contained: true,
            'data-label': 'label',
            'data-value': 'value'
          }
        },
        text: {
          label: 'Text Color',
          type: 'select',
          options: getMappedColors('text')
        },
        icon: {
          label: 'Icon Color',
          type: 'select',
          options: getMappedColors('text')
        }
      }
    }
  }
}
