<template>
  <div>
    <label
      v-if="label && $attrs.type !== 'checkbox'"
      class="text-sm font-medium"
    >
      {{ label }}
      <span v-if="$attrs.required" class="text-red-700">
        <abbr title="Required field">*</abbr>
      </span>
    </label>
    <template v-if="$attrs.type === 'select'">
      <vue-select
        v-model="localValue"
        v-bind="{ ...{ prepend, square }, ...$attrs }"
        v-on="$listeners"
      />
    </template>
    <template v-else-if="$attrs.type === 'checkbox'">
      <vue-checkbox
        v-model="localValue"
        v-bind="$attrs"
        v-on="$listeners"
        :label="label"
      />
    </template>
    <template v-else-if="$attrs.type === 'textarea'">
      <vue-textarea
        v-model="localValue"
        v-bind="{ ...{ prepend, square }, ...$attrs }"
        v-on="$listeners"
        @input="localErrors = []"
      />
    </template>
    <template v-else-if="$attrs.type === 'editor'">
      <vue-editor
        v-model="localValue"
        v-bind="$attrs"
        :value="localValue"
        @input="localErrors = []"
      />
    </template>
    <template v-else-if="$attrs.type === 'range'">
      <vue-range
        v-model="localValue"
        v-bind="$attrs"
        v-on="$listeners"
        @input="localErrors = []"
      />
    </template>
    <template v-else>
      <vue-input
        v-model="localValue"
        v-bind="{ ...{ prepend, square }, ...$attrs }"
        v-on="$listeners"
        @input="localErrors = []"
      />
    </template>
    <div v-if="description" class="text-xs opacity-75 my-1">
      {{ description }}
    </div>
    <div>
      <span
        v-for="(error, errorKey) in localErrors"
        :key="errorKey"
        class="text-red-600 text-xs font-medium mr-1">{{ error }}</span>
    </div>
  </div>
</template>
<script type="text/javascript">
import HasVModel from '@/mixins/HasVModel'
import HasErrors from '@/mixins/HasErrors'

export default {
  name: 'VueInputGroup',

  props: {
    label: {
      type: String,
      required: false,
      default: () => { return null }
    },

    vuex: {
      type: Boolean,
      required: false,
      default: () => { return false }
    },

    vuexMutation: {
      type: String,
      required: false,
      default: () => { return null }
    },

    prop: {
      type: String,
      required: true
    },

    square: {
      type: Boolean,
      required: false,
      default: () => { return false }
    },

    prepend: {
      type: String,
      required: false,
      default: () => { return null }
    },

    description: {
      type: [Boolean, String],
      required: false,
      default: () => { return false }
    }
  },

  mixins: [HasVModel, HasErrors]
}
</script>
