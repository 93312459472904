import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import auth from '@/store/modules/auth'
import user from '@/store/modules/user'
import product from '@/store/modules/product'
import error from '@/store/modules/error'
import loading from '@/store/modules/loading'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth,
    user,
    error,
    loading,
    product
  },
  plugins: [createPersistedState({
    paths: [
      'user.user'
    ]
  })]
})
