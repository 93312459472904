<template>
  <div>
    <div class="flex flex-wrap lg:flex-nowrap items-center bg-gray-200 rounded text-xs space-x-4 px-1 py-1 mb-4">
      <div class="flex justify-start w-1/2 lg:w-auto order-first">
        <vue-button
          text
          class="py-1"
          :icon="['fad', 'arrow-circle-left']"
          icon-size="2x"
          @click.native="routeBack ? $router.push(routeBack) : $router.go(-1)"
        />
      </div>
      <div class="flex flex-col lg:flex-row items-center lg:justify-end w-full text-shade-dark order-last lg:order-none">
        <span class="font-bold lg:ml-4">Created At: <span class="font-medium">{{ $momentDateTimeHuman(model.created_at) }}</span></span>
        <span class="font-bold lg:ml-4">Updated At: <span class="font-medium">{{ $momentDateTimeHuman(model.updated_at) }}</span></span>
      </div>
      <div class="relative flex justify-end w-1/2 space-x-2 lg:w-auto">
        <vue-button
          :icon="['fad', 'sync']"
          text
          @click.native="$emit('refresh-model')"
        />
        <template v-if="canDelete">
          <vue-button
            :icon="['fas', 'ellipsis-v']"
            text
            @click.prevent="dropdown = !dropdown"
          />
          <div v-if="dropdown" class="absolute top-100 right-0 flex flex-col shadow bg-white rounded">
            <vue-button
              v-if="canDelete"
              text
              error
              flip
              lg
              :icon="['fad', 'trash']"
              @click="warnDelete"
            >
              Delete
            </vue-button>
          </div>
        </template>
      </div>
    </div>
    <div v-if="model" class="flex flex-wrap lg:flex-nowrap mt-2 -mx-2">
      <div v-if="$slots.left || $slots.title" class="w-full lg:w-1/4 px-2 mb-2">
        <slot name="title" />
        <vue-card class="mt-2 lg:mt-0">
          <slot name="left" />
        </vue-card>
      </div>
      <div class="w-full lg:w-1/2 flex-grow px-2 mb-2">
        <slot name="main" />
      </div>
      <div v-if="$slots.right" class="w-full lg:w-1/4 px-2 mb-2">
        <vue-card class="mt-2 lg:mt-0">
          <slot name="right" />
        </vue-card>
      </div>
    </div>
    <div v-if="loading && !uploading" class="fixed inset-0 flex items-center justify-center w-full h-full select-none z-50">
      <div class="absolute inset-0 bg-primary bg-opacity-75 z-1" aria-hidden="true" />
      <div class="relative flex flex-col items-center text-white text-2xl space-y-2 z-5">
        <font-awesome-icon :icon="['fad', 'spinner-third']" size="2x" spin />
        <span>Loading</span>
      </div>
    </div>
    <div v-if="uploading && loading" class="fixed inset-0 flex items-center justify-center w-full h-full select-none z-50">
      <div class="absolute inset-0 bg-green-600 bg-opacity-75 z-1" aria-hidden="true" />
      <div class="relative flex flex-col items-center text-white text-2xl space-y-2 z-5">
        <font-awesome-icon :icon="['fad', 'spinner-third']" size="2x" spin />
        <span>Uploading Files</span>
      </div>
    </div>
    <div v-if="deleteConfirmModal" class="fixed inset-0 flex items-center justify-center w-full h-full select-none z-50">
      <div class="absolute inset-0 bg-black bg-opacity-75 z-1" aria-hidden="true" />
      <div class="relative flex flex-col bg-white text-black shadow-lg rounded-lg overflow-hidden w-full z-5" style="min-width: 240px; max-width: 640px;">
        <div class="text-xl bg-gray-100 w-full px-4 py-4">
          Confirm Delete
        </div>
        <div class="flex-grow px-4 py-4">
          <p>Are you sure you want to permanently delete this?</p>
        </div>
        <div class="flex items-center justify-between bg-gray-100 w-full px-4 py-3 mt-8">
          <button
            class="text-black bg-transparent hover:bg-gray-200 rounded text-sm transition-all px-3 py-2"
            @click="deleteConfirmModal = false"
          >
            Cancel
          </button>
          <button
            class="text-white bg-red-600 hover:bg-red-700 rounded text-sm transition-all px-3 py-2"
            @click="confirmDelete"
          >
            Delete
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script type="text/javascript">
export default {
  name: 'SingleLayout',

  props: {
    model: {
      type: [Object, Array, Boolean],
      required: true
    },

    canEdit: {
      type: Boolean,
      required: false,
      default: () => { return true }
    },

    canDelete: {
      type: Boolean,
      required: false,
      default: () => { return true }
    },

    loading: {
      type: Boolean,
      required: false,
      default: () => { return false }
    },

    uploading: {
      type: Boolean,
      required: false,
      default: () => { return false }
    },

    routeBack: {
      type: [Boolean, Object],
      required: false,
      default: () => { return false }
    }
  },

  data () {
    return {
      dropdown: false,
      deleteConfirmModal: false
    }
  },

  methods: {
    warnDelete () {
      this.deleteConfirmModal = true
    },

    confirmDelete () {
      this.$emit('delete-model')
    }
  }
}
</script>
