<template>
  <NewModelLayout>
    <template #title>
      <h1 class="text-2xl">
        Add New Set
      </h1>
    </template>
    <template #form>
      <vue-form
        :form="form"
        :errors.sync="error.errors"
        ref="form"
        class="bg-gray-100 shadow rounded-sm"
      >
        <div class="bg-white px-6 py-4">
          <div class="flex flex-wrap w-row-offset-sm -mx-2">
            <div class="w-full lg:w-1/2 px-2 mb-4">
              <vue-input-group
                v-model="form.name"
                :errors="error.errors.name"
                name="name"
                prop="name"
                label="Name"
                description="The name of the set."
                required
              />
            </div>
            <div class="w-full lg:w-1/2 px-2 mb-4">
              <vue-input-group
                v-model="form.key"
                :errors="error.errors.key"
                name="key"
                prop="key"
                label="Key"
                description="The key that identifies this set."
                required
              />
            </div>
            <div class="w-full px-2 mb-4">
              <vue-select
                v-model="form.type"
                :options="setTypeOptions"
                :disable-on-null="false"
                name="type"
                prop="type"
                labelKey="label"
                valueKey="key"
                label="Set Type"
                description="Choose which type of items this set will contain."
              />
            </div>
          </div>
        </div>
        <div class="flex justify-between items-center w-full px-4 py-4">
          <vue-button
            text
            error
            @click="resetForm"
          >
            Reset
          </vue-button>
          <vue-button
            :icon="['fad', 'save']"
            type="submit"
            success
            @click="submitForm"
          >
            Add Set
          </vue-button>
        </div>
      </vue-form>
    </template>
  </NewModelLayout>
</template>
<script>
import { cloneDeep } from 'lodash'
import NewModelLayout from '@/components/models/NewModelLayout'

export default {
  name: 'SetsNew',

  components: {
    NewModelLayout
  },

  data () {
    return {
      loading: true,
      sets: [],
      form: {},
      defaults: {
        name: '',
        key: '',
        type: 'PurpleMountain\\PmmEcommerce\\Models\\Product',
        data: {
          images: {},
          layout: {
            cols: ['1fr'],
            rows: [],
            autoCols: '1fr',
            autoRows: '1fr'
          },
          seo: {
            keyphrase: '',
            title: '',
            description: ''
          }
        }
      },
      setTypeOptions: [
        {
          label: 'Products',
          key: 'PurpleMountain\\PmmEcommerce\\Models\\Product'
        },
        {
          label: 'Sets',
          key: 'PurpleMountain\\PmmEcommerce\\Models\\Set'
        },
        {
          label: 'Assemblies',
          key: 'PurpleMountain\\PmmEcommerce\\Models\\Assembly'
        },
        {
          label: 'Metadata',
          key: 'PurpleMountain\\PmmMetadata\\Models\\Metadata'
        },
        {
          label: 'Menus',
          key: 'App\\Models\\Menu'
        }
      ],
      error: {
        errors: {}
      }
    }
  },

  watch: {
    'form.name': function (val) {
      this.form.key = val.toLowerCase().replace(/-+/g, ' ').replace(/ +/g, '-').replace(/[^\w-]+/g, '')
    }
  },

  mounted () {
    this.initForm()
  },

  methods: {
    initForm () {
      this.form = cloneDeep(this.defaults)
    },

    resetForm () {
      this.initForm()
    },

    submitForm () {
      this.$refs.form.validate()
      this.$api.post('sets', this.form)
        .then((res) => {
          this.$router.push({
            name: 'sets.single',
            params: {
              model: res.id
            }
          })
        })
        .catch((data) => {
          this.$store.commit('error/addError', data)
        })
    }
  }
}
</script>
