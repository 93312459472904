<template>
  <div>
    <div class="flex justify-between mb-1">
      <div class="flex w-full md:w-2/6">
        <slot name="header-left" />
        <vue-table-filters
          v-if="filterKeys.length > 0"
          :filter-keys="filterKeys"
          @update:filters="updateFilters"
        />
      </div>
      <div class="flex w-full md:w-2/6">
        <vue-input-group
          v-model="search"
          prop="search"
          class="mr-1 w-full"
          placeholder="Search"
        />
        <vue-button
          @click="getData"
          :icon="['fad', 'sync-alt']"
          icon-size="md"
        />
        <slot name="header-right" />
      </div>
    </div>
    <div
      :class="{
        'flex w-full block overflow-hidden h-1': true,
        'bg-primary-light': loading,
        'bg-transparent': !loading
      }"
    >
      <div
        v-if="loading"
        class="horizontalLoading bg-primary-dark w-64 h-1"
      />
    </div>
    <table class="relative w-full text-left z-5">
      <thead>
        <th
          v-if="checkbox"
          class="bg-gray-200 p-2 border border-gray-300 w-1"
        >
          <div class="flex justify-between items-center">
            <vue-checkbox
              v-model="checkAll"
            />
          </div>
        </th>
        <th
          v-for="(col, headerKey) in cols"
          :key="headerKey"
          class="bg-gray-200 p-2 border border-gray-300"
        >
          <div class="flex justify-between items-center">
            <span
              :class="{
                'select-none': true,
                'cursor-pointer': col.sort
              }"
              @click="changeOrderBy(col, !meta.ascending)"
            >
              {{ col.header }}
            </span>
            <template v-if="col.sort">
              <font-awesome-icon
                v-if="!meta.ascending && meta.orderBy === col.value"
                :icon="['fad', 'sort-amount-up']"
                class="cursor-pointer"
                @click="changeOrderBy(col, true)"
              />
              <font-awesome-icon
                v-if="meta.ascending && meta.orderBy === col.value"
                :icon="['fad', 'sort-amount-down']"
                class="cursor-pointer"
                @click="changeOrderBy(col, false)"
              />
              <font-awesome-icon
                v-if="meta.orderBy !== col.value"
                :icon="['fad', 'sort-alt']"
                class="cursor-pointer"
                @click="changeOrderBy(col, false)"
              />
            </template>
          </div>
        </th>
      </thead>
      <tbody>
        <tr
          v-for="(row, rowKey) in data"
          :key="rowKey"
        >
          <td
            v-if="checkbox"
            class="p-2 border border-gray-300 w-1"
          >
            <vue-checkbox v-model="row.check" />
          </td>
          <td
            v-for="(col, colKey) in cols"
            :key="colKey"
            class="p-2 border border-gray-300"
          >
            <slot
              v-bind="row"
              :name="`item.${col.value}`"
            >
              {{ row[col.value] }}
            </slot>
          </td>
        </tr>
      </tbody>
    </table>

    <div class="flex mt-2 z-0">
      <vue-select
        v-model="meta.limit"
        :options="[10, 15, 50, 100, 250]"
        class="w-20"
      />

      <vue-button
        v-for="page in availablePaginationPages"
        :key="page"
        :outline="(page + 1) !== meta.page"
        class="ml-1"
        sm
        @click="meta.page = (page + 1)"
      >
        {{ page + 1 }}
      </vue-button>
    </div>
  </div>
</template>
<script>
import debounce from 'lodash.debounce'
import { find, isEmpty } from 'lodash'

export default {
  name: 'VueDataTable',

  props: {
    checkbox: {
      type: Boolean,
      required: false,
      default: () => { return false }
    },

    path: {
      type: String,
      required: true
    },

    cols: {
      type: Array,
      required: false,
      default: () => { return [] }
    },

    requestParams: {
      type: Object,
      required: false,
      default: () => { return {} }
    },

    filterKeys: {
      type: Array,
      required: false,
      default: () => { return [] }
    }
  },

  watch: {
    meta: {
      deep: true,
      handler () {
        if (!this.loading) {
          this.getData()
        }
      }
    },

    search: {
      handler () {
        this.getData()
      }
    },

    filters: {
      handler () {
        this.getData()
      }
    },

    checkAll: {
      handler () {
        this.toggleAllChecked()
      }
    }
  },

  data () {
    return {
      checkAll: false,
      loading: false,
      meta: {
        total: 0,
        ascending: false,
        orderBy: null,
        page: 1,
        limit: 15
      },
      search: null,
      filters: [],
      data: []
    }
  },

  mounted () {
    this.getData()
  },

  computed: {
    availablePaginationPages () {
      var numberOfPages = Math.ceil(this.meta.total / this.meta.limit)
      return numberOfPages ? [...Array(numberOfPages).keys()] : 0
    },

    anyChecked () {
      return !isEmpty(find(this.data, { check: true }))
    }
  },

  methods: {
    getData: debounce(function () {
      this.loading = true
      this.$api.get(this.path, {
        params: {
          ...{
            filters: this.filters,
            search: this.search
          },
          ...this.requestParams,
          ...this.meta
        }
      })
        .then(data => {
          this.data = data.data
          this.meta = {
            ...this.meta,
            ...{
              total: data.meta.total,
              page: data.meta.current_page,
              limit: data.meta.per_page
            }
          }
        })
        .catch(data => {
          this.$store.commit('error/addError', data)
        })
        .finally(() => {
          this.loading = false
        })
    }, 200),

    changeOrderBy (col, ascending) {
      if (col.sort) {
        this.meta.ascending = ascending
        this.meta.orderBy = col.value
      }
    },

    toggleAllChecked () {
      this.data.forEach(row => {
        row.check = this.checkAll
      })
    },

    updateFilters (filters) {
      this.filters = filters
    }
  }
}
</script>
<style type="text/css">
.horizontalLoading {
  margin-left: -250px;
  animation-name: horizontalLoading;
    -o-animation-name: horizontalLoading;
    -ms-animation-name: horizontalLoading;
    -webkit-animation-name: horizontalLoading;
    -moz-animation-name: horizontalLoading;
  animation-duration: 0.8s;
    -o-animation-duration: 0.8s;
    -ms-animation-duration: 0.8s;
    -webkit-animation-duration: 0.8s;
    -moz-animation-duration: 0.8s;
  animation-iteration-count: infinite;
    -o-animation-iteration-count: infinite;
    -ms-animation-iteration-count: infinite;
    -webkit-animation-iteration-count: infinite;
    -moz-animation-iteration-count: infinite;
  animation-timing-function: linear;
    -o-animation-timing-function: linear;
    -ms-animation-timing-function: linear;
    -webkit-animation-timing-function: linear;
    -moz-animation-timing-function: linear;
}

@keyframes horizontalLoading {
  0% {
    margin-left: -250px;
  }

  100% {
    margin-left: 2000px;
  }
}

@-o-keyframes horizontalLoading {
  0%{
    margin-left: -250px;
  }

  100%{
    margin-left: 2000px;
  }
}

@-ms-keyframes horizontalLoading{
  0%{
    margin-left: -250px;
  }

  100%{
    margin-left: 2000px;
  }
}

@-webkit-keyframes horizontalLoading{
  0%{
    margin-left: -250px;
  }

  100%{
    margin-left: 2000px;
  }
}

@-moz-keyframes horizontalLoading{
  0%{
    margin-left: -250px;
  }

  100%{
    margin-left: 2000px;
  }
}
</style>
