<template>
  <div class="container flex flex-col space-y-4 mx-auto">
    <div class="flex w-full justify-center py-4">
      <slot name="title" />
    </div>
    <div class="w-full">
      <slot name="form" />
    </div>
  </div>
</template>
<script type="text/javascript">
export default {
  name: 'NewModelLayout'
}
</script>
