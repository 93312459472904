import { merge, cloneDeep } from 'lodash'

export default {
  data () {
    return {
      loading: false
    }
  },

  methods: {
    duplicateModel (model) {
      this.loading = true

      const oldModel = cloneDeep(model)
      const newName = `${oldModel.value ? oldModel.value.name || oldModel.name || 'Item' : oldModel.name || 'Item'} (Duplicate)`

      this.$api.post(this.model.apiEndpoint, merge(oldModel, {
        key: this.getNewModelKey(oldModel.key),
        name: newName,
        protected: false,
        value: {
          name: newName
        }
      }))
        .then((res) => {
          this.$router.push({
            name: this.routes.single,
            params: {
              model: res.id || res.data.id
            }
          })
        })
        .catch((data) => {
          this.$store.commit('error/addError', data)
        })
        .finally(() => {
          this.loading = false
        })
    },

    getNewModelKey (key) {
      const unique = `__duplicate_${Date.now()}`
      return key.includes('__duplicate') ? `${key.substr(0, key.length - unique.length)}${unique}` : `${key}${unique}`
    }
  }
}
