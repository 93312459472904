<template>
  <div class="flex flex-col">
    <div class="w-full bg-gray-100 text-left px-2 py-2">
      <label>Content</label>
    </div>
    <div class="flex flex-col border border-gray-100 border-t-0 space-y-2 py-2">
      <div class="w-full px-2">
        <vue-input-group
          v-model="item.content.header"
          name="header"
          prop="header"
          label="Header"
          description="If left empty, the menu name will be used"
        />
      </div>
      <div class="w-full px-2">
        <vue-input-group
          v-model="item.content.menu"
          :options="menus"
          name="menu"
          prop="menu"
          label="Menu"
          labelKey="name"
          valueKey="id"
          description="Choose the menu to display items from"
          type="select"
        />
      </div>
      <div class="w-full px-2">
        <vue-button
          primary
          outline
          sm
          class="flex justify-center w-full"
          @click="$store.dispatch('editor/updateMenus')"
        >
          Refresh menus
        </vue-button>
      </div>
    </div>
    <GridItemLayoutConfig
      :item="item"
    />
    <GridItemColorsConfig
      :item="item"
      :extra-options="extraColorFields"
      :hide="['icon']"
    />
  </div>
</template>
<script>
import GridItemLayoutConfig from '../../GridItemLayoutConfig'
import GridItemColorsConfig from '../../GridItemColorsConfig'

export default {
  components: {
    GridItemLayoutConfig,
    GridItemColorsConfig
  },

  props: {
    item: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      extraColorFields: {
        header: {
          label: 'Header Color',
          inherit: 'text',
          allowNull: true
        }
      }
    }
  },

  computed: {
    menus () {
      return this.$store.state.editor.menus || []
    },

    selectedMenu () {
      return this.item.content.menu ? this.$store.getters['editor/getMenuById'](this.item.content.menu) : false
    }
  }
}
</script>
