<template>
  <div
    :class="{
      'p-3 border-l-4 rounded bg-gray-200 text-sm': true,
      'bg-primary-light border-primary-dark text-primary-dark': primary,
      'bg-green-300 border-green-700 text-green-700': success,
      'bg-red-300 border-red-700 text-red-700': error,
      'bg-blue-300 border-blue-700 text-blue-700': info
    }"
  >
    <slot>
      {{ message }}
    </slot>
  </div>
</template>
<script type="text/javascript">
export default {
  name: 'VueAlert',

  props: {
    message: {
      type: String,
      required: false,
      default: () => { return null }
    },

    primary: {
      type: Boolean,
      required: false,
      default: () => { return false }
    },

    error: {
      type: Boolean,
      required: false,
      default: () => { return false }
    },

    success: {
      type: Boolean,
      required: false,
      default: () => { return false }
    },

    info: {
      type: Boolean,
      required: false,
      default: () => { return false }
    }
  }
}
</script>
