<template>
  <vue-slider
    v-model="localValue"
    v-bind="{ ...$attrs, ...options }"
    v-on="$listeners"
  />
</template>
<script>
import HasVModel from '@/mixins/HasVModel'
import VueSlider from 'vue-slider-component/dist-css/vue-slider-component.umd.min.js'
import 'vue-slider-component/dist-css/vue-slider-component.css'
import 'vue-slider-component/theme/default.css'

export default {
  name: 'VueRange',

  components: {
    VueSlider
  },

  mixins: [
    HasVModel
  ],

  props: {
    lazy: {
      type: Boolean,
      required: false,
      default: () => { return false }
    }
  },

  computed: {
    options () {
      return {
        ...{
          lazy: this.lazy,
          adsorb: true,
          'drag-on-click': true,
          'dot-size': [16, 16]
        },
        ...this.$attrs.options
      }
    }
  }
}
</script>
