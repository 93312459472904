<template>
  <div class="flex flex-col">
    <div class="w-full bg-gray-100 text-left px-2 py-2">
      <label>Content</label>
    </div>
    <div class="flex flex-col border border-gray-100 border-t-0 space-y-2 py-2">
      <div class="w-full px-2">
        <vue-input-group
          v-model="item.content.category"
          :options="categories"
          name="category"
          prop="category"
          label="Category"
          labelKey="name"
          valueKey="id"
          description="Choose the category to display and link to"
          type="select"
        />
      </div>
      <div class="w-full px-2">
        <vue-button
          primary
          outline
          sm
          class="flex justify-center w-full"
          @click="$store.dispatch('editor/updateCategories')"
        >
          Refresh categories
        </vue-button>
      </div>
    </div>
    <GridItemLayoutConfig
      :item="item"
      :hide="['width', 'aspect', 'padding']"
      :extra-options="extraLayoutFields"
    />
    <GridItemColorsConfig
      :item="item"
      :hide="['bg_image', 'icon']"
      :extra-options="extraColorFields"
    />
  </div>
</template>
<script>
import GridItemLayoutConfig from '../../GridItemLayoutConfig'
import GridItemColorsConfig from '../../GridItemColorsConfig'

export default {
  components: {
    GridItemLayoutConfig,
    GridItemColorsConfig
  },

  props: {
    item: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      extraLayoutFields: {
        position: {
          label: 'Position',
          type: 'select',
          options: [
            { label: 'Default', value: 'default' },
            { label: 'Centred', value: 'center' }
          ]
        }
      },
      extraColorFields: {
        svg: {
          label: 'Image Colour',
          inherit: 'background',
          allowNull: false
        }
      }
    }
  },

  computed: {
    categories () {
      return this.$store.state.editor.categories || []
    },

    selectedCategory () {
      return this.item.content.category ? this.$store.getters['editor/getCategoryById'](this.item.content.category) : false
    }
  }
}
</script>
