var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"flex justify-between mb-1"},[_c('div',{staticClass:"flex w-full md:w-2/6"},[_vm._t("header-left"),(_vm.filterKeys.length > 0)?_c('vue-table-filters',{attrs:{"filter-keys":_vm.filterKeys},on:{"update:filters":_vm.updateFilters}}):_vm._e()],2),_c('div',{staticClass:"flex w-full md:w-2/6"},[_c('vue-input-group',{staticClass:"mr-1 w-full",attrs:{"prop":"search","placeholder":"Search"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('vue-button',{attrs:{"icon":['fad', 'sync-alt'],"icon-size":"md"},on:{"click":_vm.getData}}),_vm._t("header-right")],2)]),_c('div',{class:{
      'flex w-full block overflow-hidden h-1': true,
      'bg-primary-light': _vm.loading,
      'bg-transparent': !_vm.loading
    }},[(_vm.loading)?_c('div',{staticClass:"horizontalLoading bg-primary-dark w-64 h-1"}):_vm._e()]),_c('table',{staticClass:"relative w-full text-left z-5"},[_c('thead',[(_vm.checkbox)?_c('th',{staticClass:"bg-gray-200 p-2 border border-gray-300 w-1"},[_c('div',{staticClass:"flex justify-between items-center"},[_c('vue-checkbox',{model:{value:(_vm.checkAll),callback:function ($$v) {_vm.checkAll=$$v},expression:"checkAll"}})],1)]):_vm._e(),_vm._l((_vm.cols),function(col,headerKey){return _c('th',{key:headerKey,staticClass:"bg-gray-200 p-2 border border-gray-300"},[_c('div',{staticClass:"flex justify-between items-center"},[_c('span',{class:{
              'select-none': true,
              'cursor-pointer': col.sort
            },on:{"click":function($event){return _vm.changeOrderBy(col, !_vm.meta.ascending)}}},[_vm._v(" "+_vm._s(col.header)+" ")]),(col.sort)?[(!_vm.meta.ascending && _vm.meta.orderBy === col.value)?_c('font-awesome-icon',{staticClass:"cursor-pointer",attrs:{"icon":['fad', 'sort-amount-up']},on:{"click":function($event){return _vm.changeOrderBy(col, true)}}}):_vm._e(),(_vm.meta.ascending && _vm.meta.orderBy === col.value)?_c('font-awesome-icon',{staticClass:"cursor-pointer",attrs:{"icon":['fad', 'sort-amount-down']},on:{"click":function($event){return _vm.changeOrderBy(col, false)}}}):_vm._e(),(_vm.meta.orderBy !== col.value)?_c('font-awesome-icon',{staticClass:"cursor-pointer",attrs:{"icon":['fad', 'sort-alt']},on:{"click":function($event){return _vm.changeOrderBy(col, false)}}}):_vm._e()]:_vm._e()],2)])})],2),_c('tbody',_vm._l((_vm.data),function(row,rowKey){return _c('tr',{key:rowKey},[(_vm.checkbox)?_c('td',{staticClass:"p-2 border border-gray-300 w-1"},[_c('vue-checkbox',{model:{value:(row.check),callback:function ($$v) {_vm.$set(row, "check", $$v)},expression:"row.check"}})],1):_vm._e(),_vm._l((_vm.cols),function(col,colKey){return _c('td',{key:colKey,staticClass:"p-2 border border-gray-300"},[_vm._t(("item." + (col.value)),function(){return [_vm._v(" "+_vm._s(row[col.value])+" ")]},null,row)],2)})],2)}),0)]),_c('div',{staticClass:"flex mt-2 z-0"},[_c('vue-select',{staticClass:"w-20",attrs:{"options":[10, 15, 50, 100, 250]},model:{value:(_vm.meta.limit),callback:function ($$v) {_vm.$set(_vm.meta, "limit", $$v)},expression:"meta.limit"}}),_vm._l((_vm.availablePaginationPages),function(page){return _c('vue-button',{key:page,staticClass:"ml-1",attrs:{"outline":(page + 1) !== _vm.meta.page,"sm":""},on:{"click":function($event){_vm.meta.page = (page + 1)}}},[_vm._v(" "+_vm._s(page + 1)+" ")])})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }