<template>
  <div class="contents">
    <template v-if="template && templateBlocks" class="contents">
      <div v-for="item in templateBlocks" :key="item.key" class="contents">
        <component
          v-if="item.element"
          :is="getRenderElement(item.element)"
          :item="item"
        />
      </div>
    </template>
    <div v-else class="flex flex-col items-center justify-center w-full h-full space-y-2 py-8">
      <p class="text-lg">
        Template Block
      </p>
      <p>
        No template selected
      </p>
    </div>
  </div>
</template>
<script>
export default {
  friendlyName: 'Template',

  props: {
    item: {
      type: Object,
      required: true
    }
  },

  computed: {
    template () {
      return this.item && this.item.content && this.item.content.template ? this.$store.getters['editor/getTemplateByKey'](this.item.content.template) || false : false
    },

    templateBlocks () {
      return this.template ? this.template.value.template.layout.items || false : false
    }
  },

  methods: {
    getRenderElement (element) {
      const found = this.$store.getters['editor/getElementByKey'](element)
      return found ? found.render : false
    }
  }
}
</script>
