var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"space-y-2"},[_c('button',{staticClass:"flex items-center justify-between w-full py-1",on:{"click":function($event){_vm.expanded = !_vm.expanded}}},[_c('h4',{staticClass:"text-lg"},[_vm._v("Snippet Preview")]),_c('font-awesome-icon',{class:{
        'transform-gpu transition-transform': true,
        'scale-y-100': !_vm.expanded,
        '-scale-y-100': _vm.expanded
      },attrs:{"icon":['fal', 'chevron-down'],"fixed-width":""}})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.expanded),expression:"expanded"}],staticClass:"flex flex-col space-y-2"},[_c('div',{staticClass:"relative bg-white shadow px-4 py-4"},[(_vm.url)?_c('div',{ref:"url",staticClass:"text-xs"},[_vm._v(" "+_vm._s(_vm.baseUrl + _vm.url.slice(1))+" ")]):_vm._e(),(_vm.title)?_c('div',{staticClass:"text-lg text-blue-600"},[_vm._v(" "+_vm._s(_vm.title)+" ")]):_vm._e(),(_vm.title)?_c('div',{ref:"title",staticClass:"absolute h-0 text-lg overflow-y-hidden whitespace-nowrap",attrs:{"aria-hidden":"true"}},[_vm._v(" "+_vm._s(_vm.title)+" ")]):_vm._e(),(_vm.description)?_c('div',{ref:"description",staticClass:"text-gray-800 text-sm"},[_vm._v(" "+_vm._s(_vm.description.substr(0, _vm.maxDescriptionLength))+" "+_vm._s(_vm.description.length > _vm.maxDescriptionLength ? '...' : '')+" ")]):_vm._e()]),_c('div',{staticClass:"flex items-center justify-between"},[_c('span',[_vm._v("Title: "+_vm._s(_vm.titleLength)+"px / "+_vm._s(_vm.maxTitleWidth)+"px ("+_vm._s(_vm.titleLengthPercent.toFixed(2))+"%)")]),(_vm.titleScore)?_c('span',{class:{
          'rounded-full font-bold text-white text-sm uppercase px-2 py-1': true,
          'bg-green-700': _vm.titleScore === 'good',
          'bg-yellow-500': _vm.titleScore === 'ok',
          'bg-red-700': _vm.titleScore === 'bad'
        }},[_vm._v(" "+_vm._s(_vm.titleScore)+" ")]):_vm._e()]),(_vm.description)?_c('div',{staticClass:"flex items-center justify-between"},[_c('span',[_vm._v("Description: "+_vm._s(_vm.description.length)+" / "+_vm._s(_vm.maxDescriptionLength)+" ("+_vm._s(_vm.descriptionLengthPercent.toFixed(2))+"%)")]),(_vm.descriptionScore)?_c('span',{class:{
          'rounded-full font-bold text-white text-sm uppercase px-2 py-1': true,
          'bg-green-700': _vm.descriptionScore === 'good',
          'bg-yellow-500': _vm.descriptionScore === 'ok',
          'bg-red-700': _vm.descriptionScore === 'bad'
        }},[_vm._v(" "+_vm._s(_vm.descriptionScore)+" ")]):_vm._e()]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }