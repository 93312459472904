<template>
  <RootBlock :item="item" :padding="false">
    <div class="grid grid-cols-1 auto-rows-auto w-full h-full">
      <div v-if="hasImage" class="row-start-1 h-0 aspect-w-3 aspect-h-2 xl:aspect-w-1 xl:aspect-h-1">
        <img
          :src="item.images[`bio_photo_${item.key}`].medium"
          :alt="imageAlt"
          :title="imageAlt"
          class="w-full h-full object-cover object-top"
        >
      </div>
      <div
        :class="{
          'flex flex-col space-y-2 px-gap-xs py-gap-xs': true,
          'xl:row-start-2 justify-between': content && (content.type !== 'full' || hasImage),
          'xl:row-start-1 xl:row-span-2': content && content.type !== 'full' && !hasImage,
          'justify-start': content && content.type === 'full'
        }"
      >
        <div>
          <component :is="layout ? layout.header || 'h3' : 'h3'" v-if="content && content.name" :class="`${content && content.type ? (content.type === 'full' ? 'text-h1' : 'truncate text-h2') : 'truncate text-h2'} ${colors && colors.header ? colors.header : colors.text || 'text-white'}`">
            {{ content.name }}
          </component>
          <p v-if="content && content.position" :class="`block ${content.type !== 'full' ? 'truncate' : ''} ${content && content.type && content.type === 'full' ? 'text-h2' : ''} ${colors && colors.subtitle ? colors.subtitle : colors.text || 'text-white'}`">
            {{ content.position }}
          </p>
        </div>
        <div class="flex-grow">
          <div
            v-if="content && content.bio"
            :class="{
              'markdown-render': true,
              'truncate': content.type !== 'full'
            }"
            v-html="content.bio"
          />
        </div>
        <a
          v-if="content && content.contact_email"
          :class="`text-h2 ${colors && colors.subtitle ? colors.subtitle : colors.text || 'text-white'} truncate`"
          :href="`mailto:${content.contact_email}`">
          {{ content.contact_email }}
        </a>
      </div>
    </div>
  </RootBlock>
</template>
<script>
import RootBlock from '../RootBlock'

export default {
  friendlyName: 'Staff Member Block',

  components: {
    RootBlock
  },

  props: {
    item: {
      type: Object,
      required: true
    }
  },

  computed: {
    content () {
      return this.item.content || false
    },

    layout () {
      return this.item.layout || false
    },

    colors () {
      return this.item.colors || false
    },

    hasImage () {
      return this.item.images && this.item.images[`bio_photo_${this.item.key}`]
    },

    imageAlt () {
      return this.hasImage ? this.content[`bio_photo_${this.item.key}_alt`] || '' : ''
    }
  }
}
</script>
