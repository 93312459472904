<template>
  <div class="flex flex-col">
    <div class="w-full bg-gray-100 text-left px-2 py-2">
      <label>Content</label>
    </div>
    <div class="flex flex-col border border-gray-100 border-t-0 space-y-2 py-2">
      <div class="w-full px-2">
        <vue-input-group
          v-model="item.content.header"
          prop="header"
          name="header"
          label="Section Header"
        />
      </div>
      <div class="w-full px-2">
        <vue-input-group
          v-model="item.content.category"
          :options="categories"
          name="category"
          prop="category"
          label="Category Set"
          labelKey="name"
          valueKey="id"
          description="Choose the category set to display items from"
          type="select"
        />
      </div>
      <div class="w-full px-2">
        <vue-input-group
          v-model="item.content.columnCount"
          :options="[1,2,3,4,5,6,7,8,9]"
          name="column_count"
          prop="column_count"
          label="Columns"
          description="Pick how many columns should be displayed on desktop"
          type="select"
        />
      </div>
      <div class="w-full px-2">
        <vue-input-group
          v-model="item.content.columnCountMobile"
          :options="[1,2,3,4,5,6]"
          name="column_count_mobile"
          prop="column_count_mobile"
          label="Columns (Mobile)"
          description="Pick how many columns should be displayed on mobile"
          type="select"
        />
      </div>
      <div class="w-full px-2">
        <vue-button
          primary
          outline
          sm
          class="flex justify-center w-full"
          @click="getCategories"
        >
          Refresh categories
        </vue-button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    item: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      categories: []
    }
  },

  mounted () {
    this.getCategories()
  },

  methods: {
    getCategories () {
      this.$api.get('sets', {
        params: {
          type: 'PurpleMountain\\PmmEcommerce\\Models\\Set',
          with: ['sets']
        }
      })
        .then((res) => {
          this.categories = res.data
        })
    }
  }
}
</script>
