<template>
  <div class="px-gap-2xl">
    <TitleSummaryCard
        class="mb-4"
        :edit.sync="edit"
        @edit="startEdit"
        @save="saveEdits"
        @discard="discardEdit"
      >
      </TitleSummaryCard>

      <div class="my-5">
        <vue-data
        v-model="bacs.enabled"
        :value="bacs.enabled"
        :edit="edit"
        type="checkbox"
        :label="bacs.enabled ? 'Bacs Enabled' : 'Enable Bacs'"
        />
      </div>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash'
import TitleSummaryCard from '@/components/models/TitleSummaryCard'

export default {

  name: 'PaymentsBacs',

  components: {
    TitleSummaryCard
  },

  data () {
    return {
      edit: false,
      view: false,
      bacsOld: false,
      bacs: {
        enabled: ''
      },
      route: {
        apiEndpoint: 'pmtConfig'
      }
    }
  },

  async mounted () {
    const { data } = await this.$api.get(this.route.apiEndpoint, {
      params: {
        payment_type: 'bacs'
      }
    })
    this.bacs.enabled = data.bacs.enabled
  },

  methods: {
    async toggleEnabled () {
      try {
        const { data } = await this.$api.put(this.route.apiEndpoint, {
          payment_type: 'bacs',
          enabled: this.bacs.enabled
        })
        console.log(data)
        this.bacs.enabled = data.bacs.enabled
      } catch (error) {
        this.$store.commit('error/addError', error)
      }
    },
    startEdit () {
      this.bacsOld = cloneDeep(this.bacs)
    },

    saveEdits () {
      this.toggleEnabled()
      this.edit = false
    },

    discardEdit () {
      this.bacsOld = false
      this.edit = false
    }
  }
}
</script>

<style>

</style>
