<template>
  <div class="flex flex-col items-center justify-center border-2 border-dashed border-gray-300 text-gray-600 text-center rounded appearance-none">
    <slot />
  </div>
</template>
<script>
export default {
  name: 'NewGridItem'
}
</script>
