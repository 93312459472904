<template>
  <div class="flex flex-col">
    <div class="flex flex-col border border-gray-100 border-t-0 space-y-2 py-2">
      <div class="w-full px-2">
        <vue-data
          v-model="item.images[`foreground_image_${item.key}`]"
          :value="item.images[`foreground_image_${item.key}`]"
          :edit="true"
          class="mb-2"
          label="Foreground Image"
          type="file"
          accept="image/png, image/jpeg, image/gif"
          @update="syncImage($event, `foreground_image_${item.key}`)"
          @discard="removeImage(`foreground_image_${item.key}`)"
        />
      </div>
      <div class="w-full px-2">
        <vue-input-group
          v-model="item.content.foreground_image_alt"
          prop="foreground_image_alt"
          name="foreground_image_alt"
          label="Foreground Image Alt Text"
        />
      </div>
      <div class="w-full px-2">
        <vue-input-group
          v-model="item.content.header"
          prop="header"
          name="header"
          label="Title"
        />
      </div>
      <div class="w-full px-2">
        <vue-input-group
          v-model="item.content.footer"
          prop="footer"
          name="footer"
          label="Subtitle"
        />
      </div>
    </div>
    <GridItemLayoutConfig
      :item="item"
    />
    <GridItemColorsConfig
      :item="item"
      :hide="['icon']"
    />
  </div>
</template>
<script>
import GridItemLayoutConfig from '../../GridItemLayoutConfig'
import GridItemColorsConfig from '../../GridItemColorsConfig'
import HasImageUpload from '../../../mixins/HasImageUpload'

export default {
  components: {
    GridItemLayoutConfig,
    GridItemColorsConfig
  },

  mixins: [
    HasImageUpload
  ],

  props: {
    item: {
      type: Object,
      required: true
    }
  }
}
</script>
