<template>
  <div>
    <div>
      <TitleSummaryCard
        class="mb-4 pt-0"
        :class="'float-left'"
        :edit.sync="edit"
        @discard="discardEdit"
      >
      <template #title>
          <h3>Admin Users</h3>
        </template>
      </TitleSummaryCard>
    </div>
    <div>
      <vue-data-table
       ref="dataTable"
      :key="componentKey"
      :cols="[
        {
          header: 'User Name',
          value: 'name',
        },
        {
          header: 'Email Address',
          value: 'email'
        },
        {
          header: 'Added At',
          value: 'created_at',
          sort: true
        },
        {
          header: 'Last Modified',
          value: 'updated_at',
          sort: true
        },
        {
          header: 'Action',
          value: 'actions',
        }
      ]"
      :path="path"
    >
    <template #header-left>
      <vue-button
        v-if="edit"
        :icon="['fas', 'plus-circle']"
        text
        @click="assignNewModels"
      />
    </template>
      <template v-slot:item.title="row">
        {{ row.value.title }}
      </template>
      <template v-slot:item.ref="row">
        {{ row.value.ref }}
      </template>
      <template v-slot:item.created_at="row">
        {{ $momentDateTimeHuman(row.created_at) }}
      </template>
      <template v-slot:item.updated_at="row">
        {{ $momentDateTimeHuman(row.updated_at) }}
      </template>
      <template v-slot:item.actions="row">
        <vue-button
          :icon="['fad', 'trash']"
          :aria-label="`Delete ${row.name} from Admins`"
          :title="`Delete ${row.name} from Admins`"
          error
          sm
          class="inline-flex cursor-pointer"
          @click="removeAdmin(row.id)"
        />
      </template>
    </vue-data-table>
    <vue-dialog :dialog.sync="form.modal" class="z-50">
      <template #title>
        Assign New Admin User
      </template>
      <div class="flex flex-col">
        <div class="flex mb-2">
          <vue-input
            v-model="form.search"
            append
            placeholder="Search for users"
            class="flex-grow"
          />
          <vue-button
            sm
            append
            @click="searchModels"
          >
            Find
          </vue-button>
        </div>
        <div v-if="!form.loading && form.models.length > 0" class="flex flex-col flex-grow overflow-y-auto my-2">
          <div>
            <!-- <vue-checkbox-group
              v-model="form.new"
              :data="form.models"
              :label-key="model.name"
            /> -->
            <div v-for="user in form.models" :key="user.id">
              <!-- <input type="checkbox" name="" id=""> -->
              <!-- <label>{{ user.name }}  - {{ user.email }}</label> -->
              <label class="flex flex-grow justify-start items-start py-2">
                <div class="bg-white border rounded border-gray-300 w-5 h-5 flex flex-shrink-0 justify-center items-center mr-2 focus-within:border-blue-500 hover:border-gray-400">
                  <input
                    v-model="form.new"
                    :value="user"
                    type="checkbox"
                    class="opacity-0 absolute"
                    @input="localErrors = []"
                  >
                  <svg class="fill-current hidden w-3 h-3 text-primary pointer-events-none" viewBox="0 0 20 20"><path d="M0 11l2-2 5 5L18 3l2 2L7 18z"/></svg>
                </div>
                <div class="w-full flex gap-2 items-center">
                  <span>{{ user.name }}</span>
                  <span class="text-sm">{{ user.email }}</span>
                </div>
              </label>
            </div>
          </div>
        </div>
        <div v-else-if="!form.loading" class="my-2">
          No results found
        </div>
        <div v-if="form.loading" class="my-2">
          Loading...
        </div>
      </div>
      <template #action>
        <vue-button success class="justify-center w-full" @click="addModels">
          Add Users
        </vue-button>
      </template>
    </vue-dialog>
    </div>
  </div>
</template>

<script>
import { differenceBy, intersectionBy, pullAt, findIndex } from 'lodash'
import TitleSummaryCard from '@/components/models/TitleSummaryCard'
import VueDialog from '@/components/VueDialog'

export default {
  name: 'UsersAdmin',

  components: {
    TitleSummaryCard,
    VueDialog
  },

  data () {
    return {
      edit: false,
      form: {
        loading: false,
        models: false,
        new: [],
        search: '',
        modal: false
      },
      models: false,
      componentKey: 0,
      path: 'adminUsers'
    }
  },

  mounted () {
    this.getModels()
  },

  watch: {
    'form.modal': function () {
      this.form.search = ''
      this.form.new = []
    }
  },

  methods: {
    assignNewModels () {
      this.form.search = ''
      this.getModels()
      this.form.modal = true
    },

    getModels () {
      this.form.loading = true
      const adminUsers = this.$refs.dataTable.data.map(item => item.id)

      this.$api.get('users', {
        params: {
          type: this.filter,
          search: this.form.search,
          exclude: [...adminUsers]
        }
      })
        .then((res) => {
          this.models = this.$refs.dataTable.data
          this.form.models = differenceBy(res.data, this.models, 'id')
        })
        .finally(() => {
          this.form.loading = false
        })
    },

    getAdmins () {
      this.$api.get('adminUsers')
        .then((res) => {
          this.models = this.$refs.dataTable.data
          this.form.models = differenceBy(res.data, this.models, 'id')
        })
        .finally(() => {
          this.form.loading = false
        })
    },

    addModels () {
      const payload = {
        admins: [
          ...this.models,
          ...intersectionBy(this.form.models, this.form.new, 'id')
        ]
      }
      console.log('payload', payload)
      this.$api.post('adminUsers/assign-role', payload)
        .then((res) => {
          this.discardEdit()
          this.forceRerender()
        })
        .catch((err) => {
          this.$store.commit('error/addError', err)
        })
      this.form.new = []
      this.form.modal = false
    },

    removeModel (id) {
      pullAt(this.models, findIndex(this.models, { id }))
      this.$emit('update:models', this.models)
    },

    removeAdmin (id) {
      this.$api.post(`adminUsers/${id}/remove-role`, { id })
        .then((res) => {
          this.discardEdit()
          this.forceRerender()
        })
        .catch((err) => {
          this.$store.commit('error/addError', err)
        })
    },

    forceRerender () {
      this.componentKey += 1
    },

    searchModels () {
      this.getModels()
    },

    discardEdit () {
      this.edit = false
    }
  }

}
</script>

<style lang="css" scoped>
.pt-0 {
  padding-top: 0!important;
}
</style>
