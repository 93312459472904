<template>
  <div class="w-full">
    <div
      v-for="(setting, settingKey) in settings"
      :key="settingKey"
      class="w-full"
    >
      <button class="w-full bg-gray-100 text-left px-2 py-2">
        <label>{{ setting.label }}</label>
      </button>
      <div class="border border-gray-100 border-t-0 px-2 py-2">
        <div v-if="setting.sizes" class="grid grid-cols-1 md:grid-cols-2 gap-2">
          <div
            v-for="(size, sizeKey) in setting.sizes"
            :key="sizeKey"
          >
            <vue-input-group
              v-model="item.layout[`${settingKey}_${sizeKey}`]"
              :prop="`${settingKey}_${sizeKey}`"
              :name="`${settingKey}_${sizeKey}`"
              :label="getSizeSettingLabel(sizeKey)"
              :type="setting.type"
              :options="size.options"
              :disable-on-null="false"
              :allow-none="setting.allow_none"
              labelKey="label"
              valueKey="value"
            />
          </div>
        </div>
        <vue-input-group
          v-else
          v-model="item.layout[settingKey]"
          :prop="settingKey"
          :name="settingKey"
          :type="setting.type"
          :options="setting.options || []"
          :disable-on-null="false"
          :allow-none="setting.allow_none"
          labelKey="label"
          valueKey="value"
        />
      </div>
    </div>
    <div v-if="childBlockPresent" class="w-full">
      <button class="w-full bg-gray-100 text-left px-2 py-2">
        <label>Child Page</label>
      </button>
      <div class="border border-gray-100 border-t-0 px-2 py-2">
        <vue-input-group
          v-model="item.layout.hideOnChildPage"
          prop="hideOnChildPage"
          name="hideOnChildPage"
          type="checkbox"
          label="Hide this block when the a child page is loaded."
        />
      </div>
    </div>
  </div>
</template>
<script>
import { mapValues, omitBy } from 'lodash'
import LayoutConfig from '../mixins/LayoutConfig.js'

export default {
  mixins: [
    LayoutConfig
  ],

  props: {
    item: {
      type: Object,
      required: true
    },

    hide: {
      type: Array,
      required: false,
      default: () => { return [] }
    },

    extraOptions: {
      type: Object,
      required: false,
      default: () => { return {} }
    }
  },

  computed: {
    settings () {
      let extra = this.extraOptions
      if (Object.values(extra).length > 0) {
        extra = mapValues(extra, (field) => {
          return {
            ...field,
            ...(field.inherit ? {
              type: this.layout[field.inherit].type,
              options: this.layout[field.inherit].options
            } : {})
          }
        })
      }
      return omitBy({
        ...this.layout,
        ...extra
      }, (value, key) => {
        return this.hide.includes(key)
      })
    },

    childBlockPresent () {
      return this.$store.state.editor.editorHasChildContent || false
    }
  },

  methods: {
    getSizeSettingLabel (key) {
      switch (key) {
        case 'base':
          return 'Mobile'
        case 'xl':
          return 'Desktop'
        case 'x':
          return 'Left/Right'
        case 'y':
          return 'Top/Bottom'
        default:
          return 'Default'
      }
    }
  }
}
</script>
