<template>
  <div class="flex flex-col space-y-4">
    <div class="space-y-2">
      <h2 class="text-2xl">
        SEO
      </h2>
      <vue-data
        v-model="rootData.seo.keyphrase"
        :value="rootData.seo.keyphrase"
        :edit="edit"
        label="Focus Keyphrase"
      />
      <vue-data
        v-model="rootData.seo.title"
        :value="rootData.seo.title"
        :edit="edit"
        label="Page Title"
      />
      <vue-data
        v-model="rootData.seo.description"
        :value="rootData.seo.description"
        :edit="edit"
        label="Page Description"
        type="textarea"
      />
    </div>
    <content-assessor
      :title="seo.title"
      :description="seo.description"
      :keyword="seo.keyword"
      :url="seo.url"
      :permalink="seo.permalink"
      :locale="seo.locale"
      :text="textToAnalyse"
    />
    <seo-assessor
      :title="seo.title"
      :description="seo.description"
      :keyword="seo.keyword"
      :url="seo.url"
      :permalink="seo.permalink"
      :locale="seo.locale"
      :text="textToAnalyse"
    />
    <snippet-preview
      :title="seo.title"
      :description="seo.description"
      :url="seo.url"
      :baseUrl="`${seo.base_url}${urlPrefix || ''}`"
      @update:titleWidth="(value) => { titleWidth = value }"
      @update:titleLengthPercent="(value) => { titleLengthPercent = value }"
      @update:descriptionLengthPercent="(value) => { descriptionLengthPercent = value }"
    />
  </div>
</template>
<script>
import SnippetPreview from './SnippetPreview'
import ContentAssessor from './ContentAssessor'
import SeoAssessor from './SeoAssessor'
import markdownIt from 'markdown-it'
import { map, isEmpty, filter } from 'lodash'

export default {
  name: 'SeoSection',

  components: {
    SnippetPreview,
    ContentAssessor,
    SeoAssessor
  },

  props: {
    model: {
      type: [Array, Object],
      required: true
    },

    edit: {
      type: Boolean,
      required: false,
      default: () => { return false }
    },

    urlPrefix: {
      type: [String, Boolean],
      required: false,
      default: () => { return null }
    },

    slug: {
      type: [String, Boolean],
      required: false,
      default: () => { return null }
    }
  },

  data () {
    return {
      titleWidth: 0,
      titleLengthPercent: 0,
      descriptionLengthPercent: 0,
      md: new markdownIt() // eslint-disable-line new-cap
    }
  },

  computed: {
    seo () {
      return {
        title: this.rootData.seo.title,
        description: this.rootData.seo.description,
        keyword: this.rootData.seo.keyphrase,
        url: `/${this.urlSlug}`,
        base_url: process.env.VUE_APP_API_BASE_URL,
        permalink: `${process.env.VUE_APP_API_BASE_URL}${this.urlSlug}`,
        locale: 'en_GB'
      }
    },

    rootData () {
      return this.model.value || this.model.data || this.model
    },

    urlSlug () {
      return this.rootData.slug || this.slug || this.model.key || this.model.id
    },

    textToAnalyse () {
      const input = [
        ...[
          this.rootData && this.rootData.title ? `<h1>${this.rootData.title}</h1>` : null
        ],
        ...(this.rootData && this.rootData.layout && this.rootData.layout.items
          ? map(this.rootData.layout.items, (item) => {
            const template = item.content.template ? this.$store.getters['editor/getTemplateByKey'](item.content.template) || false : false
            const items = [
              (item.content.items && item.content.items.length > 0 ? map(item.content.items, (item, index) => {
                return this.filterInput([
                  item.content.header && item.content.header !== 'New Block' ? `<h2>${item.content.header}</h2>` : null,
                  item.content.content && item.content.content !== 'Put your main content here' ? this.md.render(item.content.content) || null : null,
                  item.content.footer || null,
                  item.content.name || null,
                  item.content.company || null,
                  (item.images && Object.values(item.images).length > 0 ? map(item.images, (image, index) => {
                    return image && typeof image === 'object' ? `<img src="${image.medium}" alt="${item.content[`${index}_alt`] || ''}" />` : null
                  }) : null)
                ])
              }) : this.filterInput([
                item.content.header && item.content.header !== 'New Block' ? `<h2>${item.content.header}</h2>` : null,
                item.content.content && item.content.content !== 'Put your main content here' ? this.md.render(item.content.content) || null : null
              ])),
              (template && template.value.template.layout.items && template.value.template.layout.items.length > 0 ? template.value.template.layout.items.map((item) => {
                return this.filterInput([
                  item.content.header && item.content.header !== 'New Block' ? `<h2>${item.content.header}</h2>` : null,
                  item.content.content && item.content.content !== 'Put your main content here' ? this.md.render(item.content.content) || null : null,
                  item.content.footer || null,
                  item.content.name || null,
                  item.content.company || null,
                  (item.images && Object.values(item.images).length > 0 ? map(item.images, (image, index) => {
                    return image && typeof image === 'object' ? `<img src="${image.medium}" alt="${item.content[`${index}_alt`] || ''}" />` : null
                  }) : null)
                ])
              }) : null),
              (item.images && Object.values(item.images).length > 0 ? map(item.images, (image, index) => {
                return image && typeof image === 'object' ? `<img src="${image.medium}" alt="${item.content[`${index}_alt`] || ''}" />` : null
              }) : null)
            ]
            return this.filterInput(items)
          }) : []
        )
      ]
      return this.filterInput(input)
    }
  },

  methods: {
    filterInput (array) {
      return filter(array, (i) => { return !isEmpty(i) }).join('')
    }
  }
}
</script>
