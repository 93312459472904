<template>
  <div class="px-gap-2xl">
    <TitleSummaryCard
      class="mb-4"
      :edit.sync="edit"
      @edit="startEdit"
      @save="saveModel"
      @discard="discardEdit"
    >
    </TitleSummaryCard>
      <div>
        <h3>Pricing Percentages</h3>
        <div>
          <div class="flex flex-col lg:flex-row w-full gap-gap-lg">
            <vue-data
              v-model="model.band1"
              :value="model.band1"
              :edit="edit"
              class="w-full xl:w-1/2 my-10"
              label="Band 1 Trade"
            />
            <vue-data
              v-model="model.band2"
              :value="model.band2"
              :edit="edit"
              class="w-full xl:w-1/2 my-10"
              label="Band 2 - Trade"
            />
          </div>
          <div class="flex flex-col lg:flex-row w-full gap-gap-lg">
            <vue-data
              v-model="model.band3"
              :value="model.band3"
              :edit="edit"
              class="w-full xl:w-1/2 my-10"
              label="Band 3 - Trade"
            />
            <vue-data
              v-model="model.band4"
              :value="model.band4"
              :edit="edit"
              class="w-full xl:w-1/2 my-10"
              label="Band 4 - Non Trade"
            />
          </div>
        </div>
      </div>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash'
import TitleSummaryCard from '@/components/models/TitleSummaryCard'

export default {
  name: 'PricingIndex',

  components: {
    TitleSummaryCard
  },

  data () {
    return {
      edit: false,
      model: {},
      modelOld: {},
      route: {
        apiEndpoint: 'pricingBands'
      }
    }
  },
  mounted () {
    this.modelSetup()
  },

  methods: {
    async modelSetup () {
      try {
        const data = await this.$api.get('pricingBands')
        const newObj = Object.fromEntries(
          Object.entries(data).map(([k, v]) => [`band${k}`, v])
        )
        this.model = newObj
      } catch (error) {
        this.$store.commit('error/addError', error)
      }
    },

    saveModel () {
      this.$api.put(this.route.apiEndpoint, this.model)
        .then((res) => {
          this.edit = false
        })
        .catch((err) => {
          console.log(err)
          this.$store.commit('error/addError', err)
        })
    },

    startEdit () {
      this.modelOld = cloneDeep(this.model)
    },

    discardEdit () {
      this.model = this.modelOld
      this.modelOld = false
      this.edit = false
    }
  }
}
</script>

<style>

</style>
