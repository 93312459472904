import UsesTokens from '../../../mixins/UsesTokens'
export default {

  mixins: [UsesTokens],

  mounted () {
    this.setupImages()
  },

  methods: {
    syncImage (data, collection) {
      this.$store.commit('loading/startUploading')
      const formData = new FormData()
      formData.append('file', data)
      formData.append('model', this.$route.params.media ? this.$route.params.media.model || 'Metadata' : 'Metadata')
      formData.append('collection', collection)
      this.getToken().then(() => {
        this.$api.post(`media/upload/${this.$route.params.media ? this.$route.params.media.endpoint || 'metadata' : 'metadata'}/${this.$route.params.model}`, formData, {
          'Content-Type': 'multipart/form-data'
        })
          .then((res) => {
            this.$set(this.item.images, collection, res.data)
            this.$emit('update:item', this.item)
            this.queueImageReload()
          })
          .catch((err) => {
            this.$store.commit('error/addError', err)
          })
          .finally(() => {
            this.$store.commit('loading/stopUploading')
          })
      })
    },

    removeImage (collection) {
      if (this.item.images[collection] && this.item.images[collection].id) {
        this.getToken().then(() => {
          this.$api.delete(`media/${this.item.images[collection].id}`)
            .then(() => {
              this.$set(this.item.images, collection, null)
              this.$emit('update:item', this.item)
            })
            .catch((err) => {
              this.$store.commit('error/addError', err)
            })
        })
      }
    },

    setupImages () {
      if (!this.item.images) {
        this.$set(this.item, 'images', {})
      }
    },

    queueImageReload (delay = 5000) {
      console.info('Delaying image upload refresher')
      setTimeout(() => {
        this.imageReload()
      }, delay)
    },

    imageReload () {
      console.info('Image upload refresh start')
      this.$store.commit('loading/refreshCache')
    }
  }
}
