<template>
  <div id="app">
    <vue-navigation v-if="$route.name !== 'login'" />
    <div class="pt-16 px-4">
      <router-view />
    </div>
    <vue-loading />
    <vue-uploading />
    <vue-errors />
  </div>
</template>
<script>
export default {
  mounted () {
    this.$store.dispatch('editor/updateTemplates')
    this.$store.dispatch('editor/updateCategories')
  }
}
</script>
