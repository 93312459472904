<template>
  <NewModelLayout>
    <template #title>
      <h1 class="text-2xl">
        Add a New Product
      </h1>
    </template>
    <template #form>
      <vue-form
        :form="form"
        :errors.sync="error.errors"
        ref="form"
        class="bg-gray-100 shadow rounded-sm"
      >
        <div class="bg-white px-6 py-4">
          <div class="flex flex-wrap w-row-offset-sm -mx-2">
            <div class="w-full lg:w-1/2 px-2 mb-4">
              <vue-input-group
                v-model="form.name"
                :errors="error.errors.name"
                name="name"
                prop="name"
                label="Name"
                description="The name of the product."
                required
              />
            </div>
            <div class="w-full lg:w-1/2 px-2 mb-4">
              <vue-input-group
                v-model="form.slug"
                :errors="error.errors.slug"
                name="slug"
                prop="slug"
                label="Slug"
                description="The product slug"
                required
              />
            </div>
            </div>
            <div class="flex flex-wrap w-row-offset-sm -mx-2">
              <div class="w-full lg:w-1/2 px-2 mb-4">
                <vue-input-group
                  v-model="form.discounted_price"
                  :errors="error.errors.discounted_price"
                  type="number"
                  name="discounted_price"
                  prop="discounted_price"
                  label="Discounted Price"
                  description="The discounted price of this product"
                />
              </div>
              <div class="w-full lg:w-1/2 px-2 mb-4">
                <vue-input-group
                  v-model="form.tax_rate"
                  :errors="error.errors.tax_rate"
                  type="number"
                  name="tax_rate"
                  prop="tax_rate"
                  label="Tax Rate"
                  step="0.1"
                  description="Tax rate to be applied"
                  required
                />
              </div>
            </div>
            <div class="w-full px-2 mb-4">
            <label for="product-description" class="font-sm font-medium">Product Description</label>
            <vue-textarea
             v-model="form.description"
             :errors="error.errors.description"
             name="description"
             prop="description"
             id="product-description"
            />
            </div>
            <div class="flex flex-wrap w-row-offset-sm -mx-2">
              <div class="w-full lg:w-1/2 px-2 mb-4 flex-1">
                <vue-checkbox
                  v-model="form.tax_included_in_price"
                  :errors="error.errors.tax_included_in_price"
                  name="tax_included_in_price"
                  prop="tax_included_in_price"
                  label="Tax included in price"
                  description="Check to determine whether tax should be included in the prouct price"
                />
              </div>
              <div class="w-full lg:w-1/2 px-2 mb-4 flex-1">
                <vue-checkbox
                  v-model="form.display_with_tax"
                  :errors="error.errors.display_with_tax"
                  name="display_with_tax"
                  prop="display_with_tax"
                  label="Display with tax"
                  description="Check to display the product with the price"
                />
              </div>
            </div>
        <div class="flex justify-between items-center w-full px-4 py-4">
          <vue-button
            text
            error
            @click="resetForm"
          >
            Reset
          </vue-button>
          <vue-button
            :icon="['fad', 'save']"
            type="submit"
            success
            @click="submitForm"
          >
            Add Product
          </vue-button>
        </div>
        </div>
      </vue-form>
    </template>
  </NewModelLayout>
</template>
<script>
import { cloneDeep } from 'lodash'
import NewModelLayout from '@/components/models/NewModelLayout'

export default {
  name: 'ProductsNew',

  components: {
    NewModelLayout
  },

  data () {
    return {
      loading: true,
      products: [],
      form: {},
      defaults: {
        name: '',
        slug: '',
        sku: '',
        price: '',
        discounted_price: 0,
        tax_rate: '',
        tax_included_in_price: false,
        display_with_tax: false,
        is_part: false,
        images: '',
        data: {
          images: {},
          layout: {
            cols: ['1fr'],
            rows: [],
            autoCols: '1fr',
            autoRows: '1fr'
          },
          seo: {
            keyphrase: '',
            title: '',
            description: ''
          }
        }
      },
      error: {
        errors: {}
      }
    }
  },

  watch: {
    'form.name': function (val) {
      this.form.slug = val.toLowerCase().replace(/-+/g, ' ').replace(/ +/g, '-').replace(/[^\w-]+/g, '')
    }
  },

  computed: {
    partProductParentOptions () {
      return this.products
    }
  },

  mounted () {
    this.initForm()
    this.$store.dispatch('products/get_products')
    this.products = this.$store.getters['products/getProducts']
  },

  methods: {
    initForm () {
      this.form = cloneDeep(this.defaults)
    },

    resetForm () {
      this.initForm()
    },

    submitForm () {
      this.$refs.form.validate()
      console.log(this.form)
      this.$api.post('products', this.form)
        .then((res) => {
          console.log(res)
          this.$router.push({
            name: 'products.single',
            params: {
              model: res.slug
            }
          })
        })
        .catch((data) => {
          this.$store.commit('error/addError', data)
        })
    }
  }
}
</script>
