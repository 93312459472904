import Vue from 'vue'
import vSelect from 'vue-select'
import VueSweetalert2 from 'vue-sweetalert2'

import 'vue-select/dist/vue-select.css'
import 'sweetalert2/dist/sweetalert2.min.css'

import VueCryptojs from 'vue-cryptojs'

const prefix = 'vue'

Vue.component(
  `${prefix}-loading`,
  require('@/components/VueErrorsCover.vue').default
)

Vue.component(
  `${prefix}-errors`,
  require('@/components/VueLoadingCover.vue').default
)

Vue.component(
  `${prefix}-uploading`,
  require('@/components/VueUploadingCover.vue').default
)

Vue.component(
  `${prefix}-link`,
  require('@/components/VueLink.vue').default
)

Vue.component(
  `${prefix}-input`,
  require('@/components/forms/VueInput.vue').default
)

Vue.component(
  `${prefix}-input-group`,
  require('@/components/forms/VueInputGroup.vue').default
)

Vue.component(
  `${prefix}-data`,
  require('@/components/forms/VueData.vue').default
)

Vue.component(
  `${prefix}-file`,
  require('@/components/forms/VueFile.vue').default
)

Vue.component(
  `${prefix}-textarea`,
  require('@/components/forms/VueTextArea.vue').default
)

Vue.component(
  `${prefix}-markdown`,
  require('@/components/VueMarkdown.vue').default
)

Vue.component(
  `${prefix}-editor`,
  require('@/components/forms/VueEditor.vue').default
)

Vue.component(
  `${prefix}-range`,
  require('@/components/forms/VueRange.vue').default
)

Vue.component(
  `${prefix}-button`,
  require('@/components/VueButton.vue').default
)

Vue.component(
  `${prefix}-checkbox`,
  require('@/components/forms/VueCheckbox.vue').default
)

Vue.component(
  `${prefix}-checkbox-group`,
  require('@/components/forms/VueCheckboxGroup.vue').default
)

Vue.component(
  `${prefix}-icon-picker`,
  require('@/components/forms/VueIconPicker.vue').default
)

Vue.component(
  `${prefix}-alert`,
  require('@/components/VueAlert.vue').default
)

Vue.component(
  `${prefix}-dialog`,
  require('@/components/VueDialog.vue').default
)

Vue.component(
  `${prefix}-form`,
  require('@/components/forms/VueForm.vue').default
)

Vue.component(
  `${prefix}-data`,
  require('@/components/forms/VueData').default
)

Vue.component(
  `${prefix}-data-table`,
  require('@/components/VueDataTable.vue').default
)

Vue.component(
  `${prefix}-markdown`,
  require('@/components/VueMarkdown').default
)

Vue.component(
  `${prefix}-select`,
  require('@/components/forms/VueSelect.vue').default
)

Vue.component(
  `${prefix}-card`,
  require('@/components/VueCard.vue').default
)

Vue.component(
  `${prefix}-navigation`,
  require('@/components/VueNavigation.vue').default
)

Vue.component(
  `${prefix}-navigation-item`,
  require('@/components/VueNavigationItem.vue').default
)

Vue.component('v-select', vSelect)

Vue.use(VueSweetalert2)

Vue.use(VueCryptojs)
