<template>
  <div class="group flex">
    <input
      ref="input"
      type="file"
      v-bind="$attrs"
      v-on="$listeners"
      class="py-2"
    >
  </div>
</template>
<script type="text/javascript">
export default {
  name: 'VueFile',

  props: {
    prepend: {
      type: [Boolean, String],
      required: false,
      default: () => { return null }
    },

    append: {
      type: [Boolean, String],
      required: false,
      default: () => { return null }
    }
  },

  mounted () {
    if ('autofocus' in this.$attrs) {
      this.focus()
    }
  },

  methods: {
    focus () {
      this.$refs.input.focus()
    }
  }
}
</script>
<style scoped>
  input:invalid {
    @apply border-red-600;
  }
</style>
