<template>
  <div class="flex flex-col space-y-4">
    <div v-if="item" class="space-y-2">
      <div class="bg-white rounded px-2 py-2">
        <div class="flex flex-col space-y-2">
          <div v-if="elements" class="px-1">
            <label for="itemElement" class="text-sm font-medium">Block</label>
            <vue-select
              v-model="item.element"
              :options="elements"
              id="itemElement"
              labelKey="name"
              valueKey="key"
            />
          </div>
          <template v-if="config">
            <component
              :is="config"
              :item="item"
            />
          </template>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'GridInnerConfig',

  props: {
    item: {
      type: [Boolean, Object],
      required: false,
      default: () => { return false }
    }
  },

  watch: {
    item: {
      deep: true,
      handler () {
        this.item.component = this.element.component
      }
    }
  },

  computed: {
    element () {
      return this.$store.getters['editor/getElementByKey'](this.item.element)
    },

    elements () {
      return this.$store.getters['editor/getElements']
    },

    config () {
      return this.element ? this.element.config : false
    }
  }
}
</script>
