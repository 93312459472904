var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"flex justify-end gap-4 mb-4"},[_c('vue-button',{attrs:{"icon":['fas', 'file-import'],"success":"","outline":""},on:{"click":function($event){return _vm.$router.push({ name: _vm.routes.import })}}},[_vm._v(" Import CSV ")]),_c('vue-button',{attrs:{"icon":['fas', 'file-export'],"loading":this.loading,"outline":""},on:{"click":_vm.exportCsv}},[_vm._v(" Export CSV ")])],1),_c('vue-data-table',{attrs:{"cols":[
      {
        header: 'Product Name',
        value: 'name',
      },
      {
        header: 'Slug',
        value: 'slug'
      },
      {
        header: 'Added At',
        value: 'created_at',
        sort: true
      },
      {
        header: 'Last Modified',
        value: 'updated_at',
        sort: true
      },
      {
        header: 'Actions',
        value: 'actions'
      }
    ],"path":"products"},scopedSlots:_vm._u([{key:"header-left",fn:function(){return [_c('vue-button',{attrs:{"icon":['fas', 'plus-circle'],"primary":""},on:{"click":function($event){return _vm.$router.push({ name: _vm.routes.new })}}},[_vm._v(" Add "+_vm._s(_vm.model.singular)+" ")])]},proxy:true},{key:"item.name",fn:function(row){return [_vm._v(" "+_vm._s(row.name)+" ")]}},{key:"item.slug",fn:function(row){return [_vm._v(" "+_vm._s(row.slug)+" ")]}},{key:"item.created_at",fn:function(row){return [_vm._v(" "+_vm._s(_vm.$momentDateTimeHuman(row.created_at))+" ")]}},{key:"item.updated_at",fn:function(row){return [_vm._v(" "+_vm._s(_vm.$momentDateTimeHuman(row.updated_at))+" ")]}},{key:"item.actions",fn:function(row){return [_c('vue-link',{staticClass:"inline-flex cursor-pointer",attrs:{"to":{
          name: _vm.routes.single,
          params: {
            model: row.slug
          }
        },"icon":['fad', 'edit'],"aria-label":("Edit " + (_vm.model.singular) + ": " + (row.name)),"dark":"","text":"","sm":""}}),_c('vue-button',{staticClass:"inline-flex",attrs:{"disabled":_vm.loading,"icon":['fad', 'copy'],"aria-label":("Duplicate " + (row.name) + " to a new " + (_vm.model.singular)),"dark":"","text":"","sm":""},on:{"click":function($event){return _vm.duplicateModel(row)}}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }