<template>
  <RootBlock :item="item" :padding="false">
    <template v-if="items && items.length > 0">
      <div :class="`grid grid-cols-${item.content.columnCount || '3'} overflow-hidden`">
        <div
          v-for="(item, index) in items"
          :key="index"
        >
          <GridItem
            :item="item"
            :index="index"
          />
        </div>
      </div>
    </template>
    <div v-else class="flex items-center justify-center w-full h-full">
      No columns defined. Click 'Edit Inner Blocks' on the right to add some.
    </div>
  </RootBlock>
</template>
<script>
import RootBlock from '../RootBlock'
import GridItem from '../../GridItem'

export default {
  friendlyName: 'Columns Block',

  components: {
    RootBlock,
    GridItem
  },

  props: {
    item: {
      type: Object,
      required: true
    },

    editMode: {
      type: Boolean,
      required: false,
      default: () => { return false }
    }
  },

  computed: {
    content () {
      return this.item.content || false
    },

    layout () {
      return this.item.layout || false
    },

    items () {
      return this.content.items || []
    }
  }
}
</script>
