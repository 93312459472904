var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('RootBlock',{attrs:{"item":_vm.item,"padding":false}},[_c('div',{staticClass:"grid grid-cols-1 auto-rows-auto w-full h-full"},[(_vm.hasImage)?_c('div',{staticClass:"row-start-1 h-0 aspect-w-3 aspect-h-2 xl:aspect-w-1 xl:aspect-h-1"},[_c('img',{staticClass:"w-full h-full object-cover object-top",attrs:{"src":_vm.item.images[("bio_photo_" + (_vm.item.key))].medium,"alt":_vm.imageAlt,"title":_vm.imageAlt}})]):_vm._e(),_c('div',{class:{
        'flex flex-col space-y-2 px-gap-xs py-gap-xs': true,
        'xl:row-start-2 justify-between': _vm.content && (_vm.content.type !== 'full' || _vm.hasImage),
        'xl:row-start-1 xl:row-span-2': _vm.content && _vm.content.type !== 'full' && !_vm.hasImage,
        'justify-start': _vm.content && _vm.content.type === 'full'
      }},[_c('div',[(_vm.content && _vm.content.name)?_c(_vm.layout ? _vm.layout.header || 'h3' : 'h3',{tag:"component",class:((_vm.content && _vm.content.type ? (_vm.content.type === 'full' ? 'text-h1' : 'truncate text-h2') : 'truncate text-h2') + " " + (_vm.colors && _vm.colors.header ? _vm.colors.header : _vm.colors.text || 'text-white'))},[_vm._v(" "+_vm._s(_vm.content.name)+" ")]):_vm._e(),(_vm.content && _vm.content.position)?_c('p',{class:("block " + (_vm.content.type !== 'full' ? 'truncate' : '') + " " + (_vm.content && _vm.content.type && _vm.content.type === 'full' ? 'text-h2' : '') + " " + (_vm.colors && _vm.colors.subtitle ? _vm.colors.subtitle : _vm.colors.text || 'text-white'))},[_vm._v(" "+_vm._s(_vm.content.position)+" ")]):_vm._e()],1),_c('div',{staticClass:"flex-grow"},[(_vm.content && _vm.content.bio)?_c('div',{class:{
            'markdown-render': true,
            'truncate': _vm.content.type !== 'full'
          },domProps:{"innerHTML":_vm._s(_vm.content.bio)}}):_vm._e()]),(_vm.content && _vm.content.contact_email)?_c('a',{class:("text-h2 " + (_vm.colors && _vm.colors.subtitle ? _vm.colors.subtitle : _vm.colors.text || 'text-white') + " truncate"),attrs:{"href":("mailto:" + (_vm.content.contact_email))}},[_vm._v(" "+_vm._s(_vm.content.contact_email)+" ")]):_vm._e()])])])}
var staticRenderFns = []

export { render, staticRenderFns }