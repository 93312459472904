const state = {
  loading: false,
  uploading: false,
  cacheKey: 'static'
}

const mutations = {
  startLoading (state) {
    state.loading = true
  },

  stopLoading (state) {
    state.loading = false
  },

  startUploading (state) {
    state.uploading = true
  },

  stopUploading (state) {
    state.uploading = false
  },

  refreshCache (state) {
    state.cacheKey = Date.now()
  }
}

export default {
  namespaced: true,
  state,
  mutations
}
