import { map } from 'lodash'

const aspectRatios = [
  { label: '1:1', value: ['aspect-w-1', 'aspect-h-1'] },
  { label: '2:1', value: ['aspect-w-2', 'aspect-h-1'] },
  { label: '3:1', value: ['aspect-w-3', 'aspect-h-1'] },
  { label: '4:1', value: ['aspect-w-4', 'aspect-h-1'] },
  { label: '3:2', value: ['aspect-w-3', 'aspect-h-2'] },
  { label: '4:3', value: ['aspect-w-4', 'aspect-h-3'] }
]

const padding = [
  { label: 'Extra Small', value: 'gap-xs' },
  { label: 'Small', value: 'gap-sm' },
  { label: 'Normal', value: 'gap' },
  { label: 'Large', value: 'gap-lg' },
  { label: 'XL', value: 'gap-xl' },
  { label: '2XL', value: 'gap-2xl' },
  { label: '3XL', value: 'gap-3xl' }
]

const getMappedAspectRatios = (prefix = null) => {
  return map(aspectRatios, (ratio) => {
    return {
      ...ratio,
      ...{ value: `${prefix ? `${prefix}:` : ''}${ratio.value[0]} ${prefix ? `${prefix}:` : ''}${ratio.value[1]}` }
    }
  })
}

const getMappedPadding = (side) => {
  return map(padding, (spacing) => {
    return {
      ...spacing,
      ...{ value: `${side}-${spacing.value}` }
    }
  })
}

export default {
  data () {
    return {
      layout: {
        header: {
          label: 'Header Type',
          type: 'select',
          options: [
            { label: 'H1', value: 'h1' },
            { label: 'H2', value: 'h2' },
            { label: 'H3', value: 'h3' }
          ],
          allow_none: false
        },
        width: {
          label: 'Width',
          type: 'select',
          options: [
            { label: 'Full', value: 'col-span-full' },
            { label: '1/2', value: 'col-span-1/2' },
            { label: '1/3', value: 'col-span-1/3' },
            { label: '1/4', value: 'col-span-1/4' },
            { label: '2/3', value: 'col-span-2/3' },
            { label: '3/4', value: 'col-span-3/4' }
          ]
        },
        aspect: {
          label: 'Aspect Ratio',
          type: 'select',
          sizes: {
            base: {
              options: getMappedAspectRatios(),
              default: () => { return 'aspect-w-1 aspect-h-1' }
            },
            xl: {
              options: getMappedAspectRatios('xl'),
              default: () => { return 'xl:aspect-w-1 xl:aspect-h-1' }
            }
          }
        },
        padding: {
          label: 'Box Padding',
          type: 'select',
          sizes: {
            x: {
              options: getMappedPadding('px'),
              default: () => { return 'px-gap-2xl' }
            },
            y: {
              options: getMappedPadding('py'),
              default: () => { return 'py-gap-2xl' }
            }
          }
        }
      }
    }
  }
}
