<template>
  <div class="group flex">
    <textarea
      ref="input"
      v-model="localValue"
      v-bind="$attrs"
      :class="{
        'block w-full text-sm bg-white text-shade-dark border border-shade group-focus:border-primary group-hover:border-shade-dark px-2 py-3 outline-none transition-all': true,
        'rounded': !square,
        'rounded-none': square
      }"
    />
  </div>
</template>
<script type="text/javascript">
import HasVModel from '@/mixins/HasVModel'

export default {
  name: 'VueTextArea',

  mixins: [HasVModel],

  props: {
    square: {
      type: Boolean,
      required: false,
      default: () => { return false }
    }
  },

  mounted () {
    if ('autofocus' in this.$attrs) {
      this.focus()
    }
  },

  methods: {
    focus () {
      this.$refs.input.focus()
    }
  }
}
</script>
<style scoped>
  input:invalid {
    @apply border-red-600;
  }
</style>
