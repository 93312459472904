<template>
  <RootBlock :item="item" class="overflow-hidden">
    <component :is="layout ? layout.header || 'h2' : 'h2'" v-if="content && content.header" :class="colors && colors.header ? colors.header : colors.text || ''">
      {{ content.header }}
    </component>
    <div class="markdown-render" v-html="content.content" />
    <span v-if="content && content.footer" :class="`block text-h2 ${colors && colors.footer ? colors.footer : colors.text || ''}`">
      {{ content.footer }}
    </span>
  </RootBlock>
</template>
<script>
import RootBlock from '../RootBlock'

export default {
  friendlyName: 'Text Block',

  components: {
    RootBlock
  },

  props: {
    item: {
      type: Object,
      required: true
    }
  },

  computed: {
    content () {
      return this.item.content || false
    },

    layout () {
      return this.item.layout || false
    },

    colors () {
      return this.item.colors || false
    }
  }
}
</script>
