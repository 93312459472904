<template>
  <div class="flex flex-col">
    <div class="w-full bg-gray-100 text-left px-2 py-2">
      <label>Content</label>
    </div>
    <div class="flex flex-col border border-gray-100 border-t-0 space-y-2 py-2">
      <div class="w-full px-2">
        <vue-input-group
          v-model="item.content.header"
          prop="header"
          name="header"
          label="Title"
        />
      </div>
      <div class="w-full px-2">
        <vue-input-group
          v-model="item.content.content"
          prop="content"
          name="content"
          label="Content"
          type="editor"
        />
      </div>
      <div class="w-full px-2">
        <vue-input-group
          v-model="item.content.columns"
          prop="columns"
          name="columns"
          label="Content Columns"
          type="number"
          min="1"
          max="3"
        />
      </div>
      <div class="w-full px-2">
        <vue-input-group
          v-model="item.content.footer"
          prop="footer"
          name="footer"
          label="Footer"
        />
      </div>
      <div class="w-full px-2">
        <vue-data
          v-model="item.images[`category_icon_${item.key}`]"
          :value="item.images[`category_icon_${item.key}`]"
          :edit="true"
          class="mb-2"
          type="file"
          label="Category Icon"
          accept="image/svg+xml"
          @update="syncImage($event, `category_icon_${item.key}`)"
          @discard="removeImage(`category_icon_${item.key}`)"
        />
      </div>
    </div>
    <GridItemLayoutConfig
      :item.sync="item"
    />
    <GridItemColorsConfig
      :item.sync="item"
      :extra-options="extraColorFields"
      :hide="['icon']"
    />
  </div>
</template>
<script>
import GridItemLayoutConfig from '../../GridItemLayoutConfig'
import GridItemColorsConfig from '../../GridItemColorsConfig'
import HasImageUpload from '../../../mixins/HasImageUpload'

export default {
  components: {
    GridItemLayoutConfig,
    GridItemColorsConfig
  },

  mixins: [
    HasImageUpload
  ],

  props: {
    item: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      extraColorFields: {
        header: {
          label: 'Header',
          inherit: 'text',
          allowNull: true
        },
        footer: {
          label: 'Footer',
          inherit: 'text',
          allowNull: true
        },
        svg: {
          label: 'Image Colour',
          inherit: 'background',
          allowNull: false
        }
      }
    }
  }
}
</script>
