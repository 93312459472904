<template>
  <SingleModelLayout
    :model.sync="model"
    :route-back="{
      name: route.index
    }"
    @refresh-model="getModel"
    @delete-model="deleteModel"
  >
    <template #title>
      <TitleSummaryCard
        class="mb-4"
        :edit.sync="edit"
        @edit="startEdit"
        @save="saveModel"
        @discard="discardEdit"
      >
        <template #title>
          <vue-data
            v-model="model.name"
            :value="model.name"
            :edit="edit"
            :border="false"
          />
        </template>
        <template #subtitle class="opacity-50">
          {{ model.id }}
        </template>
      </TitleSummaryCard>
    </template>
    <template #left>
      <vue-data
        v-model="model.slug"
        :value="model.slug"
        :edit="edit"
        class="mb-2"
        label="Slug"
      />
      <!-- <vue-data
        v-model="model.key"
        :value="model.key"
        :edit="edit"
        class="mb-2"
        label="Metadata Key"
      /> -->
      <!-- <seo-section
        :model="model"
        :edit="edit"
      /> -->
      <div class="mt-8">
        <vue-data
        v-model="model.sets"
        :value="productCategory"
        :edit="edit"
        :options="productCategories"
        label="Product Category"
        label-key="label"
        value-key="value"
        type="select"
        class="mb-2"
        />
      </div>
    </template>
    <template #main>
        <vue-data
            v-model="model.description"
            :value="model.description"
            :edit="edit"
            type="textarea"
            class="mb-2"
            label="Product Description"
        />
        <div class="flex justify-evenly gap-4 my-4 items-end">
         <vue-data
            v-model="model.display_with_tax"
            :value="model.display_with_tax"
            :edit="edit"
            type="checkbox"
            class="mb-2 w-1/2"
            label="Display with tax"
        />
         <vue-data
            v-model.number="model.tax_rate"
            :value="model.tax_rate"
            :edit="edit"
            type="number"
            class="mb-2 w-1/2"
            label="Tax rate"
        />
        </div>
        <div class="w-full my-2">
          <label class="font-medium text-sm mb-1">Product Variants</label>
          <table id="variants" class="w-full">
            <thead>
              <th>#</th>
              <th>Part No</th>
              <th>Size</th>
              <th>Cost Price</th>
              <th>Base Price</th>
              <th>Stock</th>
              <th>Weight</th>
              <th>Length</th>
              <th v-show="edit"></th>
            </thead>
            <tbody>
              <tr v-for="(variant, index) in variants" :key="variant.id" class="text-center">
                <td>{{ index + 1 }}</td>
                <td>
                  <vue-data
                    v-model="variant.part_no"
                    :value="variant.part_no"
                    :edit="edit"/>
                  </td>
                <td>
                  <vue-data
                    v-model="variant.size"
                    :value="variant.size"
                    :edit="edit"/>
                  </td>
                <td>
                  <vue-data
                    v-model="variant.cost_price"
                    :value="variant.cost_price"
                    :edit="edit"/>
                </td>
                <td>
                  <vue-data
                    v-model="variant.base_price"
                    :value="variant.base_price"
                    :edit="edit"/>
                </td>
                <td>
                  <vue-data
                    v-model="variant.stock"
                    :value="variant.stock"
                    :edit="edit"/>
                </td>
                <td>
                  <vue-data
                    v-model="variant.weight"
                    :value="variant.weight"
                    :edit="edit"/>
                </td>
                <td>
                  <vue-data
                    v-model="variant.length"
                    :value="variant.length"
                    :edit="edit"/>
                </td>
                <td v-show="edit">
                  <vue-button
                    :icon="['fad', 'trash']"
                    @click="deleteVariant(variant, index)"
                    type="submit"
                    xs
                    error
                    title="Delete variant">
                  </vue-button>
                </td>
              </tr>
              <tr v-show="edit">
                <td colspan="4">
                <vue-button
                  :icon="['fas', 'plus-circle']"
                  type="submit"
                  success
                  sm
                  class="my-2"
                  @click="addRow"
                >
                  Add New Row
                </vue-button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <vue-data
            v-model="model.tax_included_in_price"
            :value="model.tax_included_in_price"
            :edit="edit"
            type="checkbox"
            class="mb-2"
            label="Tax included in price"
        />
        <label class="font-medium text-sm mb-1">Associated Products</label>
        <v-select
          multiple
          v-model="search"
          @search="getData"
          label="name"
          :options="data"
          @input="linkProduct">
          <template slot="no-options">
            type to search products to link
          </template>
          </v-select>

        <div class="flex gap-2 mt-4 flex-wrap">
          <button
            class="bg-primary  rounded-lg m-3 p-2 text-xs"
            v-for="linked_product in linkedProducts"
            :key="linked_product.id">
            <span class="text-white">{{ linked_product.name}}</span>
            <span @click="dissociate(linked_product)" class="text-gray-200 px-2 hover:text-red-600">x</span>
          </button>
        </div>
    </template>
    <template #right>
      <vue-data
        :value="model.images ? model.images.product_featured_image : null"
        :edit="edit"
        :model="model"
        class="mb-4"
        type="file"
        accept="image/png, image/jpeg, image/gif"
        label="Product Images"
        @update="syncImage($event, 'product_images')"
      />
      <div class="grid grid-cols-2 gap-2">
        <div v-for="image in productImages" :key="image.uuid">
          <template v-if="edit">
            <div class="relative">
              <img :src="image.original_url" :alt="image.name">
              <vue-button
                :icon="['fad', 'trash']"
                :loading="image.loadingDelete"
                @click="deleteImage(image)"
                type="submit"
                xs
                error
                class="absolute top-0 left-0"
                title="Delete image"
              ></vue-button>
            </div>
          </template>
          <template v-else>
            <img :src="image.original_url" :alt="image.name">
          </template>
        </div>
      </div>
    </template>
  </SingleModelLayout>
</template>
<script type="text/javascript">
import { cloneDeep, isEmpty } from 'lodash'
import debounce from 'lodash.debounce'
import SingleModelLayout from '@/components/models/SingleModelLayout'
import TitleSummaryCard from '@/components/models/TitleSummaryCard'
import UsesTokens from '@/mixins/UsesTokens'

export default {
  name: 'ProductsSingle',

  components: {
    SingleModelLayout,
    TitleSummaryCard
  },

  mixins: [
    UsesTokens
  ],

  data () {
    return {
      loading: false,
      edit: false,
      model: false,
      modelOld: false,
      hasParts: false,
      files: [],
      extra_data: [],
      search: null,
      data: [],
      modelCategory: '',
      categories: [],
      associatedProducts: [],
      associatedProductIds: [],
      alreadyLinkedProducts: [],
      route: {
        apiEndpoint: 'products',
        mediaEndpoint: 'product',
        laravelModel: 'Product',
        index: 'products.index'
      }
    }
  },

  watch: {
    search: {
      handler () {
        this.getData()
      }
    },
    productCategory: function (value) {
      console.log(value)
    }
  },

  computed: {
    value () {
      return this.model.value ?? {}
    },

    content () {
      return this.value && this.value.content ? this.value.content : false
    },

    variants () {
      return this.model.variants
    },

    // get products to associate
    toAssociate () {
      // get all products
      const allProducts = cloneDeep(this.$store.getters['product/getProducts'].data)
      console.log('allProducts', allProducts)
      // filter the product being viewed as cannot attach item to itself
      const filteredSameProduct = allProducts.filter(product => product.slug !== this.$route.params.model)
      // get and remove the already linked products as they are already attached
      // const alreadyLinkedProducts = JSON.parse(this.$store.getters['products/getSingleProduct'].linked_products)
      const alreadyLinkedProducts = this.$store.getters['products/getSingleProduct']?.linked_products
      console.log('alreadyLinked', typeof alreadyLinkedProducts)
      let toLink
      if (alreadyLinkedProducts !== 'undefined') {
        toLink = filteredSameProduct.filter((product) => !this.alreadyLinkedProducts.find(removed => removed.slug === product.slug))
      } else {
        toLink = filteredSameProduct
      }
      return toLink
    },

    // get the linked products
    linkedProducts () {
      // const associatedProducts = JSON.parse(this.$store.getters['products/getSingleProduct'].linked_products)
      // const associatedProducts = this.$store.getters['products/getSingleProduct'].linked_products
      const associatedProducts = this.model.linked_products

      if (!isEmpty(associatedProducts)) {
        // const associatedProducts = this.$store.getters['products/getSingleProduct'].linked_products
        const formattedAsociatedProducts = associatedProducts.map(({ id, name, slug }) => ({ id, name, slug }))
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.associatedProductIds = formattedAsociatedProducts.map(product => product.id)
        return formattedAsociatedProducts
      } else {
        const formattedAsociatedProducts = []
        return formattedAsociatedProducts
      }
    },

    productImages () {
      const images = []
      const imagesObj = this.model.images.product_images

      if (this.isObject(imagesObj)) {
        if (Object.keys(imagesObj).length > 0) {
          Object.keys(imagesObj).forEach(function (key) { images.push(imagesObj[key]) })
        }
      } else if (this.isArray(imagesObj)) {
        return images
      }
      return images
    },

    productCategory () {
      const category = isEmpty(this.model.sets) ? '' : this.model.sets[0].id

      return category
    },
    productCategories () {
      let categories = []
      categories = isEmpty(this.categories) ? [] : this.categories.map((category) => ({ value: category.id, label: category.name }))

      return categories
    }
  },

  mounted () {
    this.getModel()
    this.getCategories()
    this.$route.params.media = {
      endpoint: this.route.mediaEndpoint,
      model: this.route.laravelModel
    }

    // this.$store.dispatch('products/get_products')
    // this.$store.dispatch('products/get_single_product', this.$route.params.model)
  },

  created () {
    this.$store.dispatch('product/get_products')
    this.$store.dispatch('product/get_single_product', this.$route.params.model)
    // this.alreadyLinkedProducts = this.$store.getters['products/getSingleProduct'].linked_products
  },

  methods: {
    getModel () {
      this.loading = true
      this.$api.get(`${this.route.apiEndpoint}/${this.$route.params.model}`, {
        params: {
          withImages: true,
          with: ['related', 'variants', 'sets']
        }
      })
        .then((res) => {
          this.model = res
          this.model.sets = isEmpty(res.sets) ? '' : res.sets[0].id
          this.associatedProductIds = isEmpty(this.model.linked_products) ? [] : this.model.linked_products.map(({ id }) => ({ id }))
        })
        .catch((err) => {
          this.$store.commit('error/addError', err)
        })
        .finally(() => {
          this.loading = false
        })
    },

    deleteModel () {
      this.loading = true
      this.$api.delete(`${this.route.apiEndpoint}/${this.$route.params.model}`)
        .then((res) => {
          this.$router.push({
            name: this.route.index
          })
        })
        .catch((err) => {
          this.$store.commit('error/addError', err)
        })
    },

    saveModel () {
      this.loading = true
      this.getToken().then(() => {
        this.saveFiles().then(() => {
          this.$api.put(`${this.route.apiEndpoint}/${this.$route.params.model}`, this.model)
            .then((res) => {
              this.getModel()
              this.edit = false
            })
            .catch((err) => {
              this.$store.commit('error/addError', err)
            })
            .finally(() => {
              this.loading = false
            })
        })
          .then(() => {
            this.files = []
          })
      })
    },

    syncImage (data, collection) {
      this.files.push({
        collection,
        data
      })
    },

    removeImage () {
      this.files = false
    },

    deleteImage (image) {
      image.loadingDelete = true
      this.getToken().then(() => {
        this.$api.delete(`/media/${image.uuid}`)
          .then((res) => {
            this.getModel()
            image.loadingDelete = false
          })
          .catch((err) => {
            this.$store.commit('error/addError', err)
            this.loadingDelete = false
          })
      })
    },

    async saveFiles () {
      if (this.files) {
        const mediaUrl = `media/upload/${this.route.mediaEndpoint}/${this.model.id}`
        console.log(mediaUrl)
        this.files.forEach((file) => {
          const formData = new FormData()
          formData.append('file', file.data)
          formData.append('collection', file.collection)
          formData.append('model', this.route.laravelModel)
          this.$api.post(mediaUrl, formData, {
            'Content-Type': 'multipart/form-data'
          })
            .catch((err) => {
              this.$store.commit('error/addError', err)
            })
        })
      }
      return this.files
    },

    startEdit () {
      this.modelOld = cloneDeep(this.model)
    },

    discardEdit () {
      this.model = this.modelOld
      this.modelOld = false
      this.edit = false
    },

    linkProduct (product) {
      if (product[0]) {
        console.log(product[0].slug)
        this.loading = true
        this.getToken().then(() => {
          this.$api.post(`product/${this.model.slug}/associate/${product[0].slug}`)
            .then((res) => {
              this.model = res.data
              this.getModel()
              this.loading = false
              this.search = ''
              // this.$store.commit('products/popAttachedProduct', product)
              this.$store.dispatch('products/get_products')
            })
            .catch((err) => {
              this.search = ''
              this.$store.commit('error/addError', err)
            })
        })
      }
    },

    dissociate (product) {
      console.log(product)
      this.loading = true
      this.getToken().then(() => {
        this.$api.post(`product/${this.model.slug}/dissociate/${product.slug}`)
          .then((res) => {
            this.getModel()
            this.loading = false
            this.$store.commit('products/pushAttachedProduct', product)
            console.log(res.data)
          })
          .catch((err) => {
            this.$store.commit('error/addError', err)
          })
      })
    },

    addRow () {
      this.model.variants.push({
        part_no: '',
        size: '',
        cost_price: '',
        base_price: '',
        stock: 0,
        weight: '',
        length: ''
      })
    },

    deleteVariant (variant, index) {
      this.$swal({
        title: 'Do you want to delete this row?',
        text: 'Once you delete, the action is irreversible',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, Delete',
        confirmButtonColor: '#cb2626'
      }).then((result) => {
        if (result.isConfirmed) {
          var idx = this.model.variants.indexOf(variant)
          if (idx > -1) {
            this.model.variants.splice(idx, 1)
          }
        }
      })
    },

    getData: debounce(function (search) {
      this.loading = true
      this.$api.get(this.route.apiEndpoint, {
        params: {
          type: this.filters,
          search: search,
          exclude: [this.model.id, ...this.associatedProductIds]
        }
      })
        .then(data => {
          this.data = data.data
          this.meta = {
            ...this.meta,
            ...{
              total: data.meta.total,
              page: data.meta.current_page,
              limit: data.meta.per_page
            }
          }
        })
        .catch(data => {
          this.search = ''
          this.$store.commit('error/addError', data)
        })
        .finally(() => {
          this.loading = false
        })
    }, 200),

    getCategories () {
      this.loading = true
      this.$api.get('sets', {
        params: {
          type: 'PurpleMountain\\PmmEcommerce\\Models\\Product'
        }
      })
        .then((res) => {
          this.categories = res.data
        })
        .catch((err) => {
          this.$store.commit('error/addError', err)
        })
        .finally(() => {
          this.loading = false
        })
    },

    isArray (a) {
      return (!!a) && (a.constructor === Array)
    },

    isObject (a) {
      return (!!a) && (a.constructor === Object)
    }
  }
}
</script>
<style scoped>
#variants thead >th {
  font-weight: 400;
}
</style>
