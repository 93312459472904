<template>
  <div class="w-full">
    <div
      v-for="(setting, settingKey) in settings"
      :key="settingKey"
      class="w-full"
    >
      <button class="w-full bg-gray-100 text-left px-2 py-2">
        <label>{{ setting.label }}</label>
      </button>
      <div class="border border-gray-100 border-t-0 px-2 py-2">
        <vue-input-group
          v-model="item.colors[settingKey]"
          :prop="settingKey"
          :name="settingKey"
          :type="setting.type"
          :options="setting.options || []"
          :disable-on-null="!setting.allowNull || false"
          labelKey="label"
          valueKey="value"
        />
      </div>
    </div>
    <div v-if="!hideImageField && item.images" class="w-full">
      <button class="w-full bg-gray-100 text-left px-2 py-2">
        <label>Background Image</label>
      </button>
      <div class="border border-gray-100 border-t-0 px-2 py-2">
        <vue-data
          v-model="item.images[`background_image_${item.key}`]"
          :value="item.images[`background_image_${item.key}`]"
          :edit="true"
          class="mb-2"
          type="file"
          accept="image/png, image/jpeg, image/gif"
          @update="syncImage($event, `background_image_${item.key}`)"
          @discard="removeImage(`background_image_${item.key}`)"
        />
        <vue-input-group
          v-model="item.content[`background_image_${item.key}_alt`]"
          :prop="`background_image_${item.key}_alt`"
          :name="`background_image_${item.key}_alt`"
          label="Background Image Alt Text"
          class="mb-2"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { mapValues, omitBy } from 'lodash'
import ColorConfig from '../mixins/ColorConfig.js'
import HasImageUpload from '../mixins/HasImageUpload'

export default {
  mixins: [
    ColorConfig,
    HasImageUpload
  ],

  props: {
    item: {
      type: Object,
      required: true
    },

    hide: {
      type: Array,
      required: false,
      default: () => { return [] }
    },

    extraOptions: {
      type: Object,
      required: false,
      default: () => { return {} }
    }
  },

  computed: {
    settings () {
      let extra = this.extraOptions
      if (Object.values(extra).length > 0) {
        extra = mapValues(extra, (field) => {
          return {
            ...field,
            ...{
              type: this.colors[field.inherit].type,
              options: this.colors[field.inherit].options
            }
          }
        })
      }
      return omitBy({
        ...this.colors,
        ...extra
      }, (value, key) => {
        return this.hide.includes(key)
      })
    },

    hideImageField () {
      return this.hide && this.hide.length > 0 && this.hide.includes('bg_image')
    }
  }
}
</script>
