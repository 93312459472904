<template>
  <div class="flex flex-col">
    <div class="w-full bg-gray-100 text-left px-2 py-2">
      <label>Content</label>
    </div>
    <div class="flex flex-col border border-gray-100 border-t-0 space-y-2 py-2">
      <div class="w-full px-2">
        <vue-input-group
          v-model="item.content.content"
          prop="content"
          name="content"
          label="Quote"
          type="editor"
        />
      </div>
      <div class="w-full px-2">
        <vue-input-group
          v-model="item.content.name"
          prop="name"
          name="name"
          label="Quote Source (Name)"
        />
      </div>
      <div class="w-full px-2">
        <vue-input-group
          v-model="item.content.company"
          prop="company"
          name="company"
          label="Quote Source (Company)"
        />
      </div>
    </div>
    <GridItemLayoutConfig
      :item="item"
    />
    <GridItemColorsConfig
      :item="item"
      :extra-options="extraColorFields"
    />
  </div>
</template>
<script>
import GridItemLayoutConfig from '../../GridItemLayoutConfig'
import GridItemColorsConfig from '../../GridItemColorsConfig'

export default {
  components: {
    GridItemLayoutConfig,
    GridItemColorsConfig
  },

  props: {
    item: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      extraColorFields: {
        icon: {
          label: 'Icon',
          inherit: 'text',
          allowNull: true
        },
        highlight: {
          label: 'Highlight',
          inherit: 'text',
          allowNull: true
        }
      }
    }
  }
}
</script>
