<template>
  <div>
    <form class="flex justify-center h-screen items-center gap-gap-2xl" @submit="submitForm">
      <div class="col-md-6">
          <label class="col-md-4 col-form-label text-md-right">Upload CSV File</label>
          <div class="custom-file">
            <input type="file" class="custom-file-input" id="csvFile"
                ref="file" @change="selectFile()">
            <label class="custom-file-label text-left" for="csvFile">{{ fileName }}</label>
          </div>
      </div>
      <vue-button
        :icon="['fas', 'paper-plane']"
        :loading="this.loading"
        outline
      >
      Submit CSV
      </vue-button>
    </form>
  </div>
</template>

<script>
import UsesTokens from '@/mixins/UsesTokens'

export default {
  name: 'ProductsImport',

  mixins: [
    UsesTokens
  ],

  data () {
    return {
      url: process.env.VUE_APP_API_BASE_URL,
      fileName: '',
      selectedFile: null,
      progress: 0,
      interval: null,
      uploadStatus: {},
      loading: false,
      errors: {},
      routes: {
        index: 'products.index'
      }
    }
  },
  methods: {
    selectFile () {
      this.selectedFile = this.$refs.file.files[0]
      this.fileName = this.selectedFile.name
    },

    submitForm (e) {
      e.preventDefault()

      this.loading = true

      if (this.selectedFile) {
        const csvUrl = 'csv/import'

        const formData = new FormData()
        formData.append('file', this.selectedFile)
        this.$api.post(csvUrl, formData, {
          'Content-Type': 'multipart/form-data; charset=utf-8',
          Accept: 'application/json'
        })
          .then(response => {
            // this.$api.get('csv/importProgress', {
            //   Accept: 'application/json'
            // }).then((response) => {
            //   console.log('importProgress', response)
            // })

            this.$router.push({ name: 'products.index' })
            this.progress = 100
            console.log(response)
            // this.uploadStatus = response.data.data
            // if (response.data.completed !== true) {
            //   // this.$router.push({ name: this.routes.index })
            //   this.getProgress()
            // }
            this.loading = false
          })
          .catch((err) => {
            this.loading = false
            this.$store.commit('error/addError', err)
          })
      }
    },

    getProgress () {
      this.$api.get('csv/importProgress')
        .then((response) => {
          console.log('impProg', response)
        })
    },

    updated () {
      this.getProgress()

      this.interval = setInterval(function () {
        this.getProgress()
      }.bind(this), 3000)
    },

    beforeDestroy () {
      clearInterval(this.interval)
    }
  }
}
</script>

<style>

</style>
