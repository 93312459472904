<template>
  <aside
    v-if="editMode && open"
    class="grid-edit-inner fixed inset-0 flex flex-col items-center w-full h-full px-4 py-4 z-20"
  >
    <div class="fixed inset-0 w-full h-full bg-black bg-opacity-75 z-1" />
    <div class="grid-edit-inner__container relative grid bg-white rounded h-full max-h-[90vh] w-full max-w-[1200px] z-5">
      <header class="row-start-1 col-span-full flex justify-between items-center h-full px-4 py-3">
        <h2 class="font-bold text-lg">Editing Inner Blocks</h2>
        <vue-button
          :icon="['fal', 'times']"
          aria-label="Close modal"
          sm
          text
          @click="close"
        />
      </header>
      <main class="relative row-start-2 col-start-1 h-full overflow-hidden">
        <div class="absolute inset-0 w-full h-full overflow-y-scroll overflow-x-hidden px-4">
          <div v-if="items && items.length > 0" :class="`grid ${columnClasses} ${rootItemClasses}`">
            <div
              v-for="(item, index) in items"
              :key="index"
            >
              <GridItem
                :item.sync="item"
                :index="index"
                :is-start="index === 0"
                :is-end="index === (items.length - 1)"
                :edit-mode="editMode"
                :editing="index === editingItemIndex"
                @edit-item="editItem"
                @move-item="moveItem"
                @delete-item="deleteItem"
              />
            </div>
          </div>
          <div v-else class="text-gray-500 text-center col-span-full py-16">
            No items added yet! Use the button below to add some.
          </div>
        </div>
      </main>
      <aside class="relative row-start-2 col-start-2 h-full overflow-hidden">
        <div v-if="editingItem" class="absolute inset-0 w-full h-full overflow-y-scroll overflow-x-hidden">
          <GridInnerConfig
            v-if="editMode"
            :item="editingItem"
            :elements="elements"
          />
        </div>
      </aside>
      <footer class="row-start-3 col-span-full flex justify-between items-center px-4 py-3">
        <div class="flex items-center space-x-1">
          <vue-select
            v-model="newItemElement"
            :options="elements"
            label-key="name"
          />
          <vue-button
            v-if="newItemElement"
            class="whitespace-nowrap"
            @click="addItem"
          >
            Add
          </vue-button>
        </div>
        <vue-button
          :icon="['fad', 'save']"
          success
          @click="save"
        >
          Save
        </vue-button>
      </footer>
    </div>
  </aside>
</template>
<script>
import { merge } from 'lodash'
import GridItem from './GridItem'
import GridInnerConfig from './GridInnerConfig'
import UsesRandomKeys from '../mixins/UsesRandomKeys'

export default {
  name: 'GridEditInner',

  components: {
    GridItem,
    GridInnerConfig
  },

  mixins: [
    UsesRandomKeys
  ],

  data () {
    return {
      newItemElement: null,
      editingItem: false,
      editingItemIndex: false
    }
  },

  props: {
    open: {
      type: Boolean,
      required: true
    },

    rootType: {
      type: String,
      required: true
    },

    rootItem: {
      type: [Boolean, Object],
      required: false,
      default: () => { return false }
    },

    editMode: {
      type: Boolean,
      required: false,
      default: () => { return false }
    },

    elements: {
      type: Array,
      required: true
    }
  },

  computed: {
    items: {
      get () {
        return this.rootItem[this.rootType].items
      },

      set (value) {
        this.rootItem[this.rootType].items = value
      }
    },

    columnClasses () {
      const cols = this.rootItem.content.columnCount
      return this.rootType === 'before' || this.rootType === 'after' ? 'grid-cols-3' : `grid-cols-${cols > 3 ? '3' : cols || '1'}`
    },

    rootItemClasses () {
      return this.rootItem ? `${this.rootItem.colors ? `${this.rootItem.colors.text || ''} ${this.rootItem.colors.background || ''} ${this.rootItem.colors.opacity || ''}` : ''}` : ''
    }
  },

  watch: {
    open: {
      handler (newVal, oldVal) {
        this.editingItem = false
        this.editingItemIndex = false
        if (newVal) {
          document.body.classList.add('scroll-lock')
        } else {
          document.body.classList.remove('scroll-lock')
        }
      }
    }
  },

  methods: {
    addItem () {
      const defaultData = {
        key: this.generateRandomKey(),
        layout: {
          aspect_base: 'aspect-w-1 aspect-h-1',
          aspect_xl: 'aspect-w-1 aspect-h-1'
        },
        colors: {
          background: 'bg-dark-900',
          text: 'text-white',
          opacity: 'bg-opacity-100'
        },
        content: {
          header: 'New Block',
          content: 'Put your main content here',
          items: [],
          button: {
            label: null,
            url: null
          }
        },
        images: {
          foo: 'bar'
        },
        before: {
          items: []
        },
        after: {
          items: []
        }
      }
      const blockData = this.newItemElement && this.newItemElement.init ? this.newItemElement.init() : {}
      this.items.push(merge(
        defaultData,
        {
          ...blockData,
          ...{
            component: this.newItemElement.component,
            element: this.newItemElement.key
          }
        }
      ))
    },

    editItem (index) {
      this.editingItemIndex = index
      this.editingItem = this.items[index]
    },

    moveItem (index, amount) {
      const newIndex = index + amount
      if (newIndex < 0 || newIndex === this.items.length) {
        return
      }
      const editing = index === this.editingItem
      const indexes = [index, newIndex].sort((a, b) => { return a - b })
      this.items.splice(indexes[0], 2, this.items[indexes[1]], this.items[indexes[0]])
      if (editing) {
        this.editingItem = newIndex
      }
    },

    deleteItem (index) {
      if (index === this.editingItemIndex) {
        this.editingItem = null
      }
      this.items.splice(index, 1)
    },

    save () {
      this.$root.$emit('editor:inner:save')
    },

    close () {
      this.$root.$emit('editor:inner:close')
    }
  }
}
</script>
<style>
.grid-edit-inner__container {
  grid-template-columns: 2fr 1fr;
  grid-template-rows: auto 1fr auto;
}
</style>
