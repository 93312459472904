<template>
  <div class="px-gap-2xl">
    <TitleSummaryCard
        class="mb-4"
        :edit.sync="edit"
        @edit="startEdit"
        @save="saveEdits"
        @discard="discardEdit"
      >
      </TitleSummaryCard>
      <div class="my-5">
        <vue-data
        v-model="stripe.enabled"
        :value="stripe.enabled"
        :edit="edit"
        type="checkbox"
        :label="stripe.enabled ? 'Stripe Enabled' : 'Enable Stripe'"
        />
      </div>
      <div class="flex justify-end">
        <vue-button
         as="button"
         :icon="['fal', view==true ? 'eye-slash': 'eye']"
         :disabled="edit==false"
         @click="view = !view"
        />
      </div>
    <div>
      <h3>Stripe Configuration</h3>
      <div>
        <vue-data
         v-model="stripe.apiKey"
         :value="stripe.apiKey"
         :edit="edit"
         :type="view == true ? 'text': 'password'"
         :class="edit == false && stripe.apiKey !== '' ? 'blurry-text' : ''"
         label="Stripe Key"
        />
        <vue-data
         v-model="stripe.apiSecret"
         :value="stripe.apiSecret"
         :edit="edit"
         :type="view == true ? 'text': 'password'"
         :class="edit == false && stripe.apiSecret !== '' ? 'blurry-text' : ''"
         label="Stripe Secret"
        />
      </div>
    </div>
  </div>
</template>

<script>
// import { cloneDeep, transform } from 'lodash'
import { cloneDeep } from 'lodash'
import TitleSummaryCard from '@/components/models/TitleSummaryCard'
import VueButton from '../../components/VueButton.vue'

export default {

  name: 'PaymentsStripe',

  components: {
    TitleSummaryCard,
    VueButton
  },

  data () {
    return {
      edit: false,
      view: false,
      stripeOld: false,
      paypalOld: false,
      key: '',
      stripe: {
        apiKey: '',
        apiSecret: '',
        enabled: ''
      },
      route: {
        apiEndpoint: 'pmtConfig'
      }
    }
  },
  async mounted () {
    const { data } = await this.$api.get(this.route.apiEndpoint, {
      params: {
        payment_type: 'stripe'
      }
    })

    const { stripe } = await this.$api.get('pmtConfigs-getStripe')
    this.stripe.apiKey = stripe.key
    this.stripe.apiSecret = stripe.secret
    this.stripe.enabled = data.stripe.enabled
  },
  methods: {
    async toggleEnabled () {
      try {
        const { data } = await this.$api.put(this.route.apiEndpoint, {
          payment_type: 'stripe',
          enabled: this.stripe.enabled
        })
        this.stripe.enabled = data.stripe.enabled
      } catch (error) {
        this.$store.commit('error/addError', error)
      }
    },

    startEdit () {
      this.stripeOld = cloneDeep(this.stripe)
    },

    discardEdit () {
      this.stripe = this.stripeOld
      this.stripeOld = false
      this.edit = false
    },

    saveEdits () {
      // const encrypt = obj => transform(obj, (acc, value, key, target) => {
      //   console.log(value)
      //   acc[key] = this.encrypt(value)
      // })
      // const stripe = encrypt(this.stripe)
      // const payload = {
      //   apiKey: this.encrypt(this.stripe.apiKey),
      //   apiSecret: this.encrypt(this.stripe.apiSecret)
      // }
      // console.log(payload)

      const payload = {
        stripe: {
          apiKey: this.stripe.apiKey,
          apiSecret: this.stripe.apiSecret
        }
      }

      this.$api.post('pmtConfigs-setStripe', { payload })
        .catch((error) => {
          console.log(error)
          this.$store.commit('error/addError', error)
        })

      this.toggleEnabled()
      this.edit = false
    },

    encrypt (data) {
      let iv = this.$CryptoJS.lib.WordArray.random(16)
      const key = this.$CryptoJS.enc.Base64.parse(this.key)
      const options = {
        iv: iv,
        mode: this.$CryptoJS.mode.CBC,
        padding: this.$CryptoJS.pad.Pkcs7
      }
      let encrypted = this.$CryptoJS.AES.encrypt(data, key, options)
      encrypted = encrypted.toString()
      iv = this.$CryptoJS.enc.Base64.stringify(iv)
      let result = {
        iv: iv,
        value: encrypted,
        mac: this.$CryptoJS.HmacSHA256(iv + encrypted, key).toString()
      }
      result = JSON.stringify(result)
      result = this.$CryptoJS.enc.Utf8.parse(result)
      return this.$CryptoJS.enc.Base64.stringify(result)
    },
    decrypt (data) {
      // var data = 'eyJpdiI6Ijg3b1BLa2dlQ0NtUWNMZU1aUFdLRXc9PSIsInZhbHVlIjoibkV4Y2t2ZElxd1pvaDgwUHZFcUpZWnpkcDE3UEdrdnhxYk03UUJiUWNTbVRuQmlyNXNNczhqSjdvdUtndE1aSzBJYkNubVg5NE83Sk9ET1hkcmtObTUrcFhnYUlUWkh2cDhuSm5KeDVlQUk4YUxWV1RYZytTdDlSUFZUQktmVUhmODE5M2h3TGRXMkQ5dGRodTBDcVlBPT0iLCJtYWMiOiJjZTQyZWJkMWZkYjk5ZTM2M2RmZWZhNGY3ZTYyMDZlMmMwYmY3NTgxOWNiY2ZmNmVhOTY0MWRkYTk4MjJlMGFlIiwidGFnIjoiIn0='
      // var key = process.env.SECRET.substring(7)
      var key = this.key
      let encrypted = atob(data)
      encrypted = JSON.parse(encrypted)
      console.log(encrypted)
      const iv = this.$CryptoJS.enc.Base64.parse(encrypted.iv)
      const value = encrypted.value
      key = this.$CryptoJS.enc.Base64.parse(key)
      var decrypted = this.$CryptoJS.AES.decrypt(value, key, {
        iv: iv
      })
      decrypted = decrypted.toString(this.$CryptoJS.enc.Utf8)

      console.log(decrypted)

      return decrypted
    }
  }
}
</script>

<style>
.blurry-text data{
   text-shadow: 0 0 32px white;
   color: transparent;
}
</style>
