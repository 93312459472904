var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('vue-data-table',{attrs:{"cols":[
      {
        header: 'Customer Name',
        value: 'customer_name',
      },
      {
        header: 'Total',
        value: 'grand_total'
      },
      {
        header: 'Billing Status',
        value: 'billing_status_label',
        sort: true
      },
      {
        header: 'Ordered At',
        value: 'created_at',
        sort: true
      },
      {
        header: 'Actions',
        value: 'actions'
      }
    ],"path":"orders"},scopedSlots:_vm._u([{key:"header-left",fn:function(){return undefined},proxy:true},{key:"item.customer_name",fn:function(row){return [_vm._v(" "+_vm._s(row.customer_name)+" ")]}},{key:"item.grand_total",fn:function(row){return [_vm._v(" "+_vm._s(row.grand_total.toLocaleString('en-UK', {'style':'currency','currency':'GBP'}))+" ")]}},{key:"item.created_at",fn:function(row){return [_vm._v(" "+_vm._s(_vm.$momentDateTimeHuman(row.created_at))+" ")]}},{key:"item.billing_status_label",fn:function(row){return [_vm._v(" "+_vm._s(row.billing_status_label)+" ")]}},{key:"item.actions",fn:function(row){return [_c('vue-link',{staticClass:"inline-flex cursor-pointer",attrs:{"to":{
          name: _vm.routes.single,
          params: {
            model: row.id
          }
        },"icon":['fad', 'edit'],"aria-label":("Edit " + (_vm.model.singular) + ": " + (row.name)),"dark":"","text":"","sm":""}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }