<template>
  <div class="px-gap-2xl">
    <TitleSummaryCard
        class="mb-4"
        :edit.sync="edit"
        @edit="startEdit"
        @save="saveEdits"
        @discard="discardEdit"
      >
      </TitleSummaryCard>
      <div class="my-5">
        <vue-data
        v-model="paypal.enabled"
        :value="paypal.enabled"
        :edit="edit"
        type="checkbox"
        :label="paypal.enabled ? 'Paypal Enabled' : 'Enable Paypal'"
        />
      </div>
      <div class="flex justify-end">
        <vue-button
         as="button"
         :icon="['fal', view==true ? 'eye-slash': 'eye']"
         :disabled="edit==false"
         @click="view = !view"
        />
      </div>
    <div>
      <h3>Paypal configuration</h3>

      <div class="flex justify-end">
        <div class="form-check form-switch">
          <input v-model="paypal.testMode" value="paypal.testMode" class="form-check-input appearance-none w-9 -ml-10 rounded-full float-left h-5 align-top bg-white bg-no-repeat bg-contain bg-gray-300 focus:outline-none cursor-pointer shadow-sm" type="checkbox" role="switch" id="flexSwitchCheckChecked" :checked="paypal.testMode" :disabled="edit==false">
          <label class="form-check-label inline-block text-gray-800" for="flexSwitchCheckDefault" v-text="paypal.testMode ? 'Test Mode enabled': 'Test Mode disabled'"></label>
        </div>
      </div>
      <vue-data
         v-model="paypal.clientId"
         :value="paypal.clientId"
         :edit="edit"
         :type="view == true ? 'text': 'password'"
         :class="edit == false && paypal.clientId !== '' ? 'blurry-text' : ''"
         label="Paypal Client ID"
        />
        <vue-data
         v-model="paypal.secret"
         :value="paypal.secret"
         :edit="edit"
         :type="view == true ? 'text': 'password'"
         :class="edit == false && paypal.secret !== '' ? 'blurry-text' : ''"
         label="Paypal Client Secret"
        />
    </div>
  </div>
</template>

<script>
// import { cloneDeep, transform } from 'lodash'
import { cloneDeep } from 'lodash'
import TitleSummaryCard from '@/components/models/TitleSummaryCard'
import VueButton from '../../components/VueButton.vue'

export default {

  name: 'PaymentsPaypal',

  components: {
    TitleSummaryCard,
    VueButton
  },

  data () {
    return {
      edit: false,
      view: false,
      paypalOld: false,
      key: '',
      paypal: {
        enabled: '',
        testMode: true,
        clientId: '',
        secret: ''
      },
      route: {
        apiEndpoint: 'pmtConfig'
      }
    }
  },
  async mounted () {
    await this.getPaymentGatewayStatus()
  },
  methods: {
    startEdit () {
      this.paypalOld = cloneDeep(this.paypal)
    },

    discardEdit () {
      this.paypal = this.paypalOld
      this.paypalOld = false
      this.edit = false
    },

    async getPaymentGatewayStatus () {
      const { data } = await this.$api.get(this.route.apiEndpoint, {
        params: {
          payment_type: 'paypal'
        }
      })
      const { paypal } = await this.$api.get('pmtConfigs-getPaypal')

      this.paypal.clientId = paypal.key
      this.paypal.secret = paypal.secret

      this.paypal.enabled = data.paypal.enabled
    },

    async toggleEnabled () {
      try {
        const { data } = await this.$api.put(this.route.apiEndpoint, {
          payment_type: 'paypal',
          enabled: this.paypal.enabled
        })

        this.paypal.enabled = data.paypal.enabled
        this.getPaymentGatewayStatus()
      } catch (error) {
        this.$store.commit('error/addError', error)
      }
    },

    saveEdits () {
      // const encrypt = obj => transform(obj, (acc, value, key, target) => {
      //   acc[key] = this.encrypt(value)
      // })
      // const stripe = encrypt(this.stripe)
      // const paypal = encrypt(this.paypal)

      // const payload = {
      //   testClientId: this.encrypt(this.paypal.testClientId),
      //   testSecret: this.encrypt(this.paypal.testSecret),
      //   liveClientId: this.encrypt(this.paypal.liveClientId),
      //   liveSecret: this.encrypt(this.paypal.liveSecret)
      // }
      // console.log(this.decrypt(payload.testClientId))
      const payload = {
        paypal: {
          testMode: this.paypal.testMode,
          clientId: this.paypal.clientId,
          secret: this.paypal.secret
        }
      }
      this.$api.post('pmtConfigs-setPaypal', { payload })
        .catch((error) => {
          console.log(error)
          this.$store.commit('error/addError', error)
        })

      this.toggleEnabled()
      this.edit = false
    },
    encrypt (data) {
      let iv = this.$CryptoJS.lib.WordArray.random(16)
      const key = this.$CryptoJS.enc.Utf8.parse(this.key)
      const options = {
        iv: iv,
        mode: this.$CryptoJS.mode.CBC,
        padding: this.$CryptoJS.pad.Pkcs7
      }
      let encrypted = this.$CryptoJS.AES.encrypt(data, key, options)
      encrypted = encrypted.toString()
      iv = this.$CryptoJS.enc.Base64.stringify(iv)
      let result = {
        iv: iv,
        value: encrypted,
        mac: this.$CryptoJS.HmacSHA256(iv + encrypted, key).toString()
      }
      result = JSON.stringify(result)
      result = this.$CryptoJS.enc.Utf8.parse(result)
      return this.$CryptoJS.enc.Base64.stringify(result)
    },
    decrypt (data) {
      // var data = 'eyJpdiI6Ijg3b1BLa2dlQ0NtUWNMZU1aUFdLRXc9PSIsInZhbHVlIjoibkV4Y2t2ZElxd1pvaDgwUHZFcUpZWnpkcDE3UEdrdnhxYk03UUJiUWNTbVRuQmlyNXNNczhqSjdvdUtndE1aSzBJYkNubVg5NE83Sk9ET1hkcmtObTUrcFhnYUlUWkh2cDhuSm5KeDVlQUk4YUxWV1RYZytTdDlSUFZUQktmVUhmODE5M2h3TGRXMkQ5dGRodTBDcVlBPT0iLCJtYWMiOiJjZTQyZWJkMWZkYjk5ZTM2M2RmZWZhNGY3ZTYyMDZlMmMwYmY3NTgxOWNiY2ZmNmVhOTY0MWRkYTk4MjJlMGFlIiwidGFnIjoiIn0='
      // var key = process.env.SECRET.substring(7)
      var key = this.key.substring(7)
      let encrypted = atob(data)
      encrypted = JSON.parse(encrypted)
      console.log(encrypted)
      const iv = this.$CryptoJS.enc.Base64.parse(encrypted.iv)
      const value = encrypted.value
      key = this.$CryptoJS.enc.Base64.parse(key)
      var decrypted = this.$CryptoJS.AES.decrypt(value, key, {
        iv: iv
      })
      decrypted = decrypted.toString(this.$CryptoJS.enc.Utf8)

      console.log(decrypted)

      return decrypted
    }
  }
}
</script>

<style>
.blurry-text data{
   text-shadow: 0 0 32px white;
   color: transparent;
}

</style>
