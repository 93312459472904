<template>
  <div>
        <vue-data-table
      :cols="[
        {
          header: 'User Name',
          value: 'name',
        },
        {
          header: 'Email Address',
          value: 'email'
        },
        {
          header: 'Added At',
          value: 'created_at',
          sort: true
        },
        {
          header: 'Last Modified',
          value: 'updated_at',
          sort: true
        },
        {
          header: 'Actions',
          value: 'actions'
        }
      ]"
      path="unapprovedUsers"
    >
      <template #header-left>
        <h3 class="m-3 font-bold">Manage Unapproved User Applications</h3>
      </template>
      <template v-slot:item.title="row">
        {{ row.value.title }}
      </template>
      <template v-slot:item.ref="row">
        {{ row.value.ref }}
      </template>
      <template v-slot:item.created_at="row">
        {{ $momentDateTimeHuman(row.created_at) }}
      </template>
      <template v-slot:item.updated_at="row">
        {{ $momentDateTimeHuman(row.updated_at) }}
      </template>
      <template v-slot:item.actions="row">
        <vue-link
          :to="{
            name: routes.single,
            params: {
              model: row.id
            }
          }"
          :icon="['fad', 'edit']"
          :aria-label="`Edit ${model.singular}: ${row.name}`"
          dark
          text
          sm
          class="inline-flex cursor-pointer"
        />
      </template>
    </vue-data-table>
  </div>
</template>

<script>
export default {
  name: 'UsersUnapproved',

  data () {
    return {
      model: {
        singular: 'User',
        plural: 'Users'
      },
      routes: {
        index: 'users.unapproved',
        new: 'users.new',
        single: 'users.single'
      }
    }
  }
}
</script>

<style>

</style>
