<template>
  <NewModelLayout>
    <template #title>
      <h1 class="text-2xl">
        {{ route.title }}
      </h1>
    </template>
    <template #form>
      <vue-form
        :form="form"
        :errors.sync="error.errors"
        ref="form"
        class="bg-gray-100 shadow rounded-sm"
      >
        <div class="bg-white px-6 py-4">
          <div class="flex flex-wrap w-row-offset-sm -mx-2">
            <div class="w-full lg:w-1/2 px-2 mb-4">
              <vue-input-group
                v-model="form.value.title"
                :errors="error.errors.title"
                name="title"
                prop="title"
                label="Title"
                description="The title of the page."
                required="true"
              />
            </div>
            <div class="w-full lg:w-1/2 px-2 mb-4">
              <vue-input-group
                v-model="form.value.ref"
                :errors="error.errors.ref"
                name="ref"
                prop="ref"
                label="Reference Number"
                description="The reference number for this job."
                required="true"
              />
            </div>
            <div class="w-full lg:w-1/2 px-2 mb-4">
              <vue-input-group
                v-model="form.key"
                :errors="error.errors.key"
                name="key"
                prop="key"
                label="Metadata Key"
                description="The metadata key for this page. Auto-generated when the name is filled."
                required="true"
                readonly="true"
              />
            </div>
          </div>
        </div>
        <div class="flex justify-between items-center w-full px-4 py-4">
          <vue-button
            text
            error
            @click="resetForm"
          >
            Reset
          </vue-button>
          <vue-button
            :icon="['fad', 'save']"
            type="submit"
            success
            @click="submitModel"
          >
            Add {{ route.singular }}
          </vue-button>
        </div>
      </vue-form>
    </template>
  </NewModelLayout>
</template>
<script>
import { cloneDeep } from 'lodash'
import NewModelLayout from '@/components/models/NewModelLayout'
import HasSlug from '@/mixins/HasSlug'

export default {
  name: 'JobsNew',

  components: {
    NewModelLayout
  },

  mixins: [
    HasSlug
  ],

  data () {
    return {
      loading: true,
      form: {},
      defaults: {
        key: '',
        type: 'job',
        protected: false,
        value: {
          title: '',
          content: '',
          ref: 'ESS',
          job: {
            openings: 1,
            type: 'Full Time',
            duration: 'Permanent',
            location: 'Blackburn',
            department: '',
            hours: '',
            pay_rate: '',
            pay_duration: 'hour',
            salary: '',
            benefits: ''
          },
          form: {
            name: true,
            email: true,
            phone: true,
            message: true,
            file: true
          },
          seo: {
            keyphrase: '',
            title: '',
            description: ''
          }
        }
      },
      keyPrepend: 'job_',
      route: {
        title: 'Add New Job',
        singular: 'Job',
        plural: 'Jobs',
        apiEndpoint: 'metadata',
        routeNext: 'jobs.single'
      },
      error: {
        errors: {}
      }
    }
  },

  watch: {
    'form.value.title': function (val) {
      this.form.key = `${this.keyPrepend}${this.getSlug(val, '_')}`
    }
  },

  mounted () {
    this.initForm()
  },

  methods: {
    initForm () {
      this.form = cloneDeep(this.defaults)
    },

    resetForm () {
      this.initForm()
    },

    submitModel () {
      this.$refs.form.validate()
      this.$api.post(this.route.apiEndpoint, this.form)
        .then((res) => {
          this.$router.push({
            name: this.route.routeNext,
            params: {
              model: res.data.id
            }
          })
        })
        .catch((data) => {
          this.$store.commit('error/addError', data)
        })
    }
  }
}
</script>
