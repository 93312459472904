<template>
  <div class="flex flex-col">
    <div class="w-full bg-gray-100 text-left px-2 py-2">
      <label>Content</label>
    </div>
    <div class="flex flex-col border border-gray-100 border-t-0 space-y-2 py-2">
      <div class="w-full px-2">
        <vue-input-group
          v-model="item.content.columnCount"
          :options="[1,2,3,4,5,6,7,8,9]"
          name="column_count"
          prop="column_count"
          label="Columns"
          description="Pick how many columns should be displayed on desktop"
          type="select"
        />
      </div>
      <div class="w-full px-2">
        <vue-input-group
          v-model="item.content.columnCountMobile"
          :options="[1,2,3,4,5,6]"
          name="column_count_mobile"
          prop="column_count_mobile"
          label="Columns (Mobile)"
          description="Pick how many columns should be displayed on mobile"
          type="select"
        />
      </div>
      <div class="w-full px-2">
        <vue-button
          sm
          class="w-full"
          @click="edit"
        >
          Edit Inner Blocks
        </vue-button>
      </div>
    </div>
    <GridItemLayoutConfig
      :item="item"
    />
    <GridItemColorsConfig
      :item="item"
    />
  </div>
</template>
<script>
import GridItemLayoutConfig from '../../GridItemLayoutConfig'
import GridItemColorsConfig from '../../GridItemColorsConfig'

export default {
  components: {
    GridItemLayoutConfig,
    GridItemColorsConfig
  },

  props: {
    item: {
      type: Object,
      required: true
    }
  },

  methods: {
    edit () {
      this.$root.$emit('editor:inner:edit', this.item)
    }
  }
}
</script>
