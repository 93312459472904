<template>
  <div>
    <header :class="{
      'fixed top-0 left-0 flex justify-between items-center w-full bg-white text-shade-dark h-12 z-10': true,
      'pl-64 transition-all': expanded,
      'pl-0 transition-all': !expanded
    }">
      <div class="flex items-center h-full">
        <button
          type="button"
          class="navigation-menu__toggler flex justify-center items-center h-full px-6"
          aria-label="Open navigation menu"
          title="Open navigation menu"
          @click="expanded = !expanded"
        >
          <font-awesome-icon
            :icon="['fal', 'bars']"
            size="lg"
          />
        </button>
        <h1 class="font-bold px-2 py-4">
          <a :href="frontendUrl" target="_blank">
            Euro Stainless
          </a>
        </h1>
      </div>
      <div class="relative">
        <a
          class="flex items-center hover:bg-primary-light cursor-pointer space-x-2 px-6 py-4"
          @click="setMenu('home')"
        >
          <font-awesome-icon :icon="['fad', 'user']" />
          <span v-if="$auth.user.name">{{ $auth.user.name }}</span>
        </a>
        <div
          v-if="navigation === 'home'"
          class="static lg:absolute right-0 w-full origin-top lg:origin-top-right lg:shadow-lg lg:w-48"
        >
          <div class="px-2 py-2 bg-white lg:rounded-md lg:shadow">
            <a
              class="block text-primary font-medium hover:bg-primary-light p-2 rounded px-3 cursor-pointer"
              @click="$auth.logout(); resetMenu"
            >
              Logout
            </a>
            <router-link
              :to="{
                name: 'account'
              }"
              class="block text-primary font-medium hover:bg-primary-light p-2 rounded px-3 cursor-pointer"
              @click.native="resetMenu"
            >
              Account
            </router-link>
          </div>
        </div>
      </div>
    </header>
    <aside :class="{
      'fixed w-64 h-screen bg-white z-20': true,
      'shadow ml-0 transition-all overflow-y-auto': expanded,
      'shadow-none -ml-64 transition-all': !expanded
    }">
      <nav class="flex flex-col">
        <div class="relative">
          <router-link
            :to="{
              name: 'home'
            }"
            class="flex items-center hover:bg-primary hover:text-primary-contrast px-2 py-6"
            @click.native="resetMenu"
          >
            <font-awesome-icon
              :icon="['fad', 'tachometer-alt']"
              size="lg"
              fixed-width
              class="mr-4"
            />
            Dashboard
          </router-link>
        </div>
        <vue-navigation-item
          unique="pages"
          label="Pages"
          :route="{
            name: 'pages.index'
          }"
          :add-route="{
            name: 'pages.new'
          }"
          :icon="['fad', 'file']"
          @set-menu="setMenu"
          @reset-menu="resetMenu"
        />
        <vue-navigation-item
          unique="sets"
          label="Sets & Categories"
          :route="{
            name: 'sets.index'
          }"
          :icon="['fad', 'layer-group']"
          @set-menu="setMenu"
          @reset-menu="resetMenu"
        />
        <vue-navigation-item
         unique="product-management"
         label="Products"
         :route="{
            name: 'products.index'
         }"
         :icon="['fad', 'album-collection']"
         @set-menu="setMenu"
         @reset-menu="resetMenu"
         />
         <vue-navigation-item
         unique="orders-management"
         label="Orders"
         :icon="['fad', 'bags-shopping']"
         :children="[
            { label: 'All Orders', icon: ['fad', 'box-full'], route: { name: 'orders.index' } },
            { label: 'Verify Tubing Orders', icon: ['fad', 'hand-holding-box'], route: { name: 'orders.verify' }, badge: '' },
          ]"
         :show-children="checkMenu('orders-management')"
         @set-menu="setMenu"
         @reset-menu="resetMenu"
        />
         <vue-navigation-item
         unique="payment-management"
         label="Payments"
         :icon="['fad', 'file-invoice-dollar']"
         :children="[
            { label: 'Stripe', icon: ['fab', 'stripe-s'], route: { name: 'payments.stripe' } },
            { label: 'Paypal', icon: ['fab', 'paypal'], route: { name: 'payments.paypal' } },
            { label: 'Bacs', icon: ['fas', 'university'], route: { name: 'payments.bacs' } },
          ]"
        :show-children="checkMenu('payment-management')"
         @set-menu="setMenu"
         @reset-menu="resetMenu"
         />
         <vue-navigation-item
         unique="shipment-management"
         label="Manage Shipping"
         :route="{
            name: 'shipping.index'
         }"
         :icon="['fad', 'shipping-fast']"
         @set-menu="setMenu"
         @reset-menu="resetMenu"
         />
         <vue-navigation-item
         unique="pricing-management"
         label="Manage Pricing"
         :route="{
            name: 'pricing.index'
         }"
         :icon="['fad', 'tags']"
         @set-menu="setMenu"
         @reset-menu="resetMenu"
         />
         <vue-navigation-item
         unique="user-management"
         label="Users"
         :icon="['fad', 'user']"
         :children="[
            { label: 'Admin Users', icon: ['fad', 'users-crown'], route: { name: 'users.admin' } },
            { label: 'UnApproved Users', icon: ['fad', 'user-lock'], route: { name: 'users.unapproved' } },
            { label: 'Approved Users', icon: ['fad', 'users'], route: { name: 'users.index' } },
            { label: 'Add User', icon: ['fad', 'user-plus'], route: { name: 'users.new' } },
          ]"
         :show-children="checkMenu('user-management')"
         @set-menu="setMenu"
         @reset-menu="resetMenu"
        />
        <vue-navigation-item
          unique="website"
          label="Website"
          :icon="['fad', 'globe']"
          :children="[
            { label: 'Posts', icon: ['fad', 'newspaper'], route: { name: 'posts.index' } },
            { label: 'Jobs', icon: ['fad', 'briefcase'], route: { name: 'jobs.index' } },
            { label: 'Menus', icon: ['fad', 'list-ul'], route: { name: 'menus.index' } },
            { label: 'Footer', icon: ['fad', 'file'], route: { name: 'footer' } },
            { label: 'Block Templates', icon: ['fad', 'cubes'], route: { name: 'templates.index' } }
          ]"
          :show-children="checkMenu('website')"
          @set-menu="setMenu"
          @reset-menu="resetMenu"
        />
      </nav>
    </aside>
  </div>
</template>
<script type="text/javascript">
export default {
  name: 'VueNavigation',

  data () {
    return {
      navigation: null,
      expanded: false
    }
  },

  computed: {
    frontendUrl () {
      return process.env.VUE_APP_FRONTEND_URL || '/'
    }
  },

  watch: {
    $route: {
      deep: true,
      handler () {
        this.resetMenu()
      }
    }
  },

  methods: {
    setMenu (item) {
      this.navigation = (this.navigation === item ? null : item)
    },

    checkMenu (item) {
      return this.navigation === item
    },

    resetMenu () {
      this.navigation = null
      this.expanded = false
    }
  }
}
</script>
