<template>
  <div class="w-full">
    <section class="flex flex-wrap w-row-offset-sm -mx-2">
      <div class="w-full md:w-1/4 order-last px-2 mb-4">
        <GridLayoutConfig
          v-if="editMode"
          :data.sync="data"
          :index="editingItem"
          @add-item="addItem"
        />
      </div>
      <div class="w-full md:w-3/4 order-first flex-grow px-2 mb-4">
        <h2 class="text-2xl mb-2">Page Content</h2>
        <div v-bind:style="containerStyles" class="grid">
          <template v-if="itemCount > 0">
            <GridItem
              v-for="(item, index) in data.items"
              :key="index"
              :item.sync="item"
              :index="index"
              :isStart="index === 0"
              :isEnd="index === (data.items.length - 1)"
              :edit-mode="editMode"
              :editing="index === editingItem"
              @edit-item="editItem"
              @move-item="moveItem"
              @delete-item="deleteItem"
            />
          </template>
        </div>
        <NewGridItem
          v-if="editMode && !reachedBlockLimit"
          class="w-full space-y-2 px-4 py-4 mt-4"
        >
          <font-awesome-icon :icon="['fas', 'plus-circle']" fixed-width />
          <span>Add a new block</span>
          <div class="flex space-x-2">
            <vue-select
              v-model="newItemElement"
              :options="elements"
              label-key="name"
            />
            <vue-button sm class="whitespace-nowrap" @click="addItem">
              Add
            </vue-button>
          </div>
        </NewGridItem>
      </div>
    </section>
    <GridEditInner
      :open="editingInner !== false"
      :root-type="editingInnerMode"
      :root-item="selectedItem"
      :elements="elements"
      :edit-mode="editMode"
    />
  </div>
</template>
<script>
import { merge, filter, cloneDeep, isEmpty } from 'lodash'
import ItemElements from '../elements.js'
import GridLayoutConfig from './GridLayoutConfig'
import GridItem from './GridItem'
import GridEditInner from './GridEditInner'
import NewGridItem from './NewGridItem'
import UsesRandomKeys from '../mixins/UsesRandomKeys'

export default {
  name: 'GridLayoutContainer',

  components: {
    GridLayoutConfig,
    GridItem,
    GridEditInner,
    NewGridItem
  },

  mixins: [
    UsesRandomKeys
  ],

  props: {
    data: {
      type: Object,
      required: true
    },

    editMode: {
      type: Boolean,
      required: false,
      default: () => { return false }
    },

    blockLimit: {
      type: [Boolean, Number],
      required: false,
      default: () => { return false }
    }
  },

  data () {
    return {
      newItemElement: false,
      editingInner: false,
      editingInnerMode: 'content',
      editingItem: null
    }
  },

  watch: {
    data: {
      deep: true,
      handler () {
        this.$emit('update:data', this.data)
        this.checkDataHasChildBlock()
      }
    },

    editMode: {
      handler () {
        this.editingItem = null
      }
    }
  },

  computed: {
    selectedItem () {
      return this.data && this.data.items ? this.data.items[this.editingItem] || false : false
    },

    itemCount () {
      return this.data && this.data.items ? this.data.items.length : 0
    },

    containerStyles () {
      return {
        gridTemplateColumns: this.blockLimit ? null : 'repeat(12, 1fr)',
        autoTemplateColumns: '1fr'
      }
    },

    elements () {
      return ItemElements
    },

    reachedBlockLimit () {
      return this.blockLimit ? this.itemCount >= this.blockLimit : false
    }
  },

  mounted () {
    this.init()
    this.$root.$on('editor:inner:edit', (item, mode = 'content') => {
      this.editingInner = item
      this.editingInnerMode = mode
      this.$store.commit('editor/editInner', item)
    })
    this.$root.$on('editor:inner:save', () => {
      this.data.items[this.editingItem] = cloneDeep(this.editingInner)
      this.editingInner = false
      this.editingInnerMode = 'content'
      this.$store.commit('editor/editInner', false)
    })
    this.$root.$on('editor:inner:close', () => {
      this.editingInner = false
      this.editingInnerMode = 'content'
      this.$store.commit('editor/editInner', false)
    })
  },

  methods: {
    init () {
      if (isEmpty(this.data)) {
        this.$emit('update:data', {
          autoCols: '1fr',
          autoRows: '1fr',
          cols: ['1fr'],
          rows: [],
          items: []
        })
      }
      this.$store.dispatch('editor/updateTemplates')
      this.$store.dispatch('editor/updateCategories')
      this.$store.dispatch('editor/updateMenus')
      this.checkDataHasChildBlock()
    },

    addItem () {
      if (typeof this.data.items === 'undefined') {
        this.$set(this.data, 'items', [])
      }
      const defaultData = {
        key: this.generateRandomKey(),
        layout: {
          width: 'col-span-1/3',
          aspect_base: 'aspect-w-1 aspect-h-1',
          aspect_xl: 'aspect-w-1 aspect-h-1'
        },
        colors: {
          background: 'bg-dark-900',
          text: 'text-white',
          opacity: 'bg-opacity-100'
        },
        content: {
          header: '',
          content: '',
          items: [],
          button: {
            label: null,
            url: null
          }
        },
        images: {
          foo: 'bar'
        },
        before: {
          items: []
        },
        after: {
          items: []
        }
      }
      const blockData = this.newItemElement && this.newItemElement.init ? this.newItemElement.init() : {}
      this.data.items.push(merge(
        defaultData,
        {
          ...blockData,
          ...{
            component: this.newItemElement.component,
            element: this.newItemElement.key
          }
        }
      ))
    },

    editItem (index) {
      this.editingItem = index
      if (this.data.items[this.editingItem] && !this.data.items[this.editingItem].images) {
        this.$set(this.data.items[this.editingItem], 'images', {})
      }
    },

    moveItem (index, amount) {
      const newIndex = index + amount
      if (newIndex < 0 || newIndex === this.data.items.length) {
        return
      }
      const editing = index === this.editingItem
      const indexes = [index, newIndex].sort((a, b) => { return a - b })
      this.data.items.splice(indexes[0], 2, this.data.items[indexes[1]], this.data.items[indexes[0]])
      if (editing) {
        this.editingItem = newIndex
      }
    },

    deleteItem (index) {
      if (index === this.editingItem) {
        this.editingItem = null
      }
      this.data.items.splice(index, 1)
    },

    checkDataHasChildBlock () {
      this.$store.commit('editor/update', { key: 'editorHasChildContent', value: (filter(this.data.items, { element: 'child-page' }).length > 0) })
    }
  }
}
</script>
