<template>
  <div class="flex flex-col">
    <div class="w-full bg-gray-100 text-left px-2 py-2">
      <label>Content</label>
    </div>
    <div class="flex flex-col border border-gray-100 border-t-0 space-y-2 py-2">
      <div class="w-full px-2">
        <vue-button
          sm
          class="w-full"
          @click="edit"
        >
          Edit Inner Blocks
        </vue-button>
      </div>
      <div class="w-full px-2">
        <vue-input-group
          v-model="item.content.arrowType"
          prop="arrow_type"
          name="arrow_type"
          :options="[
            {
              label: 'Sides',
              value: 'side'
            },
            {
              label: 'Bottom Left',
              value: 'bottom_left'
            },
            {
              label: 'Bottom Right',
              value: 'bottom_right'
            }
          ]"
          labelKey="label"
          valueKey="value"
          type="select"
          label="Arrows Location"
        />
      </div>
      <div class="w-full px-2">
        <vue-input-group
          v-model="item.content.autoplayDelay"
          prop="autoplay_delay"
          name="autoplay_delay"
          type="number"
          step="0.5"
          min="0"
          label="Autoplay Duration"
          description="How long in seconds to show each slide before moving to the next. Set to 0 to disable autoplay."
        />
      </div>
      <div class="w-full px-2">
        <vue-input-group
          v-model="item.content.button.label"
          prop="button.label"
          name="button.label"
          label="Button Label"
          description="Leave blank to hide the button."
        />
        <vue-input-group
          v-model="item.content.button.url"
          prop="button.url"
          name="button.url"
          label="Button URL"
          description="The URL to link to. For relative links, use a slash prefix (eg. /about). For external links, use http prefix (eg. https://google.com)"
        />
        <vue-input-group
          v-model="item.layout.button"
          :options="buttonLayoutOptions"
          prop="button"
          name="button"
          type="select"
          label-key="label"
          value-key="value"
          label="Button Side"
          description="Select the side in which to display the button"
        />
      </div>
    </div>
    <GridItemLayoutConfig
      :item="item"
    />
    <GridItemColorsConfig
      :item="item"
    />
  </div>
</template>
<script>
import GridItemLayoutConfig from '../../GridItemLayoutConfig'
import GridItemColorsConfig from '../../GridItemColorsConfig'

export default {
  components: {
    GridItemLayoutConfig,
    GridItemColorsConfig
  },

  props: {
    item: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      buttonLayoutOptions: [
        {
          label: 'Bottom Left',
          value: 'h-gap w-gap-3xl bottom-0 left-0'
        },
        {
          label: 'Bottom Right',
          value: 'h-gap w-gap-3xl bottom-0 right-0'
        },
        {
          label: 'Top Left',
          value: 'h-gap w-gap-3xl top-0 left-0'
        },
        {
          label: 'Top Right',
          value: 'h-gap w-gap-3xl top-0 right-0'
        }
      ]
    }
  },

  methods: {
    edit () {
      this.$root.$emit('editor:inner:edit', this.item)
    }
  }
}
</script>
