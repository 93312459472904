<template>
  <SingleModelLayout
    :model.sync="model"
    :route-back="{
      name: route.index
    }"
    @refresh-model="getModel"
    @delete-model="deleteModel"
  >
    <template #title>
      <TitleSummaryCard
        class="mb-4"
        :edit.sync="edit"
        @edit="startEdit"
        @save="saveModel"
        @discard="discardEdit"
      >
        <template #title>
          <vue-data
            v-model="model.value.title"
            :value="model.value.title"
            :edit="edit"
            :border="false"
          />
        </template>
        <template #subtitle class="opacity-50">
          {{ model.id }}
        </template>
      </TitleSummaryCard>
    </template>
    <template #left>
      <seo-section
        :model="model"
        :edit="edit"
        url-prefix="/news/"
      />
    </template>
    <template #main>
      <vue-data
        v-model="model.value.content"
        :edit="edit"
        type="editor"
        label="Content"
        :render-styles="`columns: ${model.value.layout.columns || 1}; column-gap: 1.75rem;`"
      />
    </template>
    <template #right>
      <vue-data
        :value="model.images ? model.images.featured_image : null"
        :edit="edit"
        :model="model"
        class="mb-4"
        type="file"
        accept="image/png, image/jpeg, image/gif"
        label="Featured Image"
        @update="syncImage($event, 'featured_image')"
      />
      <vue-data
        v-model="model.value.slug"
        :value="model.value.slug"
        :edit="edit"
        class="mb-2"
        label="Slug"
      />
      <vue-data
        v-model="model.value.layout.columns"
        :value="model.value.layout.columns"
        :edit="edit"
        :options="[1, 2, 3, 4]"
        class="mb-2"
        label="Columns"
        type="select"
      />
      <vue-data
        v-model="model.value.author"
        :value="model.value.author"
        :edit="edit"
        :options="userOptions"
        class="mb-2"
        label-key="name"
        label="Author"
        type="select"
      />
    </template>
  </SingleModelLayout>
</template>
<script type="text/javascript">
import { cloneDeep } from 'lodash'
import SingleModelLayout from '@/components/models/SingleModelLayout'
import TitleSummaryCard from '@/components/models/TitleSummaryCard'
import UsesTokens from '@/mixins/UsesTokens'

export default {
  name: 'PagesSingle',

  components: {
    SingleModelLayout,
    TitleSummaryCard
  },

  mixins: [
    UsesTokens
  ],

  data () {
    return {
      loading: false,
      edit: false,
      model: false,
      modelOld: false,
      files: [],
      route: {
        apiEndpoint: 'metadata',
        mediaEndpoint: 'metadata',
        laravelModel: 'Metadata',
        index: 'posts.index'
      }
    }
  },

  computed: {
    value () {
      return this.model.value ?? {}
    },

    content () {
      return this.value && this.value.content ? this.value.content : false
    }
  },

  mounted () {
    this.getModel()
    this.getUsers()
    this.$route.params.media = {
      endpoint: this.route.mediaEndpoint,
      model: this.route.laravelModel
    }
  },

  methods: {
    getModel () {
      this.loading = true
      this.$api.get(`${this.route.apiEndpoint}/${this.$route.params.model}`, {
        params: {
          withImages: true
        }
      })
        .then((res) => {
          this.model = res.data
        })
        .catch((err) => {
          this.$store.commit('error/addError', err)
        })
        .finally(() => {
          this.loading = false
        })
    },

    deleteModel () {
      this.loading = true
      this.$api.delete(`${this.route.apiEndpoint}/${this.$route.params.model}`)
        .then((res) => {
          this.$router.push({
            name: this.route.index
          })
        })
        .catch((err) => {
          this.$store.commit('error/addError', err)
        })
    },

    saveModel () {
      this.loading = true
      this.getToken().then(() => {
        this.saveFiles().then(() => {
          this.$api.put(`${this.route.apiEndpoint}/${this.$route.params.model}`, this.model)
            .then((res) => {
              this.getModel()
              this.edit = false
            })
            .catch((err) => {
              this.$store.commit('error/addError', err)
            })
            .finally(() => {
              this.loading = false
            })
        })
          .then(() => {
            this.files = []
          })
      })
    },

    getUsers () {
      this.loading = true
      this.$api.get('users')
        .then((res) => {
          this.userOptions = res.data
        })
        .catch((err) => {
          this.$store.commit('error/addError', err)
        })
        .finally(() => {
          this.loading = false
        })
    },

    syncImage (data, collection) {
      this.files.push({
        collection,
        data
      })
    },

    removeImage () {
      this.files = false
    },

    async saveFiles () {
      if (this.files) {
        this.files.forEach((file) => {
          const formData = new FormData()
          formData.append('file', file.data)
          formData.append('collection', file.collection)
          formData.append('model', this.route.laravelModel)
          this.$api.post(`media/upload/${this.route.mediaEndpoint}/${this.$route.params.model}`, formData, {
            'Content-Type': 'multipart/form-data'
          })
            .catch((err) => {
              this.$store.commit('error/addError', err)
            })
        })
      }
      return this.files
    },

    startEdit () {
      this.modelOld = cloneDeep(this.model)
    },

    discardEdit () {
      this.model = this.modelOld
      this.modelOld = false
      this.edit = false
    }
  }
}
</script>
