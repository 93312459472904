<template>
  <SingleModelLayout
    :model="model"
    :route-back="{
      name: 'sets.index'
    }"
    @refresh-model="getModel"
    @delete-model="deleteModel"
  >
    <template #title>
      <TitleSummaryCard
        class="mb-4"
        :edit.sync="edit"
        @edit="startEdit"
        @save="saveModel"
        @discard="discardEdit"
      >
        <template #title>
          <vue-data
            v-model="model.name"
            :value="model.name"
            :edit="edit"
            :border="false"
          />
        </template>
        <template #subtitle class="opacity-50">
          {{ model.id }}
        </template>
      </TitleSummaryCard>
    </template>
    <template #left>
      <vue-data
        v-if="modelType.images && modelType.type === 'product'"
        :value="model.images ? model.images.logo : null"
        :edit="edit"
        :model="model"
        class="svg-file mb-4"
        type="file"
        accept="image/svg+xml"
        label="SVG Icon"
        @update="syncImage($event, 'logo')"
        @discard="removeImage"
      />
      <vue-data
        v-else-if="modelType.images"
        :value="model.images ? model.images.logo : null"
        :edit="edit"
        :model="model"
        class="mb-4"
        type="file"
        accept="image/png, image/jpeg, image/gif"
        label="Set Logo"
        @update="syncImage($event, 'logo')"
        @discard="removeImage"
      />
      <vue-data
        v-if="modelType.images && modelType.type === 'product'"
        v-model="model.data.foreground_image_alt"
        :value="model.data.foreground_image_alt"
        :edit="edit"
        class="mb-2"
        label="Foreground Image Alt Text"
      />
      <vue-data
        v-model="model.key"
        :value="model.key"
        :edit="edit"
        class="mb-2"
        label="Key/Slug"
        description="Ensure this key is unique and doesn't already exist."
      />
      <seo-section
        v-if="modelType.seo"
        :model="model"
        :edit="edit"
        :slug="modelType.slug"
      />
    </template>
    <template #main>
      <models-assigned
        :models.sync="model[modelType.key]"
        :type="model.type"
        :edit="edit"
      />
      <grid-layout-container
        v-if="modelType.type === 'product'"
        :data.sync="model.data.layout"
        :edit-mode="edit"
      />
    </template>
  </SingleModelLayout>
</template>
<script type="text/javascript">
import { cloneDeep, isEmpty } from 'lodash'
import SingleModelLayout from '@/components/models/SingleModelLayout'
import TitleSummaryCard from '@/components/models/TitleSummaryCard'
import ModelsAssigned from '@/components/models/ModelsAssigned'
import UsesTokens from '@/mixins/UsesTokens'

export default {
  name: 'SetsSingle',

  components: {
    SingleModelLayout,
    TitleSummaryCard,
    ModelsAssigned
  },

  mixins: [
    UsesTokens
  ],

  data () {
    return {
      loading: false,
      model: false,
      modelOld: false,
      files: [],
      edit: false,
      route: {
        apiEndpoint: 'sets',
        mediaEndpoint: 'set',
        laravelModel: 'Set',
        index: 'sets.index'
      }
    }
  },

  computed: {
    modelType () {
      let data
      switch (this.model.type) {
        case 'PurpleMountain\\PmmEcommerce\\Models\\Assembly':
          data = {
            type: 'assembly',
            key: 'fields',
            seo: false,
            images: true
          }
          break
        case 'PurpleMountain\\PmmEcommerce\\Models\\Product':
          data = {
            type: 'product',
            key: 'products',
            seo: true,
            slug: `/products/${this.model.key}`,
            images: true
          }
          break
        case 'PurpleMountain\\PmmEcommerce\\Models\\Set':
          data = {
            type: 'set',
            key: 'sets',
            seo: false,
            images: true
          }
          break
        case 'PurpleMountain\\PmmMetadata\\Models\\Metadata':
          data = {
            key: 'metadata',
            seo: false,
            images: false
          }
          break
        case 'App\\Models\\Menu':
          data = {
            key: 'menus',
            seo: false,
            images: false
          }
          break
        default:
          data = {
            key: 'models',
            seo: false,
            images: false
          }
      }
      return data
    }
  },

  mounted () {
    this.getModel()
    this.$route.params.media = {
      endpoint: this.route.mediaEndpoint,
      model: this.route.laravelModel
    }
  },

  methods: {
    getModel () {
      this.loading = true
      this.$api.get(`${this.route.apiEndpoint}/${this.$route.params.model}`, {
        params: {
          withImages: true,
          withPivot: true,
          with: ['menus', 'metadata', 'sets', 'assemblies', 'products', 'products.parts']
        }
      })
        .then((res) => {
          this.model = {
            ...res,
            ...(isEmpty(res.data) ? {
              data: {
                images: {},
                layout: {
                  cols: ['1fr'],
                  rows: [],
                  autoCols: '1fr',
                  autoRows: '1fr'
                },
                seo: {
                  keyphrase: '',
                  title: '',
                  description: ''
                }
              }
            } : {})
          }
        })
        .catch((err) => {
          this.$store.commit('error/addError', err)
        })
        .finally(() => {
          this.loading = false
        })
    },

    saveModel () {
      this.loading = true
      this.getToken().then(() => {
        this.saveFiles().then(() => {
          this.$api.put(`${this.route.apiEndpoint}/${this.$route.params.model}`, this.model)
            .then((res) => {
              this.getModel()
              this.edit = false
            })
            .catch((err) => {
              this.$store.commit('error/addError', err)
            })
            .finally(() => {
              this.loading = false
            })
        })
      })
    },

    deleteModel () {
      this.loading = true
      this.$api.delete(`${this.route.apiEndpoint}/${this.$route.params.model}`)
        .then((res) => {
          this.$router.push({
            name: this.route.index
          })
        })
        .catch((err) => {
          this.$store.commit('error/addError', err)
        })
    },

    syncImage (data, collection) {
      this.files.push({
        collection,
        data
      })
    },

    removeImage () {
      this.files = false
    },

    async saveFiles () {
      if (this.files) {
        this.files.forEach((file) => {
          const formData = new FormData()
          formData.append('file', file.data)
          formData.append('collection', file.collection)
          formData.append('model', this.route.laravelModel)
          this.$api.post(`media/upload/${this.route.mediaEndpoint}/${this.$route.params.model}`, formData, {
            'Content-Type': 'multipart/form-data'
          })
            .catch((err) => {
              this.$store.commit('error/addError', err)
            })
        })
      }
      return this.files
    },

    startEdit () {
      this.modelOld = cloneDeep(this.model)
    },

    discardEdit () {
      this.model = this.modelOld
      this.modelOld = false
      this.edit = false
    }
  }
}
</script>
