<template>
  <SingleModelLayout
    :model.sync="model"
    :route-back="{
      name: route.index
    }"
    @refresh-model="getModel"
    @delete-model="deleteModel"
  >
    <template #title>
      <TitleSummaryCard
        class="mb-4"
        :edit.sync="edit"
        @edit="startEdit"
        @save="saveModel"
        @discard="discardEdit"
      >
        <template #title>
          <vue-data
            v-model="model.name"
            :value="model.name"
            :edit="edit"
            :border="false"
          />
        </template>
        <template #subtitle class="opacity-50">
          {{ model.id }}
        </template>
      </TitleSummaryCard>
    </template>
    <template #left>
      <!-- <vue-data
       v-model="model.user_type"
       :value="model.user_type"
       :edit="edit"
       :options="[
         { label: 'Admin', value: 'admin'},
         { label: 'Customer', value: 'user'}
        ]"
        label="User type"
        label-key="label"
        value-key="value"
        type="select"
        class="mb-2"
       /> -->
    </template>
    <template #main>
      <div class="w-full lg:w-1/2 px-2 mb-4">
      <vue-data
        v-model="model.name"
        :edit="edit"
        type="text"
        label="User Name"
      />
      </div>
      <div class="w-full lg:w-1/2 px-2 mb-4">
      <vue-data
        v-model="model.email"
        :edit="edit"
        type="email"
        label="User email"
      />
      </div>
      <div class="w-full lg:w-1/2 px-2 mb-4">
      <vue-data
        v-model="model.company"
        :edit="edit"
        type="text"
        label="Company"
      />
      </div>
      <div class="w-full lg:w-1/2 px-2 mb-4">
      <vue-data
       v-model="model.payment_type"
       :value="model.payment_type"
       :edit="edit"
       :options="[
         { label: 'Pay Online - At basket', value: 1},
         { label: 'Pay Offline - On account', value: 2}
        ]"
        label="Payment terms"
        label-key="label"
        value-key="value"
        type="select"
        class="mb-2"
       />
      </div>
      <div class="w-full lg:w-1/2 px-2 mb-4">
      <vue-data
       v-model="model.price_bracket"
       :value="model.price_bracket"
       :edit="edit"
       :options="allVals"
        label="Price Bracket"
        label-key="label"
        value-key="value"
        type="select"
        class="mb-2"
        @change="changePriceBracketSetter(model.price_bracket)"
       />
      </div>

      <div class="w-full">
        <h3 class="text-center">Billing Information</h3>

        <div>
          <vue-data
          v-model="billingInfo.customer_mobile"
          :edit="edit"
          type="text"
          label="Customer Mobile"
          />
        </div>
        <div>
          <vue-data
          v-model="billingInfo.billing_postcode"
          :edit="edit"
          type="text"
          label="PostCode"
          />
        </div>
        <div>
          <vue-data
          v-model="billingInfo.billing_address"
          :edit="edit"
          type="text"
          label="Street Address #1"
          />
        </div>
        <div>
          <vue-data
          v-model="billingInfo.billing_address2"
          :edit="edit"
          type="text"
          label="Street Address #2"
          />
        </div>
        <div>
          <vue-data
          v-model="billingInfo.town"
          :edit="edit"
          type="text"
          label="Town"
          />
        </div>
        <div>
          <vue-data
          v-model="billingInfo.county"
          :edit="edit"
          type="text"
          label="County"
          />
        </div>
        <div>
          <vue-data
          v-model="billingInfo.country"
          :edit="edit"
          type="text"
          label="Country/Region"
          />
        </div>
      </div>
    </template>
    <template #right>
      <label class="font-medium text-sm mb-1">Manage {{ model.name }}'s application</label>
      <vue-data
       v-model="model.approved"
       :value="model.approved"
       :edit="edit"
       true-value="1"
       false-value="0"
       type="checkbox"
       class="mb-2"
       label=" "
       description="check the box to approve user's application"
       />
       <h4 v-if="model.approved">{{ model.name }} has been approved</h4>
       <h4 v-else>{{ model.name }} has not been approved</h4>
    </template>
  </SingleModelLayout>
</template>
<script type="text/javascript">
import { cloneDeep } from 'lodash'
import SingleModelLayout from '@/components/models/SingleModelLayout'
import TitleSummaryCard from '@/components/models/TitleSummaryCard'
import UsesTokens from '@/mixins/UsesTokens'

export default {
  name: 'UsersSingle',

  components: {
    SingleModelLayout,
    TitleSummaryCard
  },

  mixins: [
    UsesTokens
  ],

  data () {
    return {
      loading: false,
      edit: false,
      model: false,
      billingInfo: {
        customer_mobile: '',
        billing_postcode: '',
        billing_address: '',
        billing_address2: '',
        town: '',
        county: '',
        country: ''
      },
      modelOld: false,
      route: {
        apiEndpoint: 'users',
        laravelModel: 'User',
        index: 'users.index'
      }
    }
  },

  computed: {
    value () {
      return this.model.value ?? {}
    },

    accountVals () {
      return [
        { label: 'Pay on account at Trade Price', value: 1 },
        { label: 'Pay on account at Non Trade Price', value: 2 }
      ]
    },

    basketVals () {
      return [
        { label: 'Pay at basket at Trade Price', value: 3 },
        { label: 'Pay at basket at Non Trade Price', value: 4 }
      ]
    },

    allVals () {
      return [
        { label: 'Band 1 - Trade Price', value: 1 },
        { label: 'Band 2 - Trade Price', value: 2 },
        { label: 'Band 3 - Trade Price', value: 3 },
        { label: 'Band 4 - Non Trade Price', value: 4 }
      ]
    },

    bracketOptions () {
      const paymentType = this.model.payment_type
      let options = []
      if (paymentType == null) {
        options.push({ label: 'Set the payment term first', value: null })
      } else if (paymentType === 1) {
        options = [
          { label: 'Pay at basket at Trade Price', value: 3 },
          { label: 'Pay at basket at Non Trade Price', value: 4 }
        ]
      } else if (paymentType === 2) {
        options = [
          { label: 'Pay on account at Trade Price', value: 1 },
          { label: 'Pay on account at Non Trade Price', value: 2 }
        ]
      }
      return options
    },

    content () {
      return this.value && this.value.content ? this.value.content : false
    }
  },

  mounted () {
    this.getModel()
    this.getUsers()
    this.$route.params.media = {
      endpoint: this.route.mediaEndpoint,
      model: this.route.laravelModel
    }
  },

  methods: {
    getModel () {
      this.loading = true
      this.$api.get(`${this.route.apiEndpoint}/${this.$route.params.model}`)
        .then((res) => {
          this.model = res.data
          if (this.model.billingInfo !== 'undefined') {
            this.billingInfo = { ...this.model.billingInfo }
          }
        })
        .catch((err) => {
          this.$store.commit('error/addError', err)
        })
        .finally(() => {
          this.loading = false
        })
    },

    deleteModel () {
      this.loading = true
      this.$api.delete(`${this.route.apiEndpoint}/${this.$route.params.model}`)
        .then((res) => {
          this.$router.push({
            name: this.route.index
          })
        })
        .catch((err) => {
          this.$store.commit('error/addError', err)
        })
    },

    saveModel () {
      this.loading = true
      this.model.billingInfo = { ...this.billingInfo }
      this.getToken().then(() => {
        this.$api.put(`${this.route.apiEndpoint}/${this.$route.params.model}`, this.model)
          .then((res) => {
            this.getModel()
            this.edit = false
          })
          .catch((err) => {
            this.$store.commit('error/addError', err)
          })
          .finally(() => {
            this.loading = false
          })
      })
    },

    getUsers () {
      this.loading = true
      this.$api.get('users')
        .then((res) => {
          this.userOptions = res.data
        })
        .catch((err) => {
          this.$store.commit('error/addError', err)
        })
        .finally(() => {
          this.loading = false
        })
    },

    startEdit () {
      this.modelOld = cloneDeep(this.model)
    },

    discardEdit () {
      this.model = this.modelOld
      this.modelOld = false
      this.edit = false
    },
    changePriceBracketSetter (value) {
      this.model.payment_type = value
    }
  }
}
</script>
