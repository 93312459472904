<template>
  <div class="flex flex-col">
    <div class="w-full bg-gray-100 text-left px-2 py-2">
      <label>Content</label>
    </div>
    <div class="flex flex-col border border-gray-100 border-t-0 space-y-2 py-2">
      <div class="w-full px-2">
        <vue-input-group
          v-model="item.content.latitude"
          prop="latitude"
          name="latitude"
          label="Map Latitude"
        />
      </div>
      <div class="w-full px-2">
        <vue-input-group
          v-model="item.content.longitude"
          prop="longitude"
          name="longitude"
          label="Map Longitude"
        />
      </div>
      <div class="w-full px-2">
        <vue-input-group
          v-model="item.content.zoom"
          prop="zoom"
          name="zoom"
          label="Map Zoom Level"
        />
      </div>
      <div class="w-full px-2">
        <a
          :href="mapPreview"
          target="_blank"
          class="block bg-dark-900 text-white text-center w-full rounded px-2 py-1"
        >
          Preview Location
        </a>
      </div>
    </div>
    <GridItemLayoutConfig :item="item" />
  </div>
</template>
<script>
import GridItemLayoutConfig from '../../GridItemLayoutConfig'

export default {
  components: {
    GridItemLayoutConfig
  },

  props: {
    item: {
      type: Object,
      required: true
    }
  },

  computed: {
    mapPreview () {
      return `https://www.google.com/maps/@${this.item.content.latitude},${this.item.content.longitude},${this.item.content.zoom}z`
    }
  }
}
</script>
