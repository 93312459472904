<template>
  <div class="">
    <vue-data-table
      :cols="[
        {
          header: 'Title',
          value: 'title',
        },
        {
          header: 'Reference Number',
          value: 'ref'
        },
        {
          header: 'Added At',
          value: 'created_at',
          sort: true
        },
        {
          header: 'Last Modified',
          value: 'updated_at',
          sort: true
        },
        {
          header: 'Actions',
          value: 'actions'
        }
      ]"
      path="metadata"
      :request-params="{
        type: 'job'
      }"
    >
      <template #header-left>
        <vue-button
          :icon="['fas', 'plus-circle']"
          primary
          @click="$router.push({ name: routes.new })"
        >
          Add {{ model.singular }}
        </vue-button>
      </template>
      <template v-slot:item.title="row">
        {{ row.value.title }}
      </template>
      <template v-slot:item.ref="row">
        {{ row.value.ref }}
      </template>
      <template v-slot:item.created_at="row">
        {{ $momentDateTimeHuman(row.created_at) }}
      </template>
      <template v-slot:item.updated_at="row">
        {{ $momentDateTimeHuman(row.updated_at) }}
      </template>
      <template v-slot:item.actions="row">
        <vue-link
          :to="{
            name: routes.single,
            params: {
              model: row.id
            }
          }"
          :icon="['fad', 'edit']"
          :aria-label="`Edit ${model.singular}: ${row.name}`"
          dark
          text
          sm
          class="inline-flex cursor-pointer"
        />
      </template>
    </vue-data-table>
  </div>
</template>
<script type="text/javascript">
export default {
  name: 'Jobs',

  data () {
    return {
      model: {
        singular: 'Job',
        plural: 'Jobs'
      },
      routes: {
        index: 'jobs.index',
        new: 'jobs.new',
        single: 'jobs.single'
      }
    }
  }
}
</script>
