<template>
  <vue-card v-bind="$attrs" class="group">
    <div class="flex w-full justify-between items-center mb-2">
      <h2 class="text-2xl font-medium flex-grow">
        <slot name="title" />
      </h2>
      <template v-if="canEdit">
        <vue-button
          v-if="!edit"
          :icon="['fad', 'edit']"
          primary
          text
          aria-label="Edit"
          class="ml-2"
          @click="$emit('edit'); $emit('update:edit', true)"
        />
        <div v-else class="flex">
          <vue-button
            :icon="['fas', 'times']"
            class="ml-1"
            aria-label="Discard Changes"
            error
            text
            @click="$emit('discard')"
          />
          <vue-button
            :icon="['fad', 'save']"
            class="ml-1"
            aria-label="Save Changes"
            success
            @click="$emit('save')"
          />
        </div>
      </template>
    </div>
    <p class="text-sm opacity-50 group-hover:opacity-100 transition-opacity">
      <slot name="subtitle" />
    </p>
  </vue-card>
</template>
<script type="text/javascript">
export default {
  name: 'TitleSummaryCard',

  props: {
    edit: {
      type: Boolean,
      required: false,
      default: () => { return false }
    },

    canEdit: {
      type: Boolean,
      required: false,
      default: () => { return true }
    }
  }
}
</script>
