<template>
  <div class="flex flex-col">
    <div class="w-full bg-gray-100 text-left px-2 py-2">
      <label>Content</label>
    </div>
    <div class="flex flex-col border border-gray-100 border-t-0 space-y-2 py-2">
      <div class="w-full px-2">
        <vue-input-group
          v-model="item.content.type"
          :options="[
            { label: 'Brief', value: 'default' },
            { label: 'Full', value: 'full' }
          ]"
          prop="type"
          name="type"
          label-key="label"
          value-key="value"
          label="Display Type"
          description="'Brief' will truncate the name, position, and e-mail to a single line to prevent wrapping. 'Full' shows all information and larger headers."
          type="select"
        />
      </div>
      <div class="w-full px-2">
        <vue-input-group
          v-model="item.content.name"
          prop="name"
          name="name"
          label="Name"
        />
      </div>
      <div class="w-full px-2">
        <vue-input-group
          v-model="item.content.position"
          prop="position"
          name="position"
          label="Position"
        />
      </div>
      <div class="w-full px-2">
        <vue-input-group
          v-model="item.content.bio"
          prop="bio"
          name="bio"
          label="Biography"
          type="editor"
        />
      </div>
      <div class="w-full px-2">
        <vue-input-group
          v-model="item.content.contact_email"
          prop="contact_email"
          name="contact_email"
          label="E-mail Address"
        />
      </div>
      <div class="w-full px-2">
        <vue-data
          v-model="item.images[`bio_photo_${item.key}`]"
          :value="item.images[`bio_photo_${item.key}`]"
          :edit="true"
          class="mb-2"
          label="Photo"
          type="file"
          accept="image/png, image/jpeg, image/gif"
          @update="syncImage($event, `bio_photo_${item.key}`)"
          @discard="removeImage(`bio_photo_${item.key}`)"
        />
      </div>
      <div class="w-full px-2">
        <vue-input-group
          v-model="item.content[`bio_photo_${item.key}_alt`]"
          :prop="`bio_photo_${item.key}_alt`"
          :name="`bio_photo_${item.key}_alt`"
          label="Photo Alt Text"
        />
      </div>
    </div>
    <GridItemLayoutConfig
      :item="item"
      :hide="[
        'width',
        'aspect',
        'padding'
      ]"
    />
    <GridItemColorsConfig
      :item="item"
      :extra-options="extraColorFields"
      :hide="['bg_image']"
    />
  </div>
</template>
<script>
import GridItemLayoutConfig from '../../GridItemLayoutConfig'
import GridItemColorsConfig from '../../GridItemColorsConfig'
import HasImageUpload from '../../../mixins/HasImageUpload'

export default {
  components: {
    GridItemLayoutConfig,
    GridItemColorsConfig
  },

  mixins: [
    HasImageUpload
  ],

  props: {
    item: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      extraColorFields: {
        header: {
          label: 'Header Color',
          inherit: 'text',
          allowNull: true
        },
        subtitle: {
          label: 'Subtitle Color',
          inherit: 'text',
          allowNull: true
        }
      }
    }
  }
}
</script>
