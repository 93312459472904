export default [
  {
    key: 'header-content',
    component: 'BlockHeaderContent',
    name: 'Header Block',
    render: () => import('./components/elements/header-content/HeaderContentRender.vue'),
    config: () => import('./components/elements/header-content/HeaderContentConfig.vue'),
    init: () => {
      return {
        layout: {
          width: 'col-span-2/3',
          aspect_base: 'aspect-w-1 aspect-h-1',
          aspect_xl: 'aspect-w-2 aspect-h-1'
        },
        before: {
          items: []
        },
        after: {
          items: []
        }
      }
    }
  },
  {
    key: 'category-content',
    component: 'BlockCategoryContent',
    name: 'Category Header Block',
    render: () => import('./components/elements/category-header/CategoryHeaderRender.vue'),
    config: () => import('./components/elements/category-header/CategoryHeaderConfig.vue'),
    init: () => {
      return {
        layout: {
          width: 'col-span-2/3',
          aspect_base: 'aspect-w-1 aspect-h-1',
          aspect_xl: 'aspect-w-2 aspect-h-1'
        },
        colors: {
          background: 'bg-dark-900',
          text: 'text-black',
          opacity: 'bg-opacity-60'
        },
        content: {
          columns: 2
        }
      }
    }
  },
  {
    key: 'text-content',
    component: 'BlockTextContent',
    name: 'Text Block',
    render: () => import('./components/elements/text-content/TextContentRender.vue'),
    config: () => import('./components/elements/text-content/TextContentConfig.vue')
  },
  {
    key: 'image-content',
    component: 'BlockImageContent',
    name: 'Image Block',
    render: () => import('./components/elements/image-content/ImageContentRender.vue'),
    config: () => import('./components/elements/image-content/ImageContentConfig.vue'),
    init: () => {
      return {
        colors: {
          background: 'bg-transparent',
          text: 'text-dark-900'
        }
      }
    }
  },
  {
    key: 'link-content',
    component: 'BlockLinkContent',
    name: 'Link Block',
    render: () => import('./components/elements/link-content/LinkContentRender.vue'),
    config: () => import('./components/elements/link-content/LinkContentConfig.vue'),
    init: () => {
      return {
        layout: {
          width: 'col-span-1/3',
          aspect_base: 'aspect-w-1 aspect-h-1',
          aspect_xl: 'aspect-w-1 aspect-h-1'
        },
        content: {
          title: '',
          link: ''
        }
      }
    }
  },
  {
    key: 'slide-content',
    component: 'BlockSlideContent',
    name: 'Slider',
    render: () => import('./components/elements/slide-content/SlideContentRender.vue'),
    config: () => import('./components/elements/slide-content/SlideContentConfig.vue'),
    init: () => {
      return {
        layout: {
          width: 'col-span-2/3',
          aspect_base: 'aspect-w-2 aspect-h-1',
          aspect_xl: 'aspect-w-2 aspect-h-1'
        },
        content: {
          columnCount: 1,
          arrowType: 'sides',
          items: []
        }
      }
    }
  },
  {
    key: 'columns',
    component: 'BlockColumns',
    name: 'Columns',
    render: () => import('./components/elements/columns/ColumnsRender.vue'),
    config: () => import('./components/elements/columns/ColumnsConfig.vue'),
    init: () => {
      return {
        layout: {
          width: 'col-span-2/3',
          aspect_base: 'aspect-w-2 aspect-h-1',
          aspect_xl: 'aspect-w-2 aspect-w-1'
        },
        content: {
          columnCount: 2,
          columnCountMobile: 1,
          items: []
        }
      }
    }
  },
  {
    key: 'category-list',
    component: 'BlockCategoryList',
    name: 'Products Category List',
    render: () => import('./components/elements/category-list/CategoryListRender.vue'),
    config: () => import('./components/elements/category-list/CategoryListConfig.vue'),
    init: () => {
      return {
        layout: {
          width: 'col-span-full',
          aspect: null,
          aspect_base: null,
          aspect_xl: null
        },
        colors: {
          background: 'bg-transparent',
          text: 'text-black'
        },
        content: {
          columnCount: 6,
          columnCountMobile: 3,
          category: null
        }
      }
    }
  },
  {
    key: 'single-category',
    component: 'BlockSingleCategory',
    name: 'Product Category',
    render: () => import('./components/elements/single-category/SingleCategoryRender.vue'),
    config: () => import('./components/elements/single-category/SingleCategoryConfig.vue'),
    init: () => {
      return {
        layout: {
          aspect_base: 'aspect-w-1 aspect-h-1',
          aspect_xl: 'aspect-w-1 aspect-h-1',
          padding_x: 'px-gap-xs',
          padding_y: 'py-gap-xs',
          position: 'default'
        },
        colors: {
          background: 'bg-dark-800',
          text: 'text-white',
          svg: 'bg-white'
        },
        content: {
          slug: 'example-slug',
          header: 'Example Category',
          image: ''
        }
      }
    }
  },
  {
    key: 'menu-list',
    component: 'BlockMenuList',
    name: 'Menu Items',
    render: () => import('./components/elements/menu-list/MenuListRender.vue'),
    config: () => import('./components/elements/menu-list/MenuListConfig.vue'),
    init: () => {
      return {
        content: {
          header: '',
          menu: null
        },
        layout: {
          aspect_base: 'aspect-w-1 aspect-h-1',
          aspect_xl: 'aspect-w-1 aspect-h-1',
          padding_x: 'px-gap-sm',
          padding_y: 'py-gap-sm'
        }
      }
    }
  },
  {
    key: 'quote-content',
    component: 'BlockQuoteContent',
    name: 'Quote Block',
    render: () => import('./components/elements/quote-content/QuoteContentRender.vue'),
    config: () => import('./components/elements/quote-content/QuoteContentConfig.vue'),
    init: () => {
      return {
        layout: {
          aspect_base: 'aspect-w-1 aspect-h-1',
          aspect_xl: 'aspect-w-1 aspect-h-1',
          padding_x: 'px-gap-sm',
          padding_y: 'py-gap-sm'
        }
      }
    }
  },
  {
    key: 'staff-member',
    component: 'BlockStaffMember',
    name: 'Staff Member',
    render: () => import('./components/elements/staff-member/StaffMemberRender.vue'),
    config: () => import('./components/elements/staff-member/StaffMemberConfig.vue'),
    init: () => {
      return {
        layout: {
          aspect: null,
          aspect_base: null,
          aspect_xl: null
        },
        content: {
          type: 'brief',
          name: 'John Doe',
          position: 'CEO',
          bio: "This is an example of a staff member's biography",
          contact_email: 'john.doe@example.com'
        }
      }
    }
  },
  {
    key: 'map',
    component: 'BlockMap',
    name: 'Map Embed',
    render: () => import('./components/elements/map/MapRender.vue'),
    config: () => import('./components/elements/map/MapConfig.vue'),
    init: () => {
      return {
        layout: {
          width: 'col-span-full',
          aspect_base: 'aspect-w-2 aspect-h-1',
          aspect_xl: 'aspect-w-3 aspect-h-1'
        },
        colors: {
          background: 'bg-transparent',
          text: 'text-black'
        },
        content: {
          latitude: 0.00,
          longitude: 0.00,
          zoom: 15
        }
      }
    }
  },
  {
    key: 'contact-form',
    component: 'BlockContactForm',
    name: 'Contact Form',
    render: () => import('./components/elements/contact-form/ContactFormRender.vue'),
    config: () => import('./components/elements/contact-form/ContactFormConfig.vue'),
    init: () => {
      return {
        layout: {
          width: 'col-span-1/3',
          aspect_base: 'aspect-w-1 aspect-h-1',
          aspect_xl: 'aspect-w-1 aspect-h-1'
        },
        colors: {
          background: 'bg-secondary',
          text: 'text-white'
        },
        content: {
          name: true,
          email: true,
          company: false,
          phone: false,
          message: true,
          file: false,
          columns: 1
        }
      }
    }
  },
  {
    key: 'spacer',
    component: 'BlockSpacer',
    name: 'Spacer',
    render: () => import('./components/elements/spacer/SpacerRender.vue'),
    config: () => import('./components/elements/spacer/SpacerConfig.vue'),
    init: () => {
      return {
        layout: {
          width: 'col-span-full',
          aspect_base: 'aspect-w-1 aspect-h-1',
          aspect_xl: 'aspect-w-1 aspect-h-1'
        },
        colors: {
          background: 'bg-transparent'
        },
        content: {
          spacing: '2rem'
        }
      }
    }
  },
  {
    key: 'child-page',
    component: 'BlockChildPage',
    name: 'Child Page Content',
    render: () => import('./components/elements/child-page/ChildPageRender.vue'),
    config: null,
    init: () => {
      return {
        layout: {
          width: 'col-span-full',
          aspect: null,
          aspect_base: null,
          aspect_xl: null
        },
        colors: {
          background: 'bg-transparent',
          text: 'text-black'
        }
      }
    }
  },
  {
    key: 'template',
    component: 'BlockTemplate',
    name: 'Template Block',
    render: () => import('./components/elements/template/TemplateRender.vue'),
    config: () => import('./components/elements/template/TemplateConfig.vue'),
    init: () => {
      return {
        content: {
          template: null
        }
      }
    }
  }
]
