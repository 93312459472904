<template>
  <RootBlock :item="item">
    <div class="flex flex-col justify-between h-full space-y-4">
      <vue-markdown
        v-if="content && content.content"
        :source="content.content"
      />
      <div v-if="content && (content.name || content.company)" :class="`flex flex-col ${colors ? (colors.highlight || colors.text || 'text-white') : 'text-white'}`">
        <span v-if="content.name" class="text-h2 uppercase">{{ content.name }}</span>
        <span v-if="content.company" class="text-h2 uppercase">{{ content.company }}</span>
      </div>
    </div>
  </RootBlock>
</template>
<script>
import RootBlock from '../RootBlock'

export default {
  friendlyName: 'Quote Block',

  components: {
    RootBlock
  },

  props: {
    item: {
      type: Object,
      required: true
    }
  },

  computed: {
    content () {
      return this.item.content || false
    },

    layout () {
      return this.item.layout || false
    },

    colors () {
      return this.item.colors || false
    }
  }
}
</script>
