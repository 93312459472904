var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-col"},[_vm._m(0),_c('div',{staticClass:"flex flex-col border border-gray-100 border-t-0 space-y-2 py-2"},[_c('div',{staticClass:"w-full px-2"},[_c('vue-button',{staticClass:"w-full",attrs:{"sm":""},on:{"click":_vm.edit}},[_vm._v(" Edit Inner Blocks ")])],1),_c('div',{staticClass:"w-full px-2"},[_c('vue-input-group',{attrs:{"prop":"arrow_type","name":"arrow_type","options":[
          {
            label: 'Sides',
            value: 'side'
          },
          {
            label: 'Bottom Left',
            value: 'bottom_left'
          },
          {
            label: 'Bottom Right',
            value: 'bottom_right'
          }
        ],"labelKey":"label","valueKey":"value","type":"select","label":"Arrows Location"},model:{value:(_vm.item.content.arrowType),callback:function ($$v) {_vm.$set(_vm.item.content, "arrowType", $$v)},expression:"item.content.arrowType"}})],1),_c('div',{staticClass:"w-full px-2"},[_c('vue-input-group',{attrs:{"prop":"autoplay_delay","name":"autoplay_delay","type":"number","step":"0.5","min":"0","label":"Autoplay Duration","description":"How long in seconds to show each slide before moving to the next. Set to 0 to disable autoplay."},model:{value:(_vm.item.content.autoplayDelay),callback:function ($$v) {_vm.$set(_vm.item.content, "autoplayDelay", $$v)},expression:"item.content.autoplayDelay"}})],1),_c('div',{staticClass:"w-full px-2"},[_c('vue-input-group',{attrs:{"prop":"button.label","name":"button.label","label":"Button Label","description":"Leave blank to hide the button."},model:{value:(_vm.item.content.button.label),callback:function ($$v) {_vm.$set(_vm.item.content.button, "label", $$v)},expression:"item.content.button.label"}}),_c('vue-input-group',{attrs:{"prop":"button.url","name":"button.url","label":"Button URL","description":"The URL to link to. For relative links, use a slash prefix (eg. /about). For external links, use http prefix (eg. https://google.com)"},model:{value:(_vm.item.content.button.url),callback:function ($$v) {_vm.$set(_vm.item.content.button, "url", $$v)},expression:"item.content.button.url"}}),_c('vue-input-group',{attrs:{"options":_vm.buttonLayoutOptions,"prop":"button","name":"button","type":"select","label-key":"label","value-key":"value","label":"Button Side","description":"Select the side in which to display the button"},model:{value:(_vm.item.layout.button),callback:function ($$v) {_vm.$set(_vm.item.layout, "button", $$v)},expression:"item.layout.button"}})],1)]),_c('GridItemLayoutConfig',{attrs:{"item":_vm.item}}),_c('GridItemColorsConfig',{attrs:{"item":_vm.item}})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-full bg-gray-100 text-left px-2 py-2"},[_c('label',[_vm._v("Content")])])}]

export { render, staticRenderFns }