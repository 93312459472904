import { find } from 'lodash'
import axios from 'axios'
import ItemElements from '../elements.js'

const apiUrl = process.env.VUE_APP_API_BASE_URL + '/api/'

export default {
  namespaced: true,

  state: {
    templates: [],
    categories: [],
    menus: [],
    elements: ItemElements,
    editingInner: false,
    editorHasChildContent: false
  },

  getters: {
    getElements: (state) => {
      return state.elements
    },

    getElementByKey: (state) => (key) => {
      return find(state.elements, { key }) || false
    },

    getInner: (state) => {
      return state.editingInner || null
    },

    getTemplateByKey: (state) => (key) => {
      return find(state.templates, { key }) || false
    },

    getCategoryById: (state) => (id) => {
      return find(state.categories, { id }) || false
    },

    getMenuById: (state) => (id) => {
      return find(state.menus, { id }) || false
    }
  },

  mutations: {
    update (state, { key, value }) {
      state[key] = value
    },

    defineElements (state, data) {
      state.elements = data
    },

    editInner (state, item) {
      state.editingInner = item
    },

    clearInner (state) {
      state.editingInner = false
    }
  },

  actions: {
    async updateTemplates ({ commit }) {
      const { data: templates } = await axios.get(apiUrl + 'metadata', {
        params: {
          type: 'block_template',
          withImages: true
        }
      })
      commit('update', { key: 'templates', value: templates.data })
    },

    async updateCategories ({ commit }) {
      const { data: categories } = await axios.get(apiUrl + 'sets', {
        params: {
          type: 'PurpleMountain\\PmmEcommerce\\Models\\Product',
          withImages: true
        }
      })
      commit('update', { key: 'categories', value: categories.data })
    },

    async updateMenus ({ commit }) {
      const { data: menus } = await axios.get(apiUrl + 'menus', {
        params: {
          with: ['items'],
          withImages: true
        }
      })
      commit('update', { key: 'menus', value: menus.data })
    }
  }
}
