<template>
  <div v-if="show" class="fixed inset-0 flex flex-col items-center justify-center text-black bg-gray-100 space-y-4 z-40">
    <font-awesome-icon :icon="['fal', 'spinner-third']" size="2x" spin />
    <span class="text-2xl">Loading...</span>
  </div>
</template>
<script>
export default {
  computed: {
    show () {
      return this.$store.state.loading.loading
    }
  }
}
</script>
