import Vue from 'vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faPlusCircle, faTimes, faMinus, faPlus, faEdit as faEditSolid, faArrowsAltV, faEllipsisV, faChevronUp, faChevronDown, faBold, faItalic, faUnderline, faHeading, faLink, faListOl, faListUl as faListUlSolid, faRemoveFormat, faFileExport, faFileImport, faPaperPlane, faUniversity } from '@fortawesome/pro-solid-svg-icons'
import { fal } from '@fortawesome/pro-light-svg-icons'
import { faSortAmountUp, faSortAmountDown, faTrash, faCopy, faFile, faCheckCircle, faTimesCircle, faNewspaper, faListUl, faUser, faBriefcase, faGlobe, faCubes, faLayerGroup, faSortAlt, faUserCircle, faSync, faSave, faArrowCircleLeft, faEdit, faTachometerAlt, faEnvelope, faSyncAlt, faAlbumCollection, faSpinnerThird, faUsers, faUsersCrown, faUserLock, faBagsShopping, faFileInvoiceDollar, faShippingFast, faUserPlus, faHandHoldingBox, faBoxFull, faTags } from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { fab as faIconSet } from '@fortawesome/free-brands-svg-icons'

// Add icons as desired. See documentation here: https://github.com/FortAwesome/vue-fontawesome
library.add(
  fal,
  faChevronUp,
  faChevronDown,
  faSortAmountUp,
  faSortAmountDown,
  faSortAlt,
  faUserCircle,
  faArrowCircleLeft,
  faEnvelope,
  faTachometerAlt,
  faSync,
  faSyncAlt,
  faEdit,
  faEditSolid,
  faPlusCircle,
  faTimes,
  faCheckCircle,
  faTimesCircle,
  faSave,
  faMinus,
  faPlus,
  faTrash,
  faEllipsisV,
  faFile,
  faLayerGroup,
  faArrowsAltV,
  faGlobe,
  faCubes,
  faNewspaper,
  faBriefcase,
  faBold,
  faItalic,
  faUnderline,
  faHeading,
  faLink,
  faListOl,
  faListUl,
  faListUlSolid,
  faRemoveFormat,
  faUser,
  faCopy,
  faAlbumCollection,
  faSpinnerThird,
  faFileExport,
  faFileImport,
  faPaperPlane,
  faUsers,
  faUserLock,
  faUsersCrown,
  faBagsShopping,
  faFileInvoiceDollar,
  faShippingFast,
  faUserPlus,
  faHandHoldingBox,
  faBoxFull,
  faIconSet,
  faUniversity,
  faTags
)

Vue.component('font-awesome-icon', FontAwesomeIcon)
