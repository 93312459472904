import axios from 'axios'

// initial state
const state = {
  products: [],
  product: {}
}

// getters
const getters = {
  getProducts (state) {
    return state.products
  },

  getSingleProduct (state) {
    return state.product
  }
}

// actions
const actions = {
  get_products ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get(`${process.env.VUE_APP_API_BASE_URL}/api/products`, {
        params: {
          with: ['related', 'variants'],
          withImages: true
        }
      })
        .then(response => {
          // commit('setProducts', response.data.data)
          // console.log(response.data)
          commit('setProducts', response.data)
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  get_single_product ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.get(`${process.env.VUE_APP_API_BASE_URL}/api/products/${payload}`, {
        params: {
          with: 'related',
          withImages: true
        }
      })
        .then(response => {
          commit('setProduct', response.data)
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }
}

// mutations
const mutations = {
  setProducts (state, products) {
    state.products = products
  },

  setProduct (state, product) {
    state.product = product
  },

  popAttachedProduct (state, payload) {
    const index = state.products.findIndex(product => product === payload)
    state.products.splice(index, 1)
  }

}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
