<template>
  <div class="flex flex-col">
    <div class="w-full bg-gray-100 text-left px-2 py-2">
      <label>Content</label>
    </div>
    <div class="flex flex-col border border-gray-100 border-t-0 space-y-2 py-2">
      <div class="w-full px-2">
        <vue-input-group
          v-model="item.content.template"
          :options="templates"
          name="template"
          prop="template"
          label="Template Block"
          labelKey="value.name"
          valueKey="key"
          description="Choose the block template you want to load"
          type="select"
        />
      </div>
      <div class="w-full px-2">
        <vue-button
          primary
          outline
          sm
          class="flex justify-center w-full"
          @click="$store.dispatch('editor/updateTemplates')"
        >
          Refresh templates
        </vue-button>
      </div>
      <div v-if="selectedTemplate" class="w-full px-2">
        <vue-button
          primary
          sm
          class="flex justify-center w-full"
          @click="editTemplate(selectedTemplate.id)"
        >
          Edit template
        </vue-button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    item: {
      type: Object,
      required: true
    }
  },

  computed: {
    templates () {
      return this.$store.state.editor.templates || []
    },

    selectedTemplate () {
      return this.item.content.template ? this.$store.getters['editor/getTemplateByKey'](this.item.content.template) : false
    }
  },

  methods: {
    editTemplate (model) {
      const route = this.$router.resolve({
        name: 'templates.single',
        params: { model }
      })
      window.open(route.href, '_blank')
    }
  }
}
</script>
