var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('vue-data-table',{attrs:{"cols":[
      {
        header: 'ID',
        value: 'id',
      },
      {
        header: 'Name',
        value: 'name'
      },
      {
        header: 'Primary Menu?',
        value: 'primary'
      },
      {
        header: 'Added At',
        value: 'created_at',
        sort: true
      },
      {
        header: 'Last Modified',
        value: 'updated_at',
        sort: true
      },
      {
        header: 'Actions',
        value: 'actions'
      }
    ],"path":"menus"},scopedSlots:_vm._u([{key:"header-left",fn:function(){return [_c('vue-button',{attrs:{"icon":['fas', 'plus-circle']},on:{"click":function($event){return _vm.$router.push({ name: _vm.routes.new })}}},[_vm._v(" Add "+_vm._s(_vm.model.singular)+" ")])]},proxy:true},{key:"item.primary",fn:function(row){return [(row.primary)?_c('font-awesome-icon',{staticClass:"text-green-700",attrs:{"icon":['fad', 'check-circle'],"size":"lg","fixed-width":""}}):_c('font-awesome-icon',{staticClass:"text-red-700",attrs:{"icon":['fad', 'times-circle'],"size":"lg","fixed-width":""}})]}},{key:"item.created_at",fn:function(row){return [_vm._v(" "+_vm._s(_vm.$momentDateTimeHuman(row.created_at))+" ")]}},{key:"item.updated_at",fn:function(row){return [_vm._v(" "+_vm._s(_vm.$momentDateTimeHuman(row.updated_at))+" ")]}},{key:"item.actions",fn:function(row){return [_c('vue-link',{staticClass:"inline-flex cursor-pointer",attrs:{"to":{
          name: _vm.routes.single,
          params: {
            model: row.id
          }
        },"icon":['fad', 'edit'],"aria-label":("Edit Page: " + (row.name)),"text":"","sm":""}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }