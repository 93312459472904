<template>
    <NewModelLayout>
    <template #title>
      <h1 class="text-2xl">
        Add a New User
      </h1>
    </template>
    <template #form>
      <vue-form
        :form="form"
        :errors.sync="error.errors"
        ref="form"
        class="bg-gray-100 shadow rounded-sm"
      >
        <div class="bg-white px-6 py-4">
          <div class="flex flex-wrap w-row-offset-sm -mx-2">
            <div class="w-full lg:w-1/2 px-2 mb-4">
              <vue-input-group
                v-model="form.first_name"
                :errors="error.errors.first_name"
                name="first_name"
                prop="last_name"
                label="First Name"
                description="The user's first name."
                autocomplete='false'
                required
              />
            </div>
            <div class="w-full lg:w-1/2 px-2 mb-4">
              <vue-input-group
                v-model="form.last_name"
                :errors="error.errors.name"
                name="last_name"
                prop="last_name"
                label="Last Name"
                description="The user's last name."
                autocomplete='false'
                required
              />
            </div>
          </div>
          <div class="w-full px-2 mb-4">
              <vue-input-group
                v-model="form.email"
                :errors="error.errors.email"
                name="email"
                type="email"
                prop="email"
                label="Email"
                description="The user's email address"
                autocomplete='false'
                required
              />
          </div>
          <div class="w-full px-2 mb-4">
              <vue-input-group
                v-model="form.company"
                :errors="error.errors.company"
                name="company"
                type="text"
                prop="company"
                label="Company"
                description="The company that this user is from"
                autocomplete='false'
                required
              />
          </div>
          <div class="flex flex-wrap w-row-offset-sm -mx-2">
            <div class="w-full lg:w-1/2 px-2 mb-4">
              <vue-input-group
                v-model="form.password"
                :errors="error.errors.password"
                name="password"
                type="password"
                prop="password"
                label="Password"
                description="The user's password."
                autocomplete='false'
                required
              />
            </div>
            <div class="w-full lg:w-1/2 px-2 mb-4">
              <vue-input-group
                v-model="form.password_confirmation"
                :errors="error.errors.password_confirmation"
                name="password_confirmation"
                type="password"
                prop="password_confirmation"
                label="Confirm Password"
                description="Re-enter the user's password."
                autocomplete='false'
                required
              />
            </div>
          </div>
        <div class="flex justify-between items-center w-full px-4 py-4">
          <vue-button
            text
            error
            @click="resetForm"
          >
            Reset
          </vue-button>
          <vue-button
            :icon="['fad', 'save']"
             :loading="loading"
            type="submit"
            success
            @click="submitForm"
          >
            Add User
          </vue-button>
        </div>
        </div>
      </vue-form>
    </template>
  </NewModelLayout>
</template>

<script>
import { cloneDeep } from 'lodash'
import UsesTokens from '@/mixins/UsesTokens'
import NewModelLayout from '@/components/models/NewModelLayout'

export default {

  name: 'UsersNew',

  mixins: [UsesTokens],

  components: {
    NewModelLayout
  },

  data () {
    return {
      loading: false,
      form: {},
      defaults: {
        first_name: '',
        last_name: '',
        email: '',
        apply_trade: true,
        company: '',
        password: '',
        password_confirmation: ''
      },
      error: {
        errors: {}
      }
    }
  },

  mounted () {
    this.initForm()
  },

  methods: {
    initForm () {
      this.form = cloneDeep(this.defaults)
    },

    resetForm () {
      this.initForm()
    },

    submitForm () {
      this.$refs.form.validate()

      const headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Content-From': 'admin/create'
      }

      this.$api.post('users', this.form, { headers })
        .then((res) => {
          this.$router.push({
            name: 'users.single',
            params: {
              model: res.data.id
            }
          })
        }).catch((err) => {
          this.$store.commit('error/addError', err)
        })
    }
  }
}
</script>

<style>

</style>
