<template>
  <div class="flex flex-col space-y-4">
    <div v-if="$route.name === 'pages.single'" class="space-y-2">
      <h2 class="text-2xl text-right">
        Page Options
      </h2>
      <div class="bg-white rounded px-2 py-2">
        <div class="flex flex-col space-y-2">
          <div class="px-1">
            <vue-input-group
              v-model="data.showHeader"
              name="show_header"
              prop="show_header"
              label="Show Page Header?"
              type="checkbox"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="space-y-2">
      <h2 class="text-2xl text-right">
        Item Options
      </h2>
      <div class="bg-white rounded px-2 py-2">
        <div v-if="item" class="flex flex-col space-y-2">
          <div v-if="elements && item && item.element" class="px-1">
            <label for="itemElement" class="text-sm font-medium">Block</label>
            <vue-select
              v-model="item.element"
              :options="elements"
              id="itemElement"
              labelKey="name"
              valueKey="key"
            />
          </div>
          <template v-if="item && config">
            <component
              :is="config"
              :item.sync="item"
            />
          </template>
        </div>
        <div v-else class="text-sm text-gray-600 py-2 px-1">
          Select a block from the editor on the left and click 'Edit' to modify it here
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { find, isEmpty } from 'lodash'

export default {
  name: 'GridLayoutConfig',

  props: {
    data: {
      type: Object,
      required: true
    },

    index: {
      type: Number,
      required: false,
      default: () => { return null }
    }
  },

  computed: {
    validItem () {
      return this.index !== null && !isEmpty(this.data.items[this.index])
    },

    item: {
      get () {
        return this.validItem ? this.data.items[this.index] : null
      },
      set (value) {
        this.data.items[this.index] = value
      }
    },

    element () {
      return this.item ? find(this.elements, { key: this.item.element }) || null : null
    },

    elements () {
      return this.$store.getters['editor/getElements']
    },

    config () {
      return this.element.config || null
    }
  },

  watch: {
    item: {
      deep: true,
      handler () {
        this.item.component = this.element.component
      }
    }
  },

  methods: {
    add (key, value) {
      if (typeof this.data[key] === 'undefined') {
        this.data[key] = []
      }
      this.data[key].push(value)
    },

    remove (key) {
      if (this.data[key].length > 1) {
        this.data[key].splice(-1, 1)
      }
    }
  }
}
</script>
