import Vue from 'vue'
import VueRouter from 'vue-router'
import middleware from '@/router/middleware'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    beforeEnter: middleware.auth,
    component: () => import(/* webpackChunkName: "home-view" */ '@/views/Home.vue')
  },
  {
    path: '/login',
    name: 'login',
    beforeEnter: middleware.guest,
    component: () => import(/* webpackChunkName: "login-view" */ '@/views/Login.vue')
  },
  {
    path: '/footer',
    name: 'footer',
    beforeEnter: middleware.auth,
    component: () => import(/* webpackChunkName: "footer-view" */ '@/views/Footer.vue')
  },
  {
    path: '/posts',
    name: 'posts.index',
    beforeEnter: middleware.auth,
    component: () => import(/* webpackChunkName: "posts-index" */ '@/views/posts/PostsIndex.vue')
  },
  {
    path: '/posts/new',
    name: 'posts.new',
    beforeEnter: middleware.auth,
    component: () => import(/* webpackChunkName: "pages-new" */ '@/views/posts/PostsNew.vue')
  },
  {
    path: '/posts/:model',
    name: 'posts.single',
    beforeEnter: middleware.auth,
    component: () => import(/* webpackChunkName: "posts-single" */ '@/views/posts/PostsSingle.vue')
  },
  {
    path: '/pages',
    name: 'pages.index',
    beforeEnter: middleware.auth,
    component: () => import(/* webpackChunkName: "pages-index" */ '@/views/pages/PagesIndex.vue')
  },
  {
    path: '/pages/new',
    name: 'pages.new',
    beforeEnter: middleware.auth,
    component: () => import(/* webpackChunkName: "pages-new" */ '@/views/pages/PagesNew.vue')
  },
  {
    path: '/pages/:model',
    name: 'pages.single',
    beforeEnter: middleware.auth,
    component: () => import(/* webpackChunkName: "pages-single" */ '@/views/pages/PagesSingle.vue')
  },
  {
    path: '/sets',
    name: 'sets.index',
    beforeEnter: middleware.auth,
    component: () => import(/* webpackChunkName: "sets-index" */ '@/views/sets/SetsIndex.vue')
  },
  {
    path: '/sets/new',
    name: 'sets.new',
    beforeEnter: middleware.auth,
    component: () => import(/* webpackChunkName: "sets-new" */ '@/views/sets/SetsNew.vue')
  },
  {
    path: '/sets/:model',
    name: 'sets.single',
    beforeEnter: middleware.auth,
    component: () => import(/* webpackChunkName: "sets-single" */ '@/views/sets/SetsSingle.vue')
  },
  {
    path: '/jobs',
    name: 'jobs.index',
    beforeEnter: middleware.auth,
    component: () => import(/* webpackChunkName: "jobs-index" */ '@/views/jobs/JobsIndex.vue')
  },
  {
    path: '/jobs/new',
    name: 'jobs.new',
    beforeEnter: middleware.auth,
    component: () => import(/* webpackChunkName: "jobs-new" */ '@/views/jobs/JobsNew.vue')
  },
  {
    path: '/jobs/:model',
    name: 'jobs.single',
    beforeEnter: middleware.auth,
    component: () => import(/* webpackChunkName: "jobs-single" */ '@/views/jobs/JobsSingle.vue')
  },
  {
    path: '/menus',
    name: 'menus.index',
    beforeEnter: middleware.auth,
    component: () => import(/* webpackChunkName: "menus-index" */ '@/views/menus/MenusIndex.vue')
  },
  {
    path: '/menus/new',
    name: 'menus.new',
    beforeEnter: middleware.auth,
    component: () => import(/* webpackChunkName: "menus-new" */ '@/views/menus/MenusNew.vue')
  },
  {
    path: '/menus/:model',
    name: 'menus.single',
    beforeEnter: middleware.auth,
    component: () => import(/* webpackChunkName: "menus-single" */ '@/views/menus/MenusSingle.vue')
  },
  {
    path: '/templates',
    name: 'templates.index',
    beforeEnter: middleware.auth,
    component: () => import(/* webpackChunkName: "templates-index" */ '@/views/templates/TemplatesIndex.vue')
  },
  {
    path: '/templates/new',
    name: 'templates.new',
    beforeEnter: middleware.auth,
    component: () => import(/* webpackChunkName: "templates-new" */ '@/views/templates/TemplatesNew.vue')
  },
  {
    path: '/templates/:model',
    name: 'templates.single',
    beforeEnter: middleware.auth,
    component: () => import(/* webpackChunkName: "templates-single" */ '@/views/templates/TemplatesSingle.vue')
  },
  {
    path: '/products',
    name: 'products.index',
    beforeEnter: middleware.auth,
    component: () =>
      import(/* webpackChunkName: 'products-index' */ '@/views/products/ProductsIndex.vue')
  },
  {
    path: '/products/new',
    name: 'products.new',
    beforeEnter: middleware.auth,
    component: () =>
      import(/* webpackChunkName: 'products-new' */ '@/views/products/ProductsNew.vue')
  },
  {
    path: '/products/:model',
    name: 'products.single',
    beforeEnter: middleware.auth,
    component: () =>
      import(/* webpackChunkName: 'products-single' */ '@/views/products/ProductsSingle.vue')
  },
  {
    path: '/products_import',
    name: 'products.import',
    beforeEnter: middleware.auth,
    component: () =>
      import(/* webpackChunkName: 'products-import' */ '@/views/products/ProductsImport.vue')
  },
  {
    path: '/users',
    name: 'users.index',
    beforeEnter: middleware.auth,
    component: () => import(/* webpackChunkName: 'users-index' */ '@/views/users/UsersIndex.vue')
  },
  {
    path: '/admin-users',
    name: 'users.admin',
    beforeEnter: middleware.auth,
    component: () => import(/* webpackChunkName: 'users-admin' */ '@/views/users/UsersAdmin.vue')
  },
  {
    path: '/users/:model',
    name: 'users.single',
    beforeEnter: middleware.auth,
    component: () => import(/* webpackChunkName: 'users-single' */ '@/views/users/UsersSingle.vue')
  },
  {
    path: '/unApprovedUsers',
    name: 'users.unapproved',
    beforeEnter: middleware.auth,
    component: () => import(/* webpackChunkName: 'users-unapproved' */ '@/views/users/UsersUnapproved.vue')
  },
  {
    path: '/add-user',
    name: 'users.new',
    beforeEnter: middleware.auth,
    component: () => import(/* webpackChunkName: 'users-new' */ '@/views/users/UsersNew.vue')
  },
  {
    path: '/orders',
    name: 'orders.index',
    beforeEnter: middleware.auth,
    component: () => import(/* webpackChunkName: 'orders-index' */ '@/views/orders/OrdersIndex.vue')
  },
  {
    path: '/orders/:model',
    name: 'orders.single',
    beforeEnter: middleware.auth,
    component: () => import(/* webpackChunkName: 'orders-single' */ '@/views/orders/OrdersSingle.vue')
  },
  {
    path: '/orders-check',
    name: 'orders.verify',
    beforeEnter: middleware.auth,
    component: () => import(/* webpackChunkName: 'orders-verify' */ '@/views/orders/OrdersAdminCheck.vue')
  },
  {
    path: '/payments',
    name: 'payments.index',
    beforeEnter: middleware.auth,
    component: () => import(/* webpackChunkName: 'payments-index' */ '@/views/payments/PaymentsIndex.vue')
  },
  {
    path: '/payments/stripe',
    name: 'payments.stripe',
    beforeEnter: middleware.auth,
    component: () => import(/* webpackChunkName: 'payments-index' */ '@/views/payments/PaymentsStripe.vue')
  },
  {
    path: '/payments/paypal',
    name: 'payments.paypal',
    beforeEnter: middleware.auth,
    component: () => import(/* webpackChunkName: 'payments-index' */ '@/views/payments/PaymentsPaypal.vue')
  },
  {
    path: '/payments/bacs',
    name: 'payments.bacs',
    beforeEnter: middleware.auth,
    component: () => import(/* webpackChunkName: 'payments-index' */ '@/views/payments/PaymentsBacs.vue')
  },
  {
    path: '/shipping',
    name: 'shipping.index',
    beforeEnter: middleware.auth,
    component: () => import(/* webpackChunkName: 'shipping-index' */ '@/views/shipping/ShippingIndex.vue')
  },
  {
    path: '/pricing',
    name: 'pricing.index',
    beforeEnter: middleware.auth,
    component: () => import(/* webpackChunkName: 'shipping-index' */ '@/views/pricing/PricingIndex.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
