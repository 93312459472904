<template>
  <SingleModelLayout
    :model.sync="model"
    :route-back="{
      name: route.index
    }"
    @refresh-model="getModel"
    @delete-model="deleteModel"
  >
    <template #title>
      <TitleSummaryCard
        class="mb-4"
        :edit.sync="edit"
        @edit="startEdit"
        @save="saveModel"
        @discard="discardEdit"
      >
        <template #title>
          <vue-data
            v-model="model.value.title"
            :value="model.value.title"
            :edit="edit"
            :border="false"
          />
        </template>
        <template #subtitle class="opacity-50">
          {{ model.id }}
        </template>
      </TitleSummaryCard>
    </template>
    <template #left>
      <seo-section
        :model="model"
        :edit="edit"
        :slug="model.id"
        url-prefix="/about/jobs/"
      />
    </template>
    <template #main>
      <vue-data
        v-model="model.value.content"
        :edit="edit"
        type="editor"
        label="Content"
      />
    </template>
    <template #right>
      <vue-data
        v-model="model.value.ref"
        :value="model.value.ref"
        :edit="edit"
        class="mb-2"
        label="Reference Number"
      />
      <vue-data
        v-model="model.value.job.openings"
        :value="model.value.job.openings"
        :edit="edit"
        type="number"
        class="mb-2"
        label="Available Positions"
        description="Set to 0 to show this job as 'unavaliable', or set to -1 to hide this field and allow unlimited applications."
        min="-1"
        step="1"
      />
      <vue-data
        v-model="model.value.job.type"
        :value="model.value.job.type"
        :edit="edit"
        :options="[
          'Full Time',
          'Part Time',
          'Temporary',
          'Seasonal'
        ]"
        type="select"
        label="Position Type"
        class="mb-2"
      />
      <vue-data
        v-model="model.value.job.duration"
        :value="model.value.job.duration"
        :edit="edit"
        :options="[
          'Permanent',
          'Temporary'
        ]"
        type="select"
        label="Position Duration"
        class="mb-2"
      />
      <vue-data
        v-model="model.value.job.location"
        :value="model.value.job.location"
        :edit="edit"
        label="Location"
        class="mb-2"
      />
      <vue-data
        v-model="model.value.job.department"
        :value="model.value.job.department"
        :edit="edit"
        label="Department"
        class="mb-2"
      />
      <vue-data
        v-model="model.value.job.benefits"
        :value="model.value.job.benefits"
        :edit="edit"
        label="Benefits"
        class="mb-2"
      />
      <vue-data
        v-model="model.value.job.hours"
        :value="model.value.job.hours"
        :edit="edit"
        label="Working Hours"
        class="mb-2"
      />
      <vue-data
        v-model="model.value.job.pay_rate"
        :value="model.value.job.pay_rate"
        :edit="edit"
        type="number"
        label="Pay Rate"
        min="0"
        step="0.01"
        prepend="£"
        class="mb-2"
      />
      <vue-data
        v-model="model.value.job.pay_duration"
        :value="model.value.job.pay_duration"
        :edit="edit"
        :options="[
          { label: 'Hour', value: 'hour' },
          { label: 'Day', value: 'day' },
          { label: 'Week', value: 'week' },
          { label: 'Fortnite', value: 'two_week' },
          { label: 'Month', value: 'month' },
          { label: 'Biannual', value: 'year_half' },
          { label: 'Annual', value: 'year' }
        ]"
        label-key="label"
        value-key="value"
        type="select"
        label="Pay Unit"
        description="Hidden if Salary is more than £0"
        class="mb-2"
      />
      <vue-data
        v-model="model.value.job.salary"
        :value="model.value.job.salary"
        :edit="edit"
        type="number"
        label="Salary"
        min="0"
        step="0.01"
        prepend="£"
        class="mb-2"
      />
    </template>
  </SingleModelLayout>
</template>
<script type="text/javascript">
import { cloneDeep } from 'lodash'
import SingleModelLayout from '@/components/models/SingleModelLayout'
import TitleSummaryCard from '@/components/models/TitleSummaryCard'
import UsesTokens from '@/mixins/UsesTokens'

export default {
  name: 'JobsSingle',

  components: {
    SingleModelLayout,
    TitleSummaryCard
  },

  mixins: [
    UsesTokens
  ],

  data () {
    return {
      loading: false,
      edit: false,
      model: false,
      modelOld: false,
      files: [],
      route: {
        apiEndpoint: 'metadata',
        mediaEndpoint: 'metadata',
        laravelModel: 'Metadata',
        index: 'jobs.index'
      }
    }
  },

  computed: {
    value () {
      return this.model.value ?? {}
    },

    content () {
      return this.value && this.value.content ? this.value.content : false
    }
  },

  mounted () {
    this.getModel()
    this.getUsers()
    this.$route.params.media = {
      endpoint: this.route.mediaEndpoint,
      model: this.route.laravelModel
    }
  },

  methods: {
    getModel () {
      this.loading = true
      this.$api.get(`${this.route.apiEndpoint}/${this.$route.params.model}`, {
        params: {
          withImages: true
        }
      })
        .then((res) => {
          this.model = res.data
        })
        .catch((err) => {
          this.$store.commit('error/addError', err)
        })
        .finally(() => {
          this.loading = false
        })
    },

    deleteModel () {
      this.loading = true
      this.$api.delete(`${this.route.apiEndpoint}/${this.$route.params.model}`)
        .then((res) => {
          this.$router.push({
            name: this.route.index
          })
        })
        .catch((err) => {
          this.$store.commit('error/addError', err)
        })
    },

    saveModel () {
      this.loading = true
      this.getToken().then(() => {
        this.saveFiles().then(() => {
          this.$api.put(`${this.route.apiEndpoint}/${this.$route.params.model}`, this.model)
            .then((res) => {
              this.getModel()
              this.edit = false
            })
            .catch((err) => {
              this.$store.commit('error/addError', err)
            })
            .finally(() => {
              this.loading = false
            })
        })
          .then(() => {
            this.files = []
          })
      })
    },

    getUsers () {
      this.loading = true
      this.$api.get('users')
        .then((res) => {
          this.userOptions = res.data
        })
        .catch((err) => {
          this.$store.commit('error/addError', err)
        })
        .finally(() => {
          this.loading = false
        })
    },

    syncImage (data, collection) {
      this.files.push({
        collection,
        data
      })
    },

    removeImage () {
      this.files = false
    },

    async saveFiles () {
      if (this.files) {
        this.files.forEach((file) => {
          const formData = new FormData()
          formData.append('file', file.data)
          formData.append('collection', file.collection)
          formData.append('model', this.route.laravelModel)
          this.$api.post(`media/upload/${this.route.mediaEndpoint}/${this.$route.params.model}`, formData, {
            'Content-Type': 'multipart/form-data'
          })
            .catch((err) => {
              this.$store.commit('error/addError', err)
            })
        })
      }
      return this.files
    },

    startEdit () {
      this.modelOld = cloneDeep(this.model)
    },

    discardEdit () {
      this.model = this.modelOld
      this.modelOld = false
      this.edit = false
    }
  }
}
</script>
