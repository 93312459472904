<template>
  <RootBlock :item="item">
    <div v-if="hasContent" class="flex flex-col justify-between w-full h-full space-y-2">
      <component :is="layout ? layout.header || 'h2' : 'h2'" v-show="content && content.header" class="uppercase text-center">
        {{ content.header }}
      </component>
      <div v-show="foregroundImage" class="relative flex-grow">
        <div class="absolute inset-0 w-full h-full">
          <img :src="`${foregroundImage.medium || foregroundImage.full}?cb=${cacheBuster}`" class="block-image__inline object-contain w-full h-full">
        </div>
      </div>
      <div v-show="content.footer" class="uppercase">
        <p class="text-center">{{ content.footer }}</p>
      </div>
    </div>
    <div v-else class="flex flex-col items-center justify-center space-y-2 h-full">
      <p class="text-lg">
        Image Block
      </p>
      <p class="text-sm">
        Upload a foreground or background image on the right side.
      </p>
    </div>
  </RootBlock>
</template>
<script>
import RootBlock from '../RootBlock'
export default {
  components: {
    RootBlock
  },

  props: {
    item: {
      type: Object,
      required: true
    }
  },

  computed: {
    content () {
      return this.item.content || false
    },

    layout () {
      return this.item.layout || false
    },

    hasContent () {
      return this.backgroundImage || this.foregroundImage || this.item.content.title || this.item.content.footer
    },

    foregroundImage () {
      return this.item.images ? this.item.images[`foreground_image_${this.item.key}`] || false : false
    },

    backgroundImage () {
      return this.item.images ? this.item.images[`background_image_${this.item.key}`] || false : false
    },

    cacheBuster () {
      return this.$store.state.loading.cacheKey || 'static'
    }
  }
}
</script>
