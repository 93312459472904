<template>
  <div
    :style="itemStyles"
    :class="`block-item group relative flex items-stretch ${item.element === 'template' ? getTemplateWidth() : (item.layout && item.layout.width ? item.layout.width : 'col-span-full')} w-full h-full`"
  >
    <div
      ref="element"
      :class="{
        'w-full ring-2 transition-colors': true,
        'ring-red-700': editing,
        'ring-transparent': !editing
      }"
    >
      <component
        v-if="element"
        :is="element.render"
        :item.sync="item"
        :edit-mode="editMode"
      />
      <span v-else>
        Element Missing
      </span>
    </div>
    <div v-if="editMode" ref="settings" class="absolute inset-0 flex items-center justify-center text-white bg-black bg-opacity-75 opacity-0 group-hover:opacity-100 transition-opacity px-4 py-4 z-10">
      <div v-if="!isStart" class="absolute top-0 inset-x-0 flex justify-center">
        <vue-button
          :icon="['fas', 'chevron-up']"
          sm
          @click="$emit('move-item', index, -1)"
        />
      </div>
      <div v-if="!isEnd" class="absolute bottom-0 inset-x-0 flex justify-center">
        <vue-button
          :icon="['fas', 'chevron-down']"
          sm
          @click="$emit('move-item', index, 1)"
        />
      </div>
      <div class="flex flex-col items-center space-y-2">
        <div class="space-x-2">
          <button class="inline-flex items-center pointer-cursor appearance-none space-x-2 rounded-sm px-2 py-1" @click="editItem">
            <font-awesome-icon :icon="['fas', 'edit']" fixed-width />
            <span>Edit</span>
          </button>
          <button class="inline-flex items-center pointer-cursor appearance-none space-x-2 rounded-sm px-2 py-1" @click="deleteItem">
            <font-awesome-icon :icon="['fad', 'trash']" fixed-width />
            <span>Delete</span>
          </button>
        </div>
        <div v-if="item.content && item.content.items && item.content.items.length > 0" :class="labelClasses">
          This block has 'inner' elements
        </div>
        <div v-if="item.before && item.before.items.length > 0" :class="labelClasses">
          This block has 'before' elements
        </div>
        <div v-if="item.after && item.after.items.length > 0" :class="labelClasses">
          This block has 'after' elements
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { head } from 'lodash'

export default {
  name: 'GridItem',

  props: {
    item: {
      type: Object,
      required: true
    },

    index: {
      required: true
    },

    editMode: {
      type: Boolean,
      required: false,
      default: () => { return false }
    },

    isStart: {
      type: Boolean,
      required: false,
      default: () => { return false }
    },

    isEnd: {
      type: Boolean,
      required: false,
      default: () => { return false }
    },

    editing: {
      type: Boolean,
      required: false,
      default: () => { return false }
    }
  },

  data () {
    return {
      labelClasses: 'bg-primary text-white rounded select-none px-2 py-1'
    }
  },

  computed: {
    itemStyles () {
      return {
        minHeight: this.editMode ? '120px' : 'unset',
        gridRow: this.item.grid ? `${this.item.grid.rowStart ? this.item.grid.rowStart : 'auto'} / span ${this.item.grid.rowSpan ? this.item.grid.rowSpan : 'auto'}` : 'auto / span auto'
      }
    },

    element () {
      return this.$store.getters['editor/getElementByKey'](this.item.element)
    }
  },

  methods: {
    editItem () {
      this.$emit('edit-item', this.index)
    },

    deleteItem () {
      this.$emit('delete-item', this.index)
    },

    getTemplateWidth () {
      const template = this.$store.getters['editor/getTemplateByKey'](this.item.content.template)
      const itemWidth = template && template.value.template.layout.items && template.value.template.layout.items.length > 0 ? head(template.value.template.layout.items).layout.width || false : false
      return itemWidth || 'col-span-1/3'
    }
  }
}
</script>
