<template>
  <div class="flex flex-col">
    <div v-for="item in data" :key="item[valueKey]" class="flex items-center">
      <label class="flex flex-grow justify-start items-start items-center py-2">
        <div class="bg-white border rounded border-gray-300 w-5 h-5 flex flex-shrink-0 justify-center items-center mr-2 focus-within:border-blue-500 hover:border-gray-400">
          <input
            v-model="localValue"
            v-bind="$attrs"
            :value="item"
            type="checkbox"
            class="opacity-0 absolute"
            @input="localErrors = []"
          >
          <svg class="fill-current hidden w-3 h-3 text-primary pointer-events-none" viewBox="0 0 20 20"><path d="M0 11l2-2 5 5L18 3l2 2L7 18z"/></svg>
        </div>
        <div
          v-if="item[labelKey]"
          class="select-none text-sm"
        >
          {{ item[labelKey] }}
        </div>
      </label>
      <vue-select
        v-if="selectType"
        v-model="item.pivot.assembly_key"
        labelKey="name"
        valueKey="key"
        :options="selectOptions"
      />
      <span v-if="itemAppend">
        {{ item[itemAppend] }}
      </span>
    </div>
    <div>
      <span
        v-for="(error, errorKey) in localErrors"
        :key="errorKey"
        class="text-red-600 text-xs font-medium mr-1">
        {{ error }}
      </span>
    </div>
  </div>
</template>
<script type="text/javascript">
import HasVModel from '@/mixins/HasVModel'

export default {
  name: 'VueCheckboxGroup',

  mixins: [HasVModel],

  props: {
    data: {
      type: Array,
      required: true
    },

    labelKey: {
      type: String,
      required: true
    },

    valueKey: {
      type: String,
      required: true
    },

    selectType: {
      type: Boolean,
      required: false,
      default: () => { return false }
    },

    selectOptions: {
      type: Array,
      required: false,
      default: () => { return [] }
    },

    itemAppend: {
      type: String,
      required: false,
      default: () => { return null }
    },

    errors: {
      type: Array,
      required: false,
      default: () => { return [] }
    }
  },

  computed: {
    localErrors: {
      get () {
        return this.errors
      },
      set (localValue) {
        this.$emit('update:errors', localValue)
      }
    }
  }
}
</script>
<style type="text/css">
  input:checked + svg {
    display: block;
  }
</style>
