<template>
  <div class="">
    <vue-data-table
      :cols="[
        {
          header: 'Name',
          value: 'name',
        },
        {
          header: 'Key/Slug',
          value: 'key'
        },
        {
          header: 'Type',
          value: 'type'
        },
        {
          header: 'Added At',
          value: 'created_at',
          sort: true
        },
        {
          header: 'Last Modified',
          value: 'updated_at',
          sort: true
        },
        {
          header: 'Actions',
          value: 'actions'
        }
      ]"
      path="sets"
    >
      <template #header-left>
        <vue-button
          :icon="['fas', 'plus-circle']"
          @click="$router.push({ name: routes.new })"
        >
          Create {{ model.singular }}
        </vue-button>
      </template>
      <template v-slot:item.type="row">
        {{ getRowTypeValue(row.type) }}
      </template>
      <template v-slot:item.created_at="row">
        {{ $momentDateTimeHuman(row.created_at) }}
      </template>
      <template v-slot:item.updated_at="row">
        {{ $momentDateTimeHuman(row.updated_at) }}
      </template>
      <template v-slot:item.actions="row">
        <vue-link
          :disabled="loading"
          :to="{
            name: routes.single,
            params: {
              model: row.id
            }
          }"
          :icon="['fad', 'edit']"
          :aria-label="`Edit ${model.singular}: ${row.name}`"
          dark
          text
          sm
          class="inline-flex"
        />
        <vue-button
          :disabled="loading"
          :icon="['fad', 'copy']"
          :aria-label="`Duplicate ${row.name} to a new ${model.singular}`"
          dark
          text
          sm
          class="inline-flex"
          @click="duplicateModel(row)"
        />
      </template>
    </vue-data-table>
  </div>
</template>
<script type="text/javascript">
import HasDuplication from '@/mixins/HasDuplication'

export default {
  name: 'Sets',

  mixins: [
    HasDuplication
  ],

  data () {
    return {
      model: {
        singular: 'Set',
        plural: 'Sets',
        apiEndpoint: 'sets'
      },
      routes: {
        index: 'sets.index',
        new: 'sets.new',
        single: 'sets.single'
      }
    }
  },

  methods: {
    getRowTypeValue (value) {
      return value.replace('PurpleMountain\\PmmEcommerce\\Models\\', '').replace('PurpleMountain\\PmmMetadata\\Models\\', '').replace('App\\Models\\', '')
    }
  }
}
</script>
