var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('header',{class:{
    'fixed top-0 left-0 flex justify-between items-center w-full bg-white text-shade-dark h-12 z-10': true,
    'pl-64 transition-all': _vm.expanded,
    'pl-0 transition-all': !_vm.expanded
  }},[_c('div',{staticClass:"flex items-center h-full"},[_c('button',{staticClass:"navigation-menu__toggler flex justify-center items-center h-full px-6",attrs:{"type":"button","aria-label":"Open navigation menu","title":"Open navigation menu"},on:{"click":function($event){_vm.expanded = !_vm.expanded}}},[_c('font-awesome-icon',{attrs:{"icon":['fal', 'bars'],"size":"lg"}})],1),_c('h1',{staticClass:"font-bold px-2 py-4"},[_c('a',{attrs:{"href":_vm.frontendUrl,"target":"_blank"}},[_vm._v(" Euro Stainless ")])])]),_c('div',{staticClass:"relative"},[_c('a',{staticClass:"flex items-center hover:bg-primary-light cursor-pointer space-x-2 px-6 py-4",on:{"click":function($event){return _vm.setMenu('home')}}},[_c('font-awesome-icon',{attrs:{"icon":['fad', 'user']}}),(_vm.$auth.user.name)?_c('span',[_vm._v(_vm._s(_vm.$auth.user.name))]):_vm._e()],1),(_vm.navigation === 'home')?_c('div',{staticClass:"static lg:absolute right-0 w-full origin-top lg:origin-top-right lg:shadow-lg lg:w-48"},[_c('div',{staticClass:"px-2 py-2 bg-white lg:rounded-md lg:shadow"},[_c('a',{staticClass:"block text-primary font-medium hover:bg-primary-light p-2 rounded px-3 cursor-pointer",on:{"click":function($event){_vm.$auth.logout(); _vm.resetMenu}}},[_vm._v(" Logout ")]),_c('router-link',{staticClass:"block text-primary font-medium hover:bg-primary-light p-2 rounded px-3 cursor-pointer",attrs:{"to":{
              name: 'account'
            }},nativeOn:{"click":function($event){return _vm.resetMenu.apply(null, arguments)}}},[_vm._v(" Account ")])],1)]):_vm._e()])]),_c('aside',{class:{
    'fixed w-64 h-screen bg-white z-20': true,
    'shadow ml-0 transition-all overflow-y-auto': _vm.expanded,
    'shadow-none -ml-64 transition-all': !_vm.expanded
  }},[_c('nav',{staticClass:"flex flex-col"},[_c('div',{staticClass:"relative"},[_c('router-link',{staticClass:"flex items-center hover:bg-primary hover:text-primary-contrast px-2 py-6",attrs:{"to":{
            name: 'home'
          }},nativeOn:{"click":function($event){return _vm.resetMenu.apply(null, arguments)}}},[_c('font-awesome-icon',{staticClass:"mr-4",attrs:{"icon":['fad', 'tachometer-alt'],"size":"lg","fixed-width":""}}),_vm._v(" Dashboard ")],1)],1),_c('vue-navigation-item',{attrs:{"unique":"pages","label":"Pages","route":{
          name: 'pages.index'
        },"add-route":{
          name: 'pages.new'
        },"icon":['fad', 'file']},on:{"set-menu":_vm.setMenu,"reset-menu":_vm.resetMenu}}),_c('vue-navigation-item',{attrs:{"unique":"sets","label":"Sets & Categories","route":{
          name: 'sets.index'
        },"icon":['fad', 'layer-group']},on:{"set-menu":_vm.setMenu,"reset-menu":_vm.resetMenu}}),_c('vue-navigation-item',{attrs:{"unique":"product-management","label":"Products","route":{
          name: 'products.index'
       },"icon":['fad', 'album-collection']},on:{"set-menu":_vm.setMenu,"reset-menu":_vm.resetMenu}}),_c('vue-navigation-item',{attrs:{"unique":"orders-management","label":"Orders","icon":['fad', 'bags-shopping'],"children":[
          { label: 'All Orders', icon: ['fad', 'box-full'], route: { name: 'orders.index' } },
          { label: 'Verify Tubing Orders', icon: ['fad', 'hand-holding-box'], route: { name: 'orders.verify' }, badge: '' } ],"show-children":_vm.checkMenu('orders-management')},on:{"set-menu":_vm.setMenu,"reset-menu":_vm.resetMenu}}),_c('vue-navigation-item',{attrs:{"unique":"payment-management","label":"Payments","icon":['fad', 'file-invoice-dollar'],"children":[
          { label: 'Stripe', icon: ['fab', 'stripe-s'], route: { name: 'payments.stripe' } },
          { label: 'Paypal', icon: ['fab', 'paypal'], route: { name: 'payments.paypal' } },
          { label: 'Bacs', icon: ['fas', 'university'], route: { name: 'payments.bacs' } } ],"show-children":_vm.checkMenu('payment-management')},on:{"set-menu":_vm.setMenu,"reset-menu":_vm.resetMenu}}),_c('vue-navigation-item',{attrs:{"unique":"shipment-management","label":"Manage Shipping","route":{
          name: 'shipping.index'
       },"icon":['fad', 'shipping-fast']},on:{"set-menu":_vm.setMenu,"reset-menu":_vm.resetMenu}}),_c('vue-navigation-item',{attrs:{"unique":"pricing-management","label":"Manage Pricing","route":{
          name: 'pricing.index'
       },"icon":['fad', 'tags']},on:{"set-menu":_vm.setMenu,"reset-menu":_vm.resetMenu}}),_c('vue-navigation-item',{attrs:{"unique":"user-management","label":"Users","icon":['fad', 'user'],"children":[
          { label: 'Admin Users', icon: ['fad', 'users-crown'], route: { name: 'users.admin' } },
          { label: 'UnApproved Users', icon: ['fad', 'user-lock'], route: { name: 'users.unapproved' } },
          { label: 'Approved Users', icon: ['fad', 'users'], route: { name: 'users.index' } },
          { label: 'Add User', icon: ['fad', 'user-plus'], route: { name: 'users.new' } } ],"show-children":_vm.checkMenu('user-management')},on:{"set-menu":_vm.setMenu,"reset-menu":_vm.resetMenu}}),_c('vue-navigation-item',{attrs:{"unique":"website","label":"Website","icon":['fad', 'globe'],"children":[
          { label: 'Posts', icon: ['fad', 'newspaper'], route: { name: 'posts.index' } },
          { label: 'Jobs', icon: ['fad', 'briefcase'], route: { name: 'jobs.index' } },
          { label: 'Menus', icon: ['fad', 'list-ul'], route: { name: 'menus.index' } },
          { label: 'Footer', icon: ['fad', 'file'], route: { name: 'footer' } },
          { label: 'Block Templates', icon: ['fad', 'cubes'], route: { name: 'templates.index' } }
        ],"show-children":_vm.checkMenu('website')},on:{"set-menu":_vm.setMenu,"reset-menu":_vm.resetMenu}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }