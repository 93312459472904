<template>
  <div class="group">
    <div class="flex px-1">
      <div
        v-for="button in editorButtons"
        :key="button.key"
        class="relative"
      >
        <button
          :alt="button.label"
          :title="button.label"
          :aria-label="button.label"
          :class="buttonClass"
          @click="button.onClick()"
        >
          <font-awesome-icon
            :icon="button.icon"
            size="sm"
            fixed-width
          />
        </button>
        <div
          v-if="editorOptions === button.key && button.options && button.options.length > 0"
          class="absolute top-full right-px flex flex-col bg-white border border-gray-200 whitespace-nowrap px-1 py-1 -mt-px z-10"
        >
          <button
            v-for="option in button.options"
            :key="option.label"
            :class="`${option.class} w-full text-left py-1 px-1`"
            @click="option.onClick()"
          >
            {{ option.label }}
          </button>
        </div>
      </div>
    </div>
    <editor-content
      :editor="editor"
      :class="{
        'markdown-render w-full text-sm bg-white text-shade-dark border border-shade group-focus:border-primary group-hover:border-shade-dark px-2 py-3 outline-none transition-all': true,
        'rounded': !square,
        'rounded-none': square
      }"
    />
  </div>
</template>
<script>
import HasVModel from '@/mixins/HasVModel'
import { Editor, EditorContent } from '@tiptap/vue-2'
import StarterKit from '@tiptap/starter-kit'
import Link from '@tiptap/extension-link'

export default {
  name: 'VueMarkdown',

  components: {
    EditorContent
  },

  mixins: [HasVModel],

  props: {
    square: {
      type: Boolean,
      required: false,
      default: () => { return false }
    }
  },

  data () {
    return {
      editor: null,
      editorOptions: null,
      buttonClass: 'bg-gray-200 hover:bg-gray-300 text-black rounded-t transition-all px-2 py-1 mr-px'
    }
  },

  computed: {
    editorButtons () {
      return [
        {
          key: 'bold',
          label: 'Bold',
          icon: ['fas', 'bold'],
          onClick: () => { this.editor.chain().focus().toggleBold().run() }
        },
        {
          key: 'italic',
          label: 'Italic',
          icon: ['fas', 'italic'],
          onClick: () => { this.editor.chain().focus().toggleItalic().run() }
        },
        {
          key: 'heading',
          label: 'Heading',
          icon: ['fas', 'heading'],
          options: [
            {
              label: 'Heading 1',
              class: 'text-lg',
              onClick: () => {
                this.editor.chain().focus().toggleHeading({ level: 1 }).run()
                this.editorOptions = null
              }
            },
            {
              label: 'Heading 2',
              class: 'text-base',
              onClick: () => {
                this.editor.chain().focus().toggleHeading({ level: 2 }).run()
                this.editorOptions = null
              }
            },
            {
              label: 'Heading 3',
              class: 'text-sm',
              onClick: () => {
                this.editor.chain().focus().toggleHeading({ level: 3 }).run()
                this.editorOptions = null
              }
            },
            {
              label: 'Heading 4',
              class: 'text-xs',
              onClick: () => {
                this.editor.chain().focus().toggleHeading({ level: 4 }).run()
                this.editorOptions = null
              }
            }
          ],
          onClick: () => { this.showOptions('heading') }
        },
        {
          key: 'link',
          name: 'Hyperlink',
          icon: ['fas', 'link'],
          onClick: () => {
            const url = window.prompt('Enter the URL you wish to insert on this text')
            if (url && url !== null) {
              this.editor.chain().focus().extendMarkRange('link').setLink({ href: url }).run()
            }
          }
        },
        {
          key: 'list-unordered',
          name: 'Unordered List',
          icon: ['fas', 'list-ul'],
          onClick: () => {
            this.editor.chain().focus().toggleBulletList().run()
          }
        },
        {
          key: 'list-ordered',
          name: 'Ordered List',
          icon: ['fas', 'list-ol'],
          onClick: () => {
            this.editor.chain().focus().toggleOrderedList().run()
          }
        },
        {
          key: 'clear',
          name: 'Clear Styling',
          icon: ['fas', 'remove-format'],
          onClick: () => {
            this.editor.chain().focus().clearNodes().run()
          }
        }
      ]
    }
  },

  watch: {
    value (newVal) {
      if (this.editor.getHTML() !== newVal) {
        this.editor.commands.setContent(this.value, false)
      }
    }
  },

  mounted () {
    this.editor = new Editor({
      content: this.value,
      extensions: [
        StarterKit,
        Link
      ],
      onUpdate: () => {
        this.localValue = this.editor.getHTML()
      }
    })
  },

  beforeDestroy () {
    this.editor.destroy()
  },

  methods: {
    showOptions (menu) {
      this.editorOptions = this.editorOptions === menu ? null : menu
    }
  }
}
</script>
