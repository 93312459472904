<template>
  <div>
    <div>
      <vue-form
        ref="form"
        :form="form"
        :errors="error.errors"
        class="max-w-sm m-auto"
      >
        <h1 class="text-2xl my-2">Login</h1>

        <vue-alert
          v-if="error.message"
          :message="error.message"
          error
        />

        <vue-input-group
          v-model="form.email"
          :errors.sync="error.errors.email"
          required
          label="Username"
          placeholder="chris@example.com"
          type="email"
          name="email"
        />

        <vue-input-group
          v-model="form.password"
          :errors.sync="error.errors.password"
          required
          label="Password"
          placeholder="********"
          type="password"
          name="password"
        />

        <vue-checkbox
          v-model="form.remember"
          :errors.sync="error.errors.remember"
          label="Remember Me?"
          name="remember"
        />

        <vue-button
          class="mt-2 w-full"
          @click.prevent="login"
        >
          Login
        </vue-button>
      </vue-form>
    </div>
  </div>
</template>
<script type="text/javascript">
import UsesTokens from '@/mixins/UsesTokens'

export default {
  name: 'Login',

  mixins: [
    UsesTokens
  ],

  data () {
    return {
      form: {},
      error: {
        errors: {}
      }
    }
  },

  mounted () {
    this.setup()
  },

  methods: {
    setup () {
      this.getToken()
    },

    login () {
      if (this.$refs.form.validate()) {
        this.error = { errors: {} }
        this.$auth.login(this.form)
          .catch(error => {
            this.error = error.response.data
          })
      }
    }
  }
}
</script>
