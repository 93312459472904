import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import Api from './api/'
import './registerServiceWorker'
import './assets/styles/index.scss'
import '@/components.js'
import '@/mixins.js'
import '@/plugins.js'
import '@/icons.js'
import 'tw-elements'

axios.defaults.withCredentials = true

Vue.config.productionTip = false

Vue.use(Api, { baseUrl: process.env.VUE_APP_API_BASE_URL })

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
