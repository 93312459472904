<template>
  <div>
    <input
      ref="input"
      v-model="localValue"
      v-bind="$attrs"
      :class="{
        'text-sm bg-white border block w-full px-2 py-3 rounded focus:outline-none': true,
        'focus:border-primary hover:border-gray-300': !$attrs.readonly && !$attrs.disabled,
        'cursor-not-allowed bg-gray-300': $attrs.readonly || $attrs.disabled
      }"
    >
  </div>
</template>
<script type="text/javascript">
import HasVModel from '@/mixins/HasVModel'

export default {
  name: 'VueInput',

  mixins: [HasVModel],

  mounted () {
    if ('autofocus' in this.$attrs) {
      this.focus()
    }
  },

  methods: {
    focus () {
      this.$refs.input.focus()
    }
  }
}
</script>
