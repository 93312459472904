var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(((_vm.$attrs).type)==='checkbox')?_c('input',_vm._b({directives:[{name:"model",rawName:"v-model",value:(_vm.localValue),expression:"localValue"}],ref:"input",class:{
      'text-sm bg-white border block w-full px-2 py-3 rounded focus:outline-none': true,
      'focus:border-primary hover:border-gray-300': !_vm.$attrs.readonly && !_vm.$attrs.disabled,
      'cursor-not-allowed bg-gray-300': _vm.$attrs.readonly || _vm.$attrs.disabled
    },attrs:{"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.localValue)?_vm._i(_vm.localValue,null)>-1:(_vm.localValue)},on:{"change":function($event){var $$a=_vm.localValue,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.localValue=$$a.concat([$$v]))}else{$$i>-1&&(_vm.localValue=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.localValue=$$c}}}},'input',_vm.$attrs,false)):(((_vm.$attrs).type)==='radio')?_c('input',_vm._b({directives:[{name:"model",rawName:"v-model",value:(_vm.localValue),expression:"localValue"}],ref:"input",class:{
      'text-sm bg-white border block w-full px-2 py-3 rounded focus:outline-none': true,
      'focus:border-primary hover:border-gray-300': !_vm.$attrs.readonly && !_vm.$attrs.disabled,
      'cursor-not-allowed bg-gray-300': _vm.$attrs.readonly || _vm.$attrs.disabled
    },attrs:{"type":"radio"},domProps:{"checked":_vm._q(_vm.localValue,null)},on:{"change":function($event){_vm.localValue=null}}},'input',_vm.$attrs,false)):_c('input',_vm._b({directives:[{name:"model",rawName:"v-model",value:(_vm.localValue),expression:"localValue"}],ref:"input",class:{
      'text-sm bg-white border block w-full px-2 py-3 rounded focus:outline-none': true,
      'focus:border-primary hover:border-gray-300': !_vm.$attrs.readonly && !_vm.$attrs.disabled,
      'cursor-not-allowed bg-gray-300': _vm.$attrs.readonly || _vm.$attrs.disabled
    },attrs:{"type":(_vm.$attrs).type},domProps:{"value":(_vm.localValue)},on:{"input":function($event){if($event.target.composing){ return; }_vm.localValue=$event.target.value}}},'input',_vm.$attrs,false))])}
var staticRenderFns = []

export { render, staticRenderFns }