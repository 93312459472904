<template>
  <div class="flex flex-col">
    <div class="w-full bg-gray-100 text-left px-2 py-2">
      <label>Content</label>
    </div>
    <div class="flex flex-col border border-gray-100 border-t-0 space-y-2 py-2">
      <div class="w-full px-2">
        <vue-input-group
          v-model="item.content.name"
          :disabled="true"
          prop="name"
          name="name"
          type="checkbox"
          label="Show Name Field"
        />
      </div>
      <div class="w-full px-2">
        <vue-input-group
          v-model="item.content.email"
          :disabled="true"
          prop="email"
          name="email"
          type="checkbox"
          label="Show E-mail Field"
        />
      </div>
      <div class="w-full px-2">
        <vue-input-group
          v-model="item.content.company"
          prop="company"
          name="company"
          type="checkbox"
          label="Show Company Field"
        />
      </div>
      <div class="w-full px-2">
        <vue-input-group
          v-model="item.content.phone"
          prop="phone"
          name="phone"
          type="checkbox"
          label="Show Phone Field"
        />
      </div>
      <div class="w-full px-2">
        <vue-input-group
          v-model="item.content.message"
          prop="message"
          name="message"
          type="checkbox"
          label="Show Message Field"
        />
      </div>
      <div class="w-full px-2">
        <vue-input-group
          v-model="item.content.file"
          prop="file"
          name="file"
          type="checkbox"
          label="Show File Field"
        />
      </div>
      <div class="w-full px-2">
        <vue-input-group
          v-model="item.content.columns"
          :options="[1,2]"
          name="columns"
          prop="columns"
          label="Columns"
          description="Pick how many columns should be displayed"
          type="select"
        />
      </div>
    </div>
    <GridItemLayoutConfig
      :item="item"
    />
    <GridItemColorsConfig
      :item="item"
      :hide="['icon']"
    />
  </div>
</template>
<script>
import GridItemLayoutConfig from '../../GridItemLayoutConfig'
import GridItemColorsConfig from '../../GridItemColorsConfig'

export default {
  components: {
    GridItemLayoutConfig,
    GridItemColorsConfig
  },

  props: {
    item: {
      type: Object,
      required: true
    }
  }
}
</script>
