var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"space-y-2"},[_c('button',{staticClass:"flex items-center justify-between w-full py-1",on:{"click":function($event){_vm.expanded = !_vm.expanded}}},[_c('h4',{staticClass:"text-lg"},[_vm._v("Content Assessment")]),_c('div',{staticClass:"flex items-center space-x-2"},[(_vm.overallContentRating)?_c('span',{class:{
          'rounded-full font-bold text-white text-sm uppercase px-2 py-1': true,
          'bg-gray-600': _vm.overallContentRating === 'feedback',
          'bg-green-700': _vm.overallContentRating === 'good',
          'bg-yellow-500': _vm.overallContentRating === 'ok',
          'bg-red-700': _vm.overallContentRating === 'bad'
        }},[_vm._v(" "+_vm._s(_vm.overallContentRating)+" ")]):_vm._e(),(_vm.hasResults)?_c('font-awesome-icon',{class:{
          'transform-gpu transition-transform': true,
          'scale-y-100': !_vm.expanded,
          '-scale-y-100': _vm.expanded
        },attrs:{"icon":['fal', 'chevron-down'],"fixed-width":""}}):_vm._e()],1)]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.expanded || !_vm.hasResults),expression:"expanded || !hasResults"}],staticClass:"flex flex-col space-y-1"},[(_vm.hasResults)?_vm._l((_vm.results),function(section,resultIndex){return _c('div',{key:resultIndex,staticClass:"flex flex-col space-y-1"},_vm._l((section),function(item,itemIndex){return _c('div',{key:itemIndex,staticClass:"flex flex-col space-y-1"},[_c('div',{class:{
              'border-l-4 bg-white rounded-sm px-2 py-1': true,
              'border-green-600': item.rating === 'good',
              'border-yellow-500': item.rating === 'ok',
              'border-red-600': item.rating === 'bad',
              'border-gray-500': item.rating === 'feedback'
            },domProps:{"innerHTML":_vm._s(item.text)}})])}),0)}):_c('div',{staticClass:"text-sm text-gray-800"},[_vm._v(" No content to assess yet ")])],2)])}
var staticRenderFns = []

export { render, staticRenderFns }