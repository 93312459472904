<template>
  <div class="flex flex-col">
    <div class="w-full bg-gray-100 text-left px-2 py-2">
      <label>Content</label>
    </div>
    <div class="flex flex-col border border-gray-100 border-t-0 space-y-2 py-2">
      <div class="w-full px-2">
        <vue-input-group
          v-model="item.content.header"
          prop="header"
          name="header"
          label="Title"
        />
      </div>
      <div class="w-full px-2">
        <vue-input-group
          v-model="item.content.content"
          prop="content"
          name="content"
          label="Content"
          type="editor"
        />
      </div>
      <div class="w-full px-2">
        <vue-input-group
          v-model="item.content.footer"
          prop="footer"
          name="footer"
          label="Footer"
        />
      </div>
      <div class="w-full px-2">
        <vue-icon-picker
          v-model="item.content.icon"
          prop="icon"
          name="icon"
          label="Icon"
        />
      </div>
      <div v-if="item.content.button" class="w-full px-2">
        <vue-input-group
          v-model="item.content.button.label"
          prop="button.label"
          name="button.label"
          label="Button Label"
          description="Leave blank to hide the button."
        />
        <vue-input-group
          v-model="item.content.button.url"
          prop="button.url"
          name="button.url"
          label="Button URL"
          description="The URL to link to. For relative links, use a slash prefix (eg. /about). For external links, use http prefix (eg. https://google.com)"
        />
        <vue-input-group
          v-model="item.layout.button"
          :options="buttonLayoutOptions"
          prop="button"
          name="button"
          type="select"
          label-key="label"
          value-key="value"
          label="Button Side"
          description="Select the side in which to display the button"
        />
      </div>
      <div v-if="canShowInnerEditor" class="w-full px-2">
        <vue-button
          sm
          class="w-full"
          @click="edit('after')"
        >
          Edit 'After' Blocks
        </vue-button>
      </div>
    </div>
    <GridItemLayoutConfig
      :item="item"
    />
    <GridItemColorsConfig
      :item="item"
      :extra-options="extraColorFields"
    />
  </div>
</template>
<script>
import { isEmpty } from 'lodash'
import GridItemLayoutConfig from '../../GridItemLayoutConfig'
import GridItemColorsConfig from '../../GridItemColorsConfig'

export default {
  components: {
    GridItemLayoutConfig,
    GridItemColorsConfig
  },

  props: {
    item: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      extraColorFields: {
        header: {
          label: 'Header',
          inherit: 'text',
          allowNull: true
        },
        footer: {
          label: 'Footer',
          inherit: 'text',
          allowNull: true
        }
      },
      buttonLayoutOptions: [
        {
          label: 'Bottom Left',
          value: 'h-gap w-gap-3xl bottom-0 left-0'
        },
        {
          label: 'Bottom Right',
          value: 'h-gap w-gap-3xl bottom-0 right-0'
        },
        {
          label: 'Top Left',
          value: 'h-gap w-gap-3xl top-0 left-0'
        },
        {
          label: 'Top Right',
          value: 'h-gap w-gap-3xl top-0 right-0'
        }
      ]
    }
  },

  computed: {
    canShowInnerEditor () {
      return isEmpty(this.$store.getters['editor/getInner'])
    }
  },

  methods: {
    edit (mode) {
      this.$root.$emit('editor:inner:edit', this.item, mode)
    }
  }
}
</script>
