<template>
  <div class="space-y-2">
    <button class="flex items-center justify-between w-full py-1" @click="expanded = !expanded">
      <h4 class="text-lg">Content Assessment</h4>
      <div class="flex items-center space-x-2">
        <span
          v-if="overallContentRating"
          :class="{
            'rounded-full font-bold text-white text-sm uppercase px-2 py-1': true,
            'bg-gray-600': overallContentRating === 'feedback',
            'bg-green-700': overallContentRating === 'good',
            'bg-yellow-500': overallContentRating === 'ok',
            'bg-red-700': overallContentRating === 'bad'
          }"
        >
          {{ overallContentRating }}
        </span>
        <font-awesome-icon
          v-if="hasResults"
          :icon="['fal', 'chevron-down']"
          :class="{
            'transform-gpu transition-transform': true,
            'scale-y-100': !expanded,
            '-scale-y-100': expanded
          }"
          fixed-width
        />
      </div>
    </button>
    <div v-show="expanded || !hasResults" class="flex flex-col space-y-1">
      <template v-if="hasResults">
        <div v-for="(section, resultIndex) in results" :key="resultIndex" class="flex flex-col space-y-1">
          <div v-for="(item, itemIndex) in section" :key="itemIndex" class="flex flex-col space-y-1">
            <div
              v-html="item.text"
              :class="{
                'border-l-4 bg-white rounded-sm px-2 py-1': true,
                'border-green-600': item.rating === 'good',
                'border-yellow-500': item.rating === 'ok',
                'border-red-600': item.rating === 'bad',
                'border-gray-500': item.rating === 'feedback'
              }"
            />
          </div>
        </div>
      </template>
      <div v-else class="text-sm text-gray-800">
        No content to assess yet
      </div>
    </div>
  </div>
</template>
<script>
import { filter, debounce } from 'lodash'
import { Paper, ContentAssessor, interpreters, string } from 'yoastseo'
import { getAssessorRatings, getI18n } from '../utils.js'

const removeHtmlBlocks = string.removeHtmlBlocks
const scoreToRating = interpreters.scoreToRating
const ratings = getAssessorRatings()

export default {
  name: 'ContentAssessor',

  props: {
    title: {
      type: String,
      default: ''
    },

    titleWidth: {
      type: Number,
      default: 0
    },

    description: {
      type: String,
      default: ''
    },
    keyword: {
      type: String,
      default: ''
    },

    url: {
      type: String,
      default: ''
    },

    text: {
      type: String,
      default: ''
    },

    locale: {
      type: String,
      default: 'en_US'
    },

    marker: {
      type: [Object, null],
      default () {
        return null
      }
    },

    permalink: {
      type: String,
      default: ''
    },

    translations: {
      type: Object,
      default () {
        return null
      }
    },

    resultFilter: {
      type: Function,
      default (result) {
        return result
      }
    }
  },

  data () {
    return {
      expanded: false,
      ratings: ['feedback', 'bad', 'ok', 'good'],
      assessorResults: [],
      assessorResultsByRating: {},
      overallContentScore: null,
      overallContentRating: null,
      contentAssessor: null
    }
  },

  computed: {
    items () {
      const res = []
      ratings.forEach((rating) => {
        const items = this.assessorResultsByRating[rating]
        if (typeof items === 'undefined') return
        items.forEach((item) => {
          item._class = [
            'vue-yoast-assessor-item',
            'vue-yoast-assessor-item-' + item.rating
          ]
          res.push(item)
        })
      })
      return res
    },

    results () {
      const result = []
      this.ratings.forEach((rating) => {
        const filtered = filter(this.assessorResultsByRating[rating], (item) => { return item.text !== '' })
        return filtered.length > 0 ? result.push(filtered) : false
      })
      return result
    },

    hasResults () {
      return this.results && this.results.length > 0
    }
  },

  watch: {
    title (newVal, oldVal) {
      if (newVal !== oldVal) this._debouncedRefresh()
    },

    description (newVal, oldVal) {
      if (newVal !== oldVal) this._debouncedRefresh()
    },

    keyword (newVal, oldVal) {
      if (newVal !== oldVal) this._debouncedRefresh()
    },

    url (newVal, oldVal) {
      if (newVal !== oldVal) this._debouncedRefresh()
    },

    text (newVal, oldVal) {
      if (newVal !== oldVal) this._debouncedRefresh()
    },

    translations (newVal) {
      this.i18n = getI18n(newVal)
      this._debouncedRefresh()
    },

    locale (newVal, oldVal) {
      if (newVal !== oldVal) this._debouncedRefresh()
    },

    overallContentScore () {
      this.$emit('update:overallScore', {
        score: this.overallContentScore,
        rating: this.overallContentRating
      })
    }
  },

  created () {
    this._debouncedRefresh = debounce(this.refresh, 1000)
  },

  mounted () {
    this.i18n = getI18n(this.translations)
    this.refresh()
  },

  methods: {
    refreshPaper () {
      const text = removeHtmlBlocks(this.text)
      this.paper = new Paper(text, {
        keyword: this.keyword || '',
        description: this.description || '',
        url: this.url || '',
        title: this.title || '',
        titleWidth: this.titleWidth || '',
        locale: this.locale || '',
        permalink: this.permalink || ''
      })
    },

    refresh () {
      this.refreshPaper()
      this.contentAssessor = new ContentAssessor(this.i18n, { marker: this.marker })
      this.contentAssessor.assess(this.paper)
      this.overallScore = this.contentAssessor.calculateOverallScore()
      this.overallRating = scoreToRating(this.contentAssessor.calculateOverallScore() / 10)
      this.assessorResults = []
      this.assessorResultsByRating = {}
      this.contentAssessor.results.forEach(item => {
        const result = this.resultFilter({
          rating: scoreToRating(item.score),
          socre: item.score,
          text: item.text
        })
        this.assessorResults.push(result)
        // eslint-disable-next-line
        if (this.assessorResultsByRating.hasOwnProperty(result.rating)) {
          this.assessorResultsByRating[result.rating].push(result)
        } else {
          this.assessorResultsByRating[result.rating] = [result]
        }
      })
      this.$emit('update', {
        results: this.assessorResults,
        resultsByRating: this.assessorResultsByRating,
        overallScore: this.overallScore,
        overallRating: this.overallRating
      })
    }
  }
}
</script>
