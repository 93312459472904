<template>
  <RootBlock :item="item">
    <div
      :class="{
        'grid auto-rows-auto w-full h-full gap-4': true,
        'grid-cols-1': !content.columns || parseInt(content.columns) < 2,
        'grid-cols-2': parseInt(content.columns) === 2
      }"
    >
      <div v-if="content.name">
        <input
          v-if="content.name"
          type="text"
          name="name"
          placeholder="Name"
          class="text-black bg-white bg-opacity-90 w-full appearance-none rounded-none px-3 py-2"
        />
      </div>
      <div v-if="content.email">
        <input
          type="email"
          name="email"
          placeholder="E-mail Address"
          class="text-black bg-white bg-opacity-90 w-full appearance-none rounded-none px-3 py-2"
        />
      </div>
      <div v-if="content.company">
        <input
          type="company"
          name="company"
          placeholder="Company"
          class="text-black bg-white bg-opacity-90 w-full appearance-none rounded-none px-3 py-2"
        />
      </div>
      <div v-if="content.phone">
        <input
          type="phone"
          name="phone"
          placeholder="Telephone"
          class="text-black bg-white bg-opacity-90 w-full appearance-none rounded-none px-3 py-2"
        />
      </div>
      <div v-if="content.message" class="col-span-full">
        <textarea
          name="message"
          placeholder="How can we help?"
          class="flex-grow text-black bg-white bg-opacity-90 w-full appearance-none rounded-none px-3 py-2"
        />
      </div>
      <div class="flex items-center w-full col-span-full">
        <div
          :class="{
            'whitespace-nowrap': true,
            'w-full': !content.columns || content.columns < 2,
            'w-gap-3xl': content.columns === 2
          }"
        >
          <div
            v-if="content.file"
            class="bg-white bg-opacity-90 text-black text-center w-full px-4 py-2"
          >
            Attach File
          </div>
        </div>
        <div
          :class="{
            'whitespace-nowrap': true,
            'w-full': !content.columns || content.columns < 2,
            'w-gap-3xl': content.columns === 2
          }"
        >
          <div class="bg-dark-800 text-white text-center w-full px-4 py-2">
            Submit
          </div>
        </div>
      </div>
    </div>
  </RootBlock>
</template>
<script>
import RootBlock from '../RootBlock'

export default {
  friendlyName: 'Contact Form',

  components: {
    RootBlock
  },

  props: {
    item: {
      type: Object,
      required: true
    }
  },

  computed: {
    content () {
      return this.item.content || false
    },

    layout () {
      return this.item.layout || false
    },

    colors () {
      return this.item.colors || false
    }
  }
}
</script>
