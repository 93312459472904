<template>
  <div class="flex flex-col">
    <div class="w-full bg-gray-100 text-left px-2 py-2">
      <label>Content</label>
    </div>
    <div class="flex flex-col border border-gray-100 border-t-0 space-y-2 py-2">
      <div class="w-full px-2">
        <vue-input-group
          v-model="item.content.header"
          prop="header"
          name="header"
          label="Title"
        />
      </div>
      <div class="w-full px-2">
        <vue-input-group
          v-model="item.content.link"
          prop="link"
          name="link"
          label="URL"
          description="The URL to link to. For relative links, use a slash prefix (eg. /about). For external links, use http prefix (eg. https://google.com)"
        />
      </div>
      <div class="w-full px-2">
        <vue-icon-picker
          v-model="item.content.icon"
          prop="icon"
          name="icon"
          label="Icon"
        />
      </div>
    </div>
    <GridItemLayoutConfig
      :item="item"
    />
    <GridItemColorsConfig
      :item="item"
      :extra-options="extraColorFields"
    />
  </div>
</template>
<script>
import GridItemLayoutConfig from '../../GridItemLayoutConfig'
import GridItemColorsConfig from '../../GridItemColorsConfig'

export default {
  components: {
    GridItemLayoutConfig,
    GridItemColorsConfig
  },

  props: {
    item: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      extraColorFields: {
        header: {
          label: 'Header',
          inherit: 'text',
          allowNull: true
        }
      }
    }
  }
}
</script>
