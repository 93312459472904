<template>
  <SingleModelLayout
    :model.sync="model"
    :route-back="{
      name: route.index
    }"
    @refresh-model="getModel"
    @delete-model="deleteModel"
  >
    <template #title>
      <TitleSummaryCard
        class="mb-4"
        :edit.sync="edit"
        @edit="startEdit"
        @save="saveModel"
        @discard="discardEdit"
      >
        <template #title>
          <vue-data
            v-model="model.name"
            :value="model.name"
            :edit="edit"
            :border="false"
          />
        </template>
        <template #subtitle class="opacity-50">
          {{ model.id }}
        </template>
      </TitleSummaryCard>
    </template>
    <template #left>
      <vue-data
        v-model="model.primary"
        :value="model.primary"
        :edit="edit"
        type="checkbox"
        class="mb-2"
        label="Primary Menu?"
      />
    </template>
    <template #main>
      <section class="mb-8">
        <div class="flex items-center justify-between mb-2">
          <h3 class="text-2xl">
            Menu Items
          </h3>
          <vue-button
            v-if="edit"
            :icon="['fas', 'plus-circle']"
            text
            @click="addItem"
          />
        </div>
        <div v-if="model.items && model.items.length > 0" class="flex flex-wrap w-row-offset -mx-2">
          <draggable
            v-model="itemsSorted"
            :disabled="!edit"
            handle=".handle"
            class="w-full"
          >
            <div
              v-for="item in itemsSorted"
              :key="item.id"
              class="w-full px-2 mb-2"
            >
              <div class="group flex items-center justify-between bg-gray-200 rounded-sm px-2 py-2">
                <div class="flex items-center space-x-2">
                  <span v-if="edit" class="handle cursor-move px-2">
                    <font-awesome-icon :icon="['fas', 'arrows-alt-v']" fa-fw />
                  </span>
                  <vue-input
                    v-if="edit"
                    v-model="item.label"
                    placeholder="Label"
                  />
                  <span v-else>{{ item.label }}</span>
                </div>
                <div class="flex items-center space-x-2">
                  <template v-if="edit">
                    <vue-input
                      v-model="item.slug"
                      placeholder="Slug"
                    />
                    <vue-button
                      text
                      error
                      xs
                      :icon="['fad', 'trash']"
                      @click.native="removeItem(item.id)"
                    />
                  </template>
                  <span v-else>
                    {{ item.slug }}
                  </span>
                </div>
              </div>
            </div>
          </draggable>
        </div>
        <div v-else class="text-gray-600 text-sm py-4">
          No menu items have been assigned yet. Click the edit button to add items.
        </div>
      </section>
    </template>
  </SingleModelLayout>
</template>
<script type="text/javascript">
import { map, orderBy, findIndex, cloneDeep, compact } from 'lodash'
import draggable from 'vuedraggable'
import SingleModelLayout from '@/components/models/SingleModelLayout'
import TitleSummaryCard from '@/components/models/TitleSummaryCard'

export default {
  components: {
    SingleModelLayout,
    TitleSummaryCard,
    draggable
  },

  data () {
    return {
      loading: false,
      edit: false,
      model: false,
      modelOld: false,
      route: {
        apiEndpoint: 'menus',
        index: 'menus.index'
      }
    }
  },

  computed: {
    models: {
      get () {
        return this.model.items
      },
      set (value) {
        this.model.items = value
      }
    },

    itemsSorted: {
      get () {
        return orderBy(this.model.items, 'order')
      },
      set (value) {
        this.$emit('update:model', {
          ...this.model,
          ...{
            items: map(value, (item, index) => {
              item.order = index
              return item
            })
          }
        })
      }
    }
  },

  created () {
    this.getModel()
  },

  methods: {
    getModel () {
      this.loading = true
      this.$api.get(`${this.route.apiEndpoint}/${this.$route.params.model}`, {
        params: {
          with: ['items']
        }
      })
        .then((res) => {
          this.model = res.data
        })
        .catch((err) => {
          this.$store.commit('error/addError', err)
        })
        .finally(() => {
          this.loading = false
        })
    },

    saveModel () {
      this.loading = true
      this.$api.put(`${this.route.apiEndpoint}/${this.$route.params.model}`, this.model)
        .then((res) => {
          this.getModel()
          this.edit = false
        })
        .catch((err) => {
          this.$store.commit('error/addError', err)
        })
        .finally(() => {
          this.loading = false
        })
    },

    deleteModel () {
      this.loading = true
      this.$api.delete(`${this.route.apiEndpoint}/${this.$route.params.model}`)
        .then((res) => {
          this.$router.push({
            name: this.route.index
          })
        })
        .catch((err) => {
          this.$store.commit('error/addError', err)
        })
    },

    startEdit () {
      this.modelOld = cloneDeep(this.model)
    },

    discardEdit () {
      this.model = this.modelOld
      this.modelOld = false
      this.edit = false
    },

    addItem () {
      this.model.items.push({
        id: null,
        menu_id: this.$route.params.model,
        label: 'No Label',
        slug: '/',
        icon: '',
        order: this.model.items.length
      })
    },

    removeItem (id) {
      const model = cloneDeep(this.model)
      model.items[findIndex(this.model.items, { id })] = null
      model.items = compact(model.items)
      this.model = model
    }
  }
}
</script>
