<template>
  <RootBlock :item="item">
    <div v-if="selectedMenu && selectedMenu.items && selectedMenu.items.length > 0">
      <component :is="layout ? layout.header || 'h3' : 'h3'" :class="`text-h2 mb-2 ${colors && colors.header ? colors.header : colors.text || ''}`">
        {{ content.header || selectedMenu.name || '' }}
      </component>
      <ul class="list-inside list-disc space-y-1">
        <li v-for="item in selectedMenu.items" :key="item.id">
          <a :href="item.slug" class="w-full">
            {{ item.label }}
          </a>
        </li>
      </ul>
    </div>
    <div v-else class="flex flex-col items-center justify-center text-center space-y-2 w-full h-full px-4 py-8">
      <p class="text-lg">
        Menu Items Block
      </p>
      <p class="text-sm">
        No menu chosen yet, or this menu has no items.
      </p>
    </div>
  </RootBlock>
</template>
<script>
import RootBlock from '../RootBlock'
export default {
  components: {
    RootBlock
  },

  props: {
    item: {
      type: Object,
      required: true
    }
  },

  computed: {
    content () {
      return this.item.content || false
    },

    layout () {
      return this.item.layout || false
    },

    colors () {
      return this.item.colors || false
    },

    selectedMenu () {
      return this.item.content.menu ? this.$store.getters['editor/getMenuById'](this.item.content.menu) : false
    }
  }
}
</script>
