export default {
  methods: {
    generateRandomKey (length = 32) {
      const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
      var key = ''
      for (var i = length - 1; i >= 0; i--) {
        const random = Math.floor(Math.random() * chars.length)
        key += chars.substring(random, random + 1)
      }
      return key
    }
  }
}
