<template>
  <NewModelLayout>
    <template #title>
      <h1 class="text-2xl">
        {{ route.title }}
      </h1>
    </template>
    <template #form>
      <vue-form
        :form="form"
        :errors.sync="error.errors"
        ref="form"
        class="bg-gray-100 shadow rounded-sm"
      >
        <div class="bg-white px-6 py-4">
          <div class="flex flex-wrap w-row-offset-sm -mx-2">
            <div class="w-full lg:w-1/2 px-2 mb-4">
              <vue-input-group
                v-model="form.name"
                :errors="error.errors.name"
                name="name"
                prop="name"
                label="Name"
                description="The name of the menu."
                required="true"
              />
            </div>
            <div class="flex items-end w-full lg:w-1/2 px-2 mb-4">
              <div>
                <vue-input-group
                  v-model="form.primary"
                  :errors="error.errors.primary"
                  type="checkbox"
                  name="primary"
                  prop="primary"
                  label="Primary Menu?"
                  description="If checked, this menu will become the site's primary navigation menu."
                />
              </div>
            </div>
          </div>
        </div>
        <div class="flex justify-between items-center w-full px-4 py-4">
          <vue-button
            text
            error
            @click="resetForm"
          >
            Reset
          </vue-button>
          <vue-button
            :icon="['fad', 'save']"
            type="submit"
            success
            @click="submitModel"
          >
            Add {{ route.singular }}
          </vue-button>
        </div>
      </vue-form>
    </template>
  </NewModelLayout>
</template>
<script>
import { cloneDeep } from 'lodash'
import NewModelLayout from '@/components/models/NewModelLayout'

export default {
  name: 'MenusNew',

  components: {
    NewModelLayout
  },

  data () {
    return {
      loading: true,
      form: {},
      defaults: {
        name: '',
        primary: false
      },
      route: {
        title: 'Add New Menu',
        singular: 'Menu',
        plural: 'Menus',
        apiEndpoint: 'menus',
        routeNext: 'menus.single'
      },
      error: {
        errors: {}
      }
    }
  },

  mounted () {
    this.initForm()
  },

  methods: {
    initForm () {
      this.form = cloneDeep(this.defaults)
    },

    resetForm () {
      this.initForm()
    },

    submitModel () {
      this.$refs.form.validate()
      this.$api.post(this.route.apiEndpoint, this.form)
        .then((res) => {
          this.$router.push({
            name: this.route.routeNext,
            params: {
              model: res.data.id
            }
          })
        })
        .catch((data) => {
          this.$store.commit('error/addError', data)
        })
    }
  }
}
</script>
