<template>
  <div class="">
    <vue-data-table
      :cols="[
        {
          header: 'ID',
          value: 'id',
        },
        {
          header: 'Name',
          value: 'name'
        },
        {
          header: 'Primary Menu?',
          value: 'primary'
        },
        {
          header: 'Added At',
          value: 'created_at',
          sort: true
        },
        {
          header: 'Last Modified',
          value: 'updated_at',
          sort: true
        },
        {
          header: 'Actions',
          value: 'actions'
        }
      ]"
      path="menus"
    >
      <template #header-left>
        <vue-button
          :icon="['fas', 'plus-circle']"
          @click="$router.push({ name: routes.new })"
        >
          Add {{ model.singular }}
        </vue-button>
      </template>
      <template v-slot:item.primary="row">
        <font-awesome-icon
          v-if="row.primary"
          :icon="['fad', 'check-circle']"
          size="lg"
          class="text-green-700"
          fixed-width
        />
        <font-awesome-icon
          v-else
          :icon="['fad', 'times-circle']"
          size="lg"
          class="text-red-700"
          fixed-width
        />
      </template>
      <template v-slot:item.created_at="row">
        {{ $momentDateTimeHuman(row.created_at) }}
      </template>
      <template v-slot:item.updated_at="row">
        {{ $momentDateTimeHuman(row.updated_at) }}
      </template>
      <template v-slot:item.actions="row">
        <vue-link
          :to="{
            name: routes.single,
            params: {
              model: row.id
            }
          }"
          :icon="['fad', 'edit']"
          :aria-label="`Edit Page: ${row.name}`"
          text
          sm
          class="inline-flex cursor-pointer"
        />
      </template>
    </vue-data-table>
  </div>
</template>
<script type="text/javascript">
export default {
  name: 'Menus',

  data () {
    return {
      model: {
        singular: 'Menu',
        plural: 'Menus'
      },
      routes: {
        index: 'menus.index',
        new: 'menus.new',
        single: 'menus.single'
      }
    }
  }
}
</script>
