var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('RootBlock',{attrs:{"item":_vm.item}},[_c('div',{class:{
      'grid auto-rows-auto w-full h-full gap-4': true,
      'grid-cols-1': !_vm.content.columns || parseInt(_vm.content.columns) < 2,
      'grid-cols-2': parseInt(_vm.content.columns) === 2
    }},[(_vm.content.name)?_c('div',[(_vm.content.name)?_c('input',{staticClass:"text-black bg-white bg-opacity-90 w-full appearance-none rounded-none px-3 py-2",attrs:{"type":"text","name":"name","placeholder":"Name"}}):_vm._e()]):_vm._e(),(_vm.content.email)?_c('div',[_c('input',{staticClass:"text-black bg-white bg-opacity-90 w-full appearance-none rounded-none px-3 py-2",attrs:{"type":"email","name":"email","placeholder":"E-mail Address"}})]):_vm._e(),(_vm.content.company)?_c('div',[_c('input',{staticClass:"text-black bg-white bg-opacity-90 w-full appearance-none rounded-none px-3 py-2",attrs:{"type":"company","name":"company","placeholder":"Company"}})]):_vm._e(),(_vm.content.phone)?_c('div',[_c('input',{staticClass:"text-black bg-white bg-opacity-90 w-full appearance-none rounded-none px-3 py-2",attrs:{"type":"phone","name":"phone","placeholder":"Telephone"}})]):_vm._e(),(_vm.content.message)?_c('div',{staticClass:"col-span-full"},[_c('textarea',{staticClass:"flex-grow text-black bg-white bg-opacity-90 w-full appearance-none rounded-none px-3 py-2",attrs:{"name":"message","placeholder":"How can we help?"}})]):_vm._e(),_c('div',{staticClass:"flex items-center w-full col-span-full"},[_c('div',{class:{
          'whitespace-nowrap': true,
          'w-full': !_vm.content.columns || _vm.content.columns < 2,
          'w-gap-3xl': _vm.content.columns === 2
        }},[(_vm.content.file)?_c('div',{staticClass:"bg-white bg-opacity-90 text-black text-center w-full px-4 py-2"},[_vm._v(" Attach File ")]):_vm._e()]),_c('div',{class:{
          'whitespace-nowrap': true,
          'w-full': !_vm.content.columns || _vm.content.columns < 2,
          'w-gap-3xl': _vm.content.columns === 2
        }},[_c('div',{staticClass:"bg-dark-800 text-white text-center w-full px-4 py-2"},[_vm._v(" Submit ")])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }