import axios from 'axios'

const Api = class Api {
  options = {}

  axiosInstance = null

  defaultOptions = {
    baseUrl: '',
    apiPath: '/api/',
    serverUnauthMessage: 'We could not complete the request, because you are not authorised to do so.',
    serverErrorMessage: 'We could not access the server at this time. Please try again. If the issue persists, please contact support.'
  }

  constructor (instance, options) {
    this.instance = instance
    this.options = {
      ...this.defaultOptions,
      ...options
    }
    this.axiosInstance = axios.create({
      baseURL: this.options.baseUrl + this.options.apiPath
    })
  }

  get (path, data = {}) {
    return new Promise((resolve, reject) => {
      this.axiosInstance.get(path, { params: this.defaultParams(data) })
        .then(response => resolve(response.data))
        .catch(error => reject(this.errorAdapter(error)))
    })
  }

  delete (path, data = {}) {
    return new Promise((resolve, reject) => {
      this.axiosInstance.delete(path, { params: this.defaultParams(data) })
        .then(response => resolve(response.data))
        .catch(error => reject(this.errorAdapter(error)))
    })
  }

  post (path, object = {}, params = {}) {
    return this.persist(path, 'post', { object: object, params: params })
  }

  put (path, object = {}, params = {}) {
    return this.persist(path, 'put', { object: object, params: params })
  }

  persist (path, method, data) {
    return new Promise((resolve, reject) => {
      this.axiosInstance[method](path, data.object, data.params)
        .then(response => resolve(response.data))
        .catch(error => reject(this.errorAdapter(error)))
    })
  }

  persistMultiple (method, requests) {
    var functionedRequests = []

    requests.forEach(request => {
      var data = {
        ...{
          params: {},
          url: this.options.baseUrl + this.options.apiPath + request.path
        },
        ...request
      }
      functionedRequests.push(axios[method](data.url, data.object, data.params))
    })

    return new Promise((resolve, reject) => {
      axios.all(functionedRequests)
        .then(data => {
          var resolved = []
          data.forEach(part => {
            resolved.push(part.data)
          })
          resolve(resolved)
        })
        .catch(error => {
          reject(this.errorAdapter(error))
        })
    })
  }

  defaultParams (data = {}) {
    data.params = data.params || {}
    return {
      ...{
        limit: 15,
        ascending: 0,
        orderBy: 'id'
      },
      ...data.params
    }
  }

  errorAdapter (error) {
    error = error || {}
    var response = error.response ? error.response : {}
    error = response.status ? error.response : error
    var data = error.data ? error.data : error.message

    return error ? (
      (typeof data === 'object' && error.status === 422)
        ? data
        : (error.status === 403 || error.status === 401)
          ? {
            message: this.options.serverUnauthMessage,
            code: error.status
          }
          : {
            message: this.options.serverErrorMessage,
            errors: {
              server: ['Please use this error code in any support queries. Error Code: ' + error.status]
            },
            code: error.status
          }
    ) : {
      message: error.message,
      errors: {
        server: error.message
      },
      code: error.status
    }
  }
}

export default Api.install = function (Vue, options) {
  Vue.mixin({
    computed: {
      $api () {
        return new Api(this, options)
      }
    }
  })
}
