<template>
  <div>
    <div class="flex justify-end gap-4 mb-4">
      <vue-button
          :icon="['fas', 'file-import']"
          @click="$router.push({ name: routes.import })"
          success
          outline
        >
        Import CSV
        </vue-button>
        <vue-button
          :icon="['fas', 'file-export']"
          :loading="this.loading"
          @click="exportCsv"
          outline
        >
        Export CSV
        </vue-button>
    </div>
    <vue-data-table
      :cols="[
        {
          header: 'Product Name',
          value: 'name',
        },
        {
          header: 'Slug',
          value: 'slug'
        },
        {
          header: 'Added At',
          value: 'created_at',
          sort: true
        },
        {
          header: 'Last Modified',
          value: 'updated_at',
          sort: true
        },
        {
          header: 'Actions',
          value: 'actions'
        }
      ]"
       path="products"
    >
      <template #header-left>
        <vue-button
          :icon="['fas', 'plus-circle']"
          primary
          @click="$router.push({ name: routes.new })"
        >
          Add {{ model.singular }}
        </vue-button>
      </template>
      <template v-slot:item.name="row">
        {{ row.name }}
      </template>
      <template v-slot:item.slug="row">
        {{ row.slug }}
      </template>
      <template v-slot:item.created_at="row">
        {{ $momentDateTimeHuman(row.created_at) }}
      </template>
      <template v-slot:item.updated_at="row">
        {{ $momentDateTimeHuman(row.updated_at) }}
      </template>
      <template v-slot:item.actions="row">
        <vue-link
          :to="{
            name: routes.single,
            params: {
              model: row.slug
            }
          }"
          :icon="['fad', 'edit']"
          :aria-label="`Edit ${model.singular}: ${row.name}`"
          dark
          text
          sm
          class="inline-flex cursor-pointer"
        />
        <vue-button
          :disabled="loading"
          :icon="['fad', 'copy']"
          :aria-label="`Duplicate ${row.name} to a new ${model.singular}`"
          dark
          text
          sm
          class="inline-flex"
          @click="duplicateModel(row)"
        />
      </template>
    </vue-data-table>
  </div>
</template>

<script type="text/javascript">
// import axios from 'axios'
import { merge, cloneDeep } from 'lodash'
import UsesTokens from '../../mixins/UsesTokens'

export default {

  name: 'Products',

  mixins: [UsesTokens],

  data () {
    return {
      url: process.env.VUE_APP_API_BASE_URL,
      model: {
        singular: 'Product',
        plural: 'Products',
        apiEndpoint: 'products'
      },
      exportComplete: false,
      interval: null,
      loading: false,
      routes: {
        index: 'products.index',
        import: 'products.import',
        new: 'products.new',
        single: 'products.single'
      }
    }
  },

  methods: {
    async exportCsv () {
      this.loading = true

      await this.$api.get(`${this.url}/sanctum/csrf-cookie`).then((response) => {
        this.$api.get(`${this.url}/api/csv/export`, {
          responseType: 'blob'
        })
          .then(response => {
            // this.downloadFile(response, 'Export.xlsx')
            this.loading = false
            window.location.href = response
            // console.log('response', response)
            // this.loading = false
            // var FILE = window.URL.createObjectURL(new Blob([response], { type: 'application/csv' }), {
            //   type: 'application/vnd.ms-excel'
            // })
            // console.log('file', FILE)
            // var docUrl = document.createElement('a')
            // docUrl.href = FILE
            // docUrl.setAttribute('download', 'EuroStainless_product_export.xlsx')
            // document.body.appendChild(docUrl)
            // docUrl.click()
          })
          .catch((err) => {
            this.loading = false
            this.$store.commit('error/addError', err)
          })
      }).catch((err) => {
        this.loading = false
        this.$store.commit('error/addError', err)
      })
    },
    downloadFile (url, fileName) {
      fetch(url, { method: 'get', mode: 'no-cors', referrerPolicy: 'no-referrer' })
        .then(res => res.blob()).then(res => {
          const aElement = document.createElement('a')
          aElement.setAttribute('download', fileName)
          const href = URL.createObjectURL(res)
          aElement.href = href
          aElement.setAttribute('target', '_blank')
          aElement.click()
          URL.revokeObjectURL(href)
        })
    },
    openInNewTab (href) {
      Object.assign(document.createElement('a'), {
        target: '_blank',
        rel: 'noopener noreferrer',
        href: href
      }).click()
    },

    duplicateModel (model) {
      this.loading = true

      const oldModel = cloneDeep(model)
      const newName = `${oldModel.value ? oldModel.value.name || oldModel.name || 'Item' : oldModel.name || 'Item'} (Duplicate)`

      this.$api.post(this.model.apiEndpoint, merge(oldModel, {
        slug: this.getNewModelKey(oldModel.slug),
        name: newName,
        protected: false,
        value: {
          name: newName
        }
      }))
        .then((res) => {
          this.$router.push({
            name: this.routes.single,
            params: {
              // model: res.id || res.data.id
              model: res.slug || res.data.slug
            }
          })
        })
        .catch((data) => {
          this.$store.commit('error/addError', data)
        })
        .finally(() => {
          this.loading = false
        })
    },

    getNewModelKey (slug) {
      const unique = `__duplicate_${Date.now()}`
      return slug.includes('__duplicate') ? `${slug.substr(0, slug.length - unique.length)}${unique}` : `${slug}${unique}`
    }
  }
}
</script>

<style>

</style>
