<template>
  <div :class="`${aspectRatio} w-full`">
    <div :class="`w-full h-full ${colors ? `${colors.text || ''} ${colors.background || ''} ${colors.opacity || ''}` : ''} ${padding ? (layout ? layout.padding_x || 'px-gap' : 'px-gap') : ''} ${padding ? (layout ? layout.padding_y || 'py-gap' : 'py-gap') : ''} space-y-4 z-5`">
      <slot />
    </div>
    <div v-if="backgroundImage" class="absolute inset-0 w-full h-full bg-red">
      <img
        :src="`${backgroundImage.large}?cb=${cacheBuster}`"
        :alt="backgroundImageAlt"
        :title="backgroundImageAlt"
        class="block-image__background w-full h-full object-cover object-center -z-1"
      >
    </div>
  </div>
</template>
<script>
export default {
  props: {
    item: {
      type: Object,
      required: true
    },

    padding: {
      type: Boolean,
      required: false,
      default: () => { return true }
    }
  },

  computed: {
    content () {
      return this.item.content || false
    },

    layout () {
      return this.item.layout || false
    },

    colors () {
      return this.item.colors || false
    },

    backgroundImage () {
      return this.item.images ? this.item.images[`background_image_${this.item.key}`] || false : false
    },

    backgroundImageAlt () {
      return this.item.content ? this.item.content[`background_image_${this.item.key}_alt`] || '' : ''
    },

    aspectRatio () {
      if (this.layout) {
        if (this.layout && this.layout.aspect) {
          return this.layout.aspect
        } else if (this.layout.aspect_base || this.layout.aspect_xl) {
          return `${this.layout.aspect_base || 'h-full'} ${this.layout.aspect_xl || 'h-full'}`
        }
      }
      return 'h-full'
    },

    cacheBuster () {
      return this.$store.state.loading.cacheKey || 'static'
    }
  }
}
</script>
