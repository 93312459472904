<template>
  <RootBlock :item="item" :padding="false">
    <div :class="`block w-full h-full ${layout ? layout.padding_x || 'px-gap-sm' : 'px-gap-sm'} ${layout ? layout.padding_y || 'py-gap-sm' : 'py-gap-sm'}`">
      <div
        v-if="layout && layout.position && layout.position === 'center'"
        class="grid grid-cols-2 xl:grid-cols-1 gap-2 h-full w-full"
      >
        <div class="relative flex flex-col justify-evenly xl:justify-start xl:items-center z-5">
          <component :is="layout ? layout.header || 'h2' : 'h2'" v-if="selectedCategory" class="uppercase">
            {{ selectedCategory.content ? selectedCategory.content.name || selectedCategory.name || '' : selectedCategory.name || '' }}
          </component>
        </div>
        <div v-if="selectedCategory && selectedCategory.images.logo" class="xl:absolute xl:inset-gap-sm xl:inset-gap pointer-events-none order-first xl:order-last">
          <div :class="`svg-mask-image svg-mask-image___center w-full h-full ${colors ? colors.svg || 'bg-white' : 'bg-white'}`" :style="`--mask-image: url('${selectedCategory.images.logo.full}')`" />
        </div>
      </div>
      <div v-else class="relative flex flex-col items-end justify-end w-full h-full">
        <div v-if="selectedCategory && selectedCategory.images && selectedCategory.images.logo" class="absolute top-0 left-0 w-3/4 transform-gpu scale-125">
          <div class="aspect-w-1 aspect-h-1">
            <div :class="`svg-mask-image svg-mask-image___left w-full h-full ${colors ? colors.svg || 'bg-white' : 'bg-white'}`" :style="`--mask-image: url('${selectedCategory.images.logo.full}')`" />
          </div>
        </div>
        <div class="flex flex-col items-end justify-end text-right">
          <component :is="layout ? layout.header || 'h2' : 'h2'" v-if="selectedCategory" class="uppercase">
            {{ selectedCategory.content ? selectedCategory.content.name || selectedCategory.name || '' : selectedCategory.name || '' }}
          </component>
        </div>
      </div>
    </div>
  </RootBlock>
</template>
<script>
import RootBlock from '../RootBlock'

export default {
  components: {
    RootBlock
  },

  props: {
    item: {
      type: Object,
      required: true
    }
  },

  computed: {
    content () {
      return this.item.content || false
    },

    layout () {
      return this.item.layout || false
    },

    colors () {
      return this.item.colors || false
    },

    selectedCategory () {
      return this.item.content.category ? this.$store.getters['editor/getCategoryById'](this.item.content.category) : false
    }
  }
}
</script>
